import { AppRoute } from 'app/app.route.const';
import { TutoringKind } from 'components/search/search.const';
import { OfferRequestStatus } from 'logic/api-models/offer-request-status';
import { OfferRequestType } from 'logic/api-models/offer-request-type';
import {
  TeachingLevel,
  TutoringRequestTeachingLevel,
  TutoringRequestTeachingLevelV2,
} from 'logic/api-models/teaching-level';
import { TutoringType } from 'logic/api-models/tutoring-type';
import { ReportOfferReason } from 'logic/api-models/report-offer';
import { GroupSize, WeekDay } from 'logic/api-models/api-models';
import { TimeOfDay } from 'logic/api-models/time-of-day';
import { SubscriptionInterval } from 'logic/subscription-interval/subscription-interval';
import { SortOffersBy } from 'logic/api-models/sort-offer-by';

export const de = {
  common: {
    costAndRate: 'Ab %{value}%{currency} / %{time} Minuten',
    voluntary: 'Ehrenamtlich',
    costSchool: 'Ab %{value}%{currency} / Stunde',
    pagination: {
      start: 'Nachhilfe',
      search: {
        schools: 'Nachhilfeschulen',
        online: 'Online-Nachhilfe',
        localTutors: 'Nachhilfe vor Ort',
      },
    },

    teachingLevel: {
      [TeachingLevel.klasse4]: '4 Klasse',
      [TeachingLevel.klasse7]: '7 Klasse',
      [TeachingLevel.klasse10]: '10 Klasse',
      [TeachingLevel.abitur]: 'Abitur',
      [TeachingLevel.berufsschule]: 'Berufsschule',
      [TeachingLevel.fachhochschule]: 'Fachhochschule',
      [TeachingLevel.universtitatStudium]: 'Universität / Studium',
      unknown: 'Unknown',
    },
    requestTeachingLevel: {
      [TutoringRequestTeachingLevel.CLASS1]: '1. Klasse',
      [TutoringRequestTeachingLevel.CLASS2]: '2. Klasse',
      [TutoringRequestTeachingLevel.CLASS3]: '3. Klasse',
      [TutoringRequestTeachingLevel.CLASS4]: '4. Klasse',
      [TutoringRequestTeachingLevel.CLASS5]: '5. Klasse',
      [TutoringRequestTeachingLevel.CLASS6]: '6. Klasse',
      [TutoringRequestTeachingLevel.CLASS7]: '7. Klasse',
      [TutoringRequestTeachingLevel.CLASS8]: '8. Klasse',
      [TutoringRequestTeachingLevel.CLASS9]: '9. Klasse',
      [TutoringRequestTeachingLevel.CLASS10]: '10. Klasse',
      [TutoringRequestTeachingLevel.CLASS11]: '11. Klasse',
      [TutoringRequestTeachingLevel.CLASS12]: '12. Klasse',
      [TutoringRequestTeachingLevel.ABITUR]: 'Abitur',
      [TutoringRequestTeachingLevel.UNIVERSITY]: 'Studium',
      unknown: 'Unknown',
    },
    requestTeachingLevelV2: {
      [TutoringRequestTeachingLevelV2.CLASS1]: '1. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS2]: '2. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS3]: '3. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS4]: '4. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS5]: '5. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS6]: '6. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS7]: '7. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS8]: '8. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS9]: '9. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS10]: '10. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS11]: '11. Klasse',
      [TutoringRequestTeachingLevelV2.CLASS12]: '12. Klasse',
      [TutoringRequestTeachingLevelV2.ABITUR]: 'Abitur',
      [TutoringRequestTeachingLevelV2.UNIVERSITY]: 'Universität',
      [TutoringRequestTeachingLevelV2.OTHER]: 'Sonstige',
      unknown: 'Unknown',
    },
    teachingLocation: {
      [TutoringType.atTeacher]: 'Beim Lehrer zu Hause',
      [TutoringType.atStudent]: 'Beim Schüler zu Hause',
      [TutoringType.online]: 'Online-Unterricht',
    },
    instituteTeachingLocation: {
      [TutoringType.atTeacher]: 'Beim Nachhilfeinstitut',
      [TutoringType.atStudent]: 'Beim Schüler',
      [TutoringType.online]: 'Online-Nachhilfe',
    },
    groupSize: {
      [GroupSize.GROUP]: 'Gruppenunterricht',
      [GroupSize.SINGLE]: 'Einzelunterricht',
    },
    weekDay: {
      [WeekDay.MONDAY]: 'Mo',
      [WeekDay.TUESDAY]: 'Di',
      [WeekDay.WEDNESDAY]: 'Mi',
      [WeekDay.THURSDAY]: 'Do',
      [WeekDay.FRIDAY]: 'Fr',
      [WeekDay.SATURDAY]: 'Sa',
      [WeekDay.SUNDAY]: 'So',
    },
    timeOfDay: {
      [TimeOfDay.Morning]: 'Vormittag',
      [TimeOfDay.Evening]: 'Nachmittag',
    },
    openingHours: {
      openAt: 'Startzeit',
      closeAt: 'Schließung',
      closed: 'Geschlossen',
    },
    otherPeopleSearchFor: {
      leadText: 'Andere Personen suchen auch nach: ',
      onSiteTeacher: 'Vor-Ort Lehrer in %{city}',
      onSiteSchool: 'Nachhilfeschulen in %{city}',
      onlineTeacher: 'Online-Lehrer für %{subject}',
    },
    dataProtection: 'Datenschutz',
    here: 'hier',
    legal: {
      companyName: 'TutorSpace GmbH',
      name: 'TutorSpace',
      operator: 'TutorSpace ist in Angebot der TutorSpace GmbH',
      management: 'Patrick Nadler',
      representative: 'Patrick Nadler',
      supportEmail: 'support@tutorspace.de',
      address: 'Pettenkoferstraße 9\n67063 Ludwigshafen\nDeutschland',
      legalAddress: 'Pettenkoferstraße 9\n67063 Ludwigshafen\nDeutschland',
      headquarters: 'Ludwigshafen',
      phone: '+49 (0) 621 - 300 890 41',
      infoEmail: ' info@tutorspace.de',
      website: 'www.tutorspace.de',
      taxID: 'DE306176120',
      onlineDisputeReference: 'http://ec.europa.eu/consumers/odr/',
      onlineImageSources:
        'www.unsplash.com\nwww.logomakr.com\nwww.lunapic.com\nwww.pixabay.com\nwww.shutterstock.com',
    },
    ad: 'Anzeige',
    copyLinkToClipboardTooltip: 'In die Zwischenablage kopiert.',
  },
  partner: {
    pros: {
      first: {
        title: 'Werde ein Partner als Nachhilfeinstitut',
        subTitle:
          'Das Partnerprogramm ist für die Präsentation aller deiner Standorte gegenüber tausenden Nachhilfelehrern, Schülern und Eltern.',
      },
      second: {
        title: 'Werde ein Partner als Schule oder Universität',
        subTitle:
          'Zeige Deutschland, dass deine Institution mit dem Einsatz von TutorSpace für Digitalisierung und Lernen auch außerhalb des Unterrichts steht.',
      },
      last: {
        title: 'Kein Institut oder Schule? Ganz egal!',
        subTitle:
          'Kontaktiere uns und wir können vielleicht in einem anderen Aspekt zusammenarbeiten.',
      },
    },
    button: 'Partner werden',
    buttonText: 'Willst du auch hier auftauchen?',
    buttonSubText: 'Zeige Deutschland, dass du dich für die Bildung einsetzt.',
  },
  aboutus: {
    typesTitle:
      'Wo könnte man mehr Interessenten und Lehrkräfte gewinnen, als auf einer Plattform, die zu 100 % aus Eltern, Schülern und Nachhilfelehrern besteht?',
    type: {
      first: { title: 'Schüler', subTitle: 'suchen Nachhilfe in der Umgebung' },
      second: { title: 'Deine Nachhilfeschule', subTitle: 'sichtbar für alle Interessierten' },
      last: { title: 'Eltern', subTitle: 'suchen schulische Hilfe für dein Kind' },
    },
    typesButton: 'Jetzt als Nachhilfeschule registrieren!',
    prosTitle: 'Du möchtest besser im Internet gefunden werden? ',
    pros: {
      first: {
        title: 'Gewinne neue Kunden',
        subTitle:
          'Gewinne neue Kunden – Gewinne die passenden Eltern und Nachhilfelehrer für deinen Standort.',
      },
      second: {
        title: 'Sei auf Google präsent',
        subTitle:
          'Erhöhe deine Sichtbarkeit im Internet mit uns über Google und lenke die Aufmerksamkeit auf deinen Standort.',
      },
      last: {
        title: 'Überzeuge Eltern',
        subTitle:
          'Mach dein Leistungsangebot transparent, lade Bilder von deiner Schule hoch und sammle Bewertungen.',
      },
    },
    typeTitle: 'Die TutorSpace-Community wächst täglich. Dabei sind unsere Nutzer vor allem:',
    typeCommunity: {
      first: {
        title: 'Väter und Mütter',
        subTitle:
          'besorgt um die guten Noten, auf der Suche nach gutem Nachhilfeunterricht, teilen Erfahrungen mit anderen Eltern',
      },
      second: {
        title: 'Schüler',
        subTitle:
          'aktiv in sozialen Netzwerken, teilen Dinge, die sie interessant finden, Nachhilfe geben und nahmen',
      },
      third: {
        title: 'Studenten',
        subTitle: 'aktiv in sozialen Netzwerken, mit Nachhilfe als Nebeneinkommen Geld verdienen',
      },
      fourth: {
        title: 'Berufslehrer',
        subTitle:
          'lieben Bildung & Unterrichten, beraten Schüler gerne , empfehlen gute Nachhilfemöglichkeiten oder bieten sich selbst an',
      },
      last: {
        title: 'Gleichverteilt',
        subTitle:
          '56% unserer Nutzer sind weiblich 44% unserer Nutzer sind männlich 100% = Die TutorSpace-Community',
      },
    },
    tiersTitle: 'Eine einmalige Gelegenheit, deine Nachhilfeschule digital zu präsentieren.',
    tiersSubTitle: 'Volle Flexibilität: ',
    tiersSubTitleContent: 'Bequeme Zahlung per SEPA-Lastschrift oder Rechnung',
    tiersSubTitle2: 'Mehr Effizienz: ',
    tiersSubTitle2Content:
      'Wir optimieren deine Anzeige auf Basis unserer Erfahrungswerte von mehreren tausend Nachhilfeanzeigen',
    CTATitle:
      'Soll dein Standort die Aufmerksamkeit von Schülern, Eltern und Nachhilfelehrern auf sich ziehen? ',
    CTASubTitle: 'Registriere dich jetzt als Nachhilfeschule. Wir helfen deinem Unternehmen gerne.',
  },
  faq: {
    title: 'Wähle eine der Kategorien und finde die Antwort auf die häufigsten Fragen:',
    subTitle:
      'Mit unserem Format "Du fragst - Wir antworten" gehen wir auf die häufigsten Anliegen, Fragen und Meinungen von dir ein.',
    subItems: {
      marketplace: 'Vergleichsplattform',
      elite: 'TutorSpace Premium',
    },
    types: {
      first: {
        title: 'Gebe Nachhilfe',
      },
      second: {
        title: 'Suche Nachhilfe',
      },
      last: {
        title: 'Institut',
      },
    },
    CTA: {
      title: 'Du hast noch Fragen?',
      subTitle1: 'Nimm zu uns ',
      subTitle2: 'Kontakt',
      subTitle3: ' auf. Wir helfen dir gerne!',
      button: 'Jetzt Kontakt aufnehmen',
    },
  },
  rateTooltips: {
    titleSchool: 'Schreibe eine Bewertung',
    rateSchool: 'Bewertung schreiben',
    needStar: 'Du musst deinen Lehrer noch bewerten.',
    thankYouInput: 'Wie wäre es mit einem Kompliment?',
    didNotTakePlace: 'Der Unterricht hat nicht stattgefunden.',
    rate: 'Du hast deinen Tutor mit {{rateing}} Sternen bewertet',
    wait20Days: 'Frag mich in 20 Tagen noch einmal.',
    wait20DaysLong:
      'Wir melden uns in 20 Tagen noch einmal bei dir und wünschen die viel Erfolg bei den kommenden Stunden!',
    thankYouText: 'DANKESCHÖN',
    errorText: 'Du hast diesen Tutor schon bewertet. Leider kann man jeden Lehrer nur 1x bewerten.',
    doneButton: 'Fertig!',
    starsTitle: 'Studienkreis Mainz',
    reasonTitle: 'Schreibe eine Bewertung',
    successButton: 'FERTIG!',
    buttonSend: 'ABSCHICKEN!',
    buttonSend2: 'Abschicken!',
    leadText: 'Bewerte deinen Lehrer',
    problemText: 'Was hat dir nicht gefallen?',
    helperText:
      'Bitte beschreibe dein Problem. Dein Text wird nicht öffentlich gemacht, aber dient zur Qualitätssicherung.',
    placeholder: 'Bitte beschreibe hier das Problem',
    placeholder2: 'Schreib ein kleines Dankeschön',
    0: '',
    1: '"Unzumutbarer Lehrer oder anstößiges Verhalten."',
    2: '"Sehr schlecht. Es gab sehr viele Probleme."',
    3: '"Würde ich nicht weiterempfehlen. Es gab mindestens ein Problem."',
    4: '"Gut, aber es gab ein Problem."',
    5: '"Sehr zufrieden!"',
  },
  homepage: {
    quotations: {
      heading: 'Überzeuge dich selbst.\nWas Kinder und Eltern über uns sagen:',
      buttonOnline: 'Jetzt mehr über TutorSpace Premium erfahren!',
      buttonHomepage: 'Jetzt mehr erfahren!',
      buttonTutorspacePlus: 'Gratis Probestunde',
      quote1: {
        text:
          'Super leicht bedienbar. Gestern angemeldet und heute schon ein Lernpartner gefunden! Kann ich nur weiterempfehlen. Zudem kann man bei Fragen eine Email schreiben!! Und im Handumdrehen bekommt man eine Antwort. :-))',
        author: 'Anja H.',
        authorRole: 'Mutter',
      },
      quote2: {
        text:
          'Super cool - gestern ist mein Nachhilfelehrer abgesprungen und heute ist schon der Ersatz am Start 🤙🏽 Super Plattform',
        author: 'Michael M.',
        authorRole: 'Schüler in der 10. Klasse',
      },
      quote3: {
        text:
          'Ich persönlich finde diese Plattform klasse! Sehr schnelle Suchergebnisse, einfach klasse. Nur zu empfehlen.',
        author: 'Sophie I.',
        authorRole: 'Mutter',
      },
      quote4: {
        text:
          'Ich finde die Plattform echt eine super Idee und sie funktioniert einwandfrei! So etwas kannte ich vorher noch nicht. So weiter machen 🙂',
        author: 'Bianca B.',
        authorRole: 'Mutter',
      },
      quote5: {
        text:
          'Quasi die beste Plattform für Nachhilfesuchende - meiner Meinung nach besser als jede andere, da sofort passende und übersichtliche Tutoren vorgeschlagen werden. Bedienung ist super einfach verständlich und macht Sinn. Auch der Support ist klasse - Danke!',
        author: 'Kathrin M.',
        authorRole: 'Mutter',
      },
      quote6: {
        text:
          'Danke TutorSpace, hat geklappt!!! Wir haben schon einen Termin vereinbart. Gruss Mario',
        author: 'Mario G.',
        authorRole: 'Vater',
      },
      quote7: {
        text:
          'Erst vor kurzem bin ich auf TutorSpace gestoßen & bin begeistert wie einfach und schnell ich an einen Nachhilfelehrer gekommen bin. Ich war seit längerer Zeit auf der Suche nach professioneller Nachhilfe und habe jetzt endlich jemanden.',
        author: 'Petra S.',
        authorRole: 'Mutter',
      },
      quote8: {
        text:
          'Hallo TutorSpace, dickes Lob für eure Plattform. Hab für meinen Sohn eine tolle Nachhilfe dank euch gefunden! MFG Sabine',
        author: 'Sabine P.',
        authorRole: 'Mutter',
      },
    },
    features: {
      heading: 'Lerne von Deutschlands größter mobilen Nachhilfelehrer-Community.',
      feature1: {
        heading: 'Vertrauen und Sicherheit',
        paragraph:
          'Jeder unserer Nutzer ist verifiziert und Daten werden verschlüsselt übertragen.',
      },
      feature2: {
        heading: 'Privat und flexibel',
        paragraph: 'Nutze die Vorteile von privater Nachhilfe bei dir zu Hause oder online.',
      },
      feature3: {
        heading: 'Der richtige Nachhilfelehrer',
        paragraph: 'Finde genau den Experten in deiner Umgebung oder online.',
      },
      feature4: {
        heading: 'Verbessere deine Noten',
        paragraph: 'Erreiche deine Ziele schneller durch einen privaten 1:1 Unterricht.',
      },
    },
    createOfferStrip: {
      leadText: 'Nachhilfe geben:',
      createButton: 'ANBIETEN',
      privateFreeHeading: 'Für Privatpersonen ist die \nNutzung der Plattform kostenlos.',
      trialLesson: 'Alle unsere Premium-Tutoren bieten eine kostenlose Probestunde.',
      privateFreeDescription: 'Lediglich Nachhilfeinstitute zahlen für die Nutzung.',
      cost: '0€',
    },
    findingTutors: {
      subheading: {
        item1: {
          title: '11.000 +',
          description: 'AKTIVE LEHRKRÄFTE',
        },
        item2: {
          title: '16.000 +',
          description: 'VERMITTELTE KONTAKTE',
        },
      },
      onSiteTutoring: {
        heading: 'Neben der Online-Nachhilfe gibt es auch\nunsere TutorSpace Community:',
        headingComparison:
          'Die TutorSpace Vergleichsplattform:\nEinfach selbst den Richtigen finden.',
        step1: {
          heading: '1. VERGLEICHEN',
          paragraph: 'Durchsuche Nachhilfeangebote und frage pro Tag bis zu 3 Nachhilfelehrer an.',
        },
        step2: {
          heading: '2. VERMITTLUNG',
          paragraph:
            'Wir vermitteln automatisiert den Kontakt. Danach vereinbarst du alles Weitere mit dem Tutor.',
        },
        step3: {
          heading: '3. UNTERRICHT',
          paragraph:
            'Die Nachhilfe erfolgt <u>unabhängig</u> von unserer Plattform. Wir wünschen viel Erfolg beim Lernen!',
        },
        compareButton: 'Jetzt kostenlos Tutoren vergleichen!',
        info: {
          title: 'Nachhilfelehrer aus deiner Umgebung für\nprivaten Einzelunterricht.',
          text1:
            'Durchsuche tausende private Lehrende in deiner Nähe und finde einen Experten, der perfekt zu dir passt.',
          text2:
            'Alle Lehrenden sind verifiziert und anhand von Bewertungen und Hintergrundinformationen einfach vergleichbar.',
        },
        profit: {
          headline: 'Profitiere von Deutschlands größter mobilen Nachhilfe-Community',
          item1: {
            title: 'Vertrauen und Sicherheit',
            text:
              'Jeder der unabhängigen Tutoren ist verifiziert und Daten werden verschlüsselt übertragen.',
          },
          item2: {
            title: 'Privat und flexibel',
            text: 'Nutze die Vorteile von privater Nachhilfe – zu Hause oder online.',
          },
          item3: {
            title: 'Der richtige Nachhilfelehrer',
            text:
              'Experten in der Umgebung oder online. Über unsere Plattform findest du die beste Nachhilfe.',
          },
        },
      },
      onlineTutoring: {
        heading: 'Die TutorSpace Vergleichsplattform:\nEinfach selbst den Richtigen finden.',
        step1: {
          heading: '1. VERGLEICHEN',
          paragraph: 'Durchsuche über 11.000 private Online-Nachhilfelehrer.',
        },
        step2: {
          heading: '2. VERMITTLUNG',
          paragraph:
            'Wir vermitteln kostenlos den Kontakt. Danach vereinbarst du alles Weitere mit dem Tutor.',
        },
        step3: {
          heading: '3. PRIVATER UNTERRICHT',
          paragraph:
            'Die Nachhilfe und die Bezahlung sind privat und flexibel. Die Organisation der Einheiten vereinbarst du mit dem Tutor deiner Wahl.',
        },
        compareButton: 'Jetzt Tutoren vergleichen!',
      },
      eliteTutoring: {
        step1: {
          heading: '1. Kostenlose Probestunde',
          paragraph: 'Geprüfte Lehrkräfte gehen bei uns unverbindlich in Vorleistung',
        },
        step2: {
          heading: '2. Unsere virtuelles Klassenzimmer',
          paragraph:
            'Lerne deinen Tutor kennen und erlebe informativen und interaktiven Unterricht.',
        },
        step3: {
          heading: '3. Paket wählen und Noten verbessern',
          paragraph: 'Die Zahlung ist bargeldlos, sodass du dich aufs Lernen konzentrieren kannst.',
        },
        compareButton: 'Jetzt zur Probestunde',
      },
      step1: {
        heading: '1. Vergleichen',
        paragraph: 'Durchsuche tausende verfügbare private Lehrer in deiner Nähe.',
      },
      step2: {
        heading: '2. Gratis Vermittlung',
        paragraph:
          'Wir vermitteln dir den Kontakt. Du setzt dich mit dem Lehrer in Verbindung, um eine passende Zeit zu finden.',
      },
      step3: {
        heading: '3. Unterrichte privat',
        paragraph:
          '1:1 Nachhilfe ist der effektivste Weg zu lernen. Die Nachhilfe und die Bezahlung sind privat und flexibel.',
      },
      compareButton: 'Kostenlos Tutoren vergleichen und kontaktieren',
    },
    tutorspaceInNumbers: {
      heading: 'TutorSpace in Zahlen:',
      item1: {
        title: '11.000+',
        description: 'Verfügbare Nachhilfelehrer',
      },
      item2: {
        title: '16.000+',
        description: 'Personen geholfen in den letzten Monaten',
      },
    },
    howDoesItWork: {
      students: {
        heading: 'Wie funktioniert es für Eltern oder Schüler?',
        step1: {
          heading: 'Schritt 1: Lehrer vergleichen',
          paragraph:
            'Zahlreiche verifizierte & motivierte private Nachhilfelehrer stehen zur Auswahl. Suche dir den perfekten in deiner Umgebung aus.',
        },
        step2: {
          heading: 'Schritt 2: Termin anfragen',
          paragraph:
            'Frage mit einem Klick an. Wenn der Tutor bestätigt, bekommst du seine Handynummer und E-Mail weitergeleitet.',
        },
        step3: {
          heading: 'Schritt 3: Unabhängig lernen',
          paragraph:
            'Tausche dich mit dem Nachhilfelehrer aus und vereinbare flexibel und privat deine Nachhilfestunden.',
        },
        button: 'Finde jetzt einen passenden Lehrer!',
      },
      tutors: {
        heading: 'Wie funktioniert es für Nachhilfelehrer?',
        step1: {
          heading: 'Schritt 1: Angebot veröffentlichen',
          paragraph:
            'Lade in wenigen einfachen Schritten dein Unterrichtsangebot hoch. Jetzt bist du für tausende suchende Eltern und Schüler sichtbar!',
        },
        step2: {
          heading: 'Schritt 2: Erhalte Anfragen',
          paragraph:
            'Anfragen zu deinem Nachhilfeangebot erhältst du direkt über unsere Plattform. Nur noch bestätigen, und deine Kontaktdaten werden sicher und verschlüsselt übermittelt.',
        },
        step3: {
          heading: 'Schritt 3: Der Unterricht geht los',
          paragraph:
            'Als Lehrer sprichst du dich direkt mit dem Schüler ab. Unabhängig von uns und flexibel. Viel Spaß beim Unterrichten!',
        },
        button: 'Jetzt Nachhilfe geben!',
      },
    },
    discoverEliteTutoring: {
      heading: 'Du willst eine noch persönlichere Lösung?\nEntdecke unsere Premium‑Tutoren.',
      plusHeading:
        'Finde die perfekte Nachhilfe für dein Kind.\nEntdecke die Lehrkräfte von TutorSpace Premium.',
      description:
        'Ob Nachhilfe, Hausaufgabenhilfe oder Prüfungsvorbereitung: Unsere eigenen Tutoren zeichnen sich durch \n' +
        'besonders hohe Qualifikation aus und werden durch einen sorgfältigen Prozess ausgewählt. Deinen Tutor \n' +
        'kannst du jederzeit flexibel wechseln, bis der perfekte Nachhilfelehrer gefunden ist.',
    },
    howToOnlineTutoring: {
      heading:
        'Online-Nachhilfe für Schüler ab der \n4. Klasse bis zum Abitur \nin allen Fächern und Kursen.',
      feature1:
        'Im virtuellen Klassenzimmer wird regelmäßig und auf motivierende Art an den Noten gearbeitet.',
      feature2:
        'Wir stellen nur Lehrer ein, die uns von ihrer Persönlichkeit überzeugen und ihre Kompetenz bewiesen haben.',
      button: 'Jetzt Online-Lehrer finden',
    },
    howElitetutoringWorks: {
      bulletpointbox1: {
        headline: 'Willkommen in unserem\n virtuellen Klassenzimmer!',
        content: [
          'Digitale Schultafel',
          'Integrierte Lernvideos',
          'Arbeitsblätter hochladen',
          'Unterrichtsaufzeichnungen',
          'Bildschirmübertragungen',
        ],
        button: 'Jetzt mehr über TutorSpace Premium erfahren!',
        buttonTutorSpacePlus: 'Gratis Probestunde buchen',
      },
      bulletpointbox2: {
        headline: 'Einfache und faire Preisgestaltung.\nBei uns gibt es keine versteckten Kosten.',
        content: [
          'Ausgewählter Plan kann monatlich angepasst werden',
          'Keine Registrierungsgebühren oder Vermittlungskosten',
          'Sichere Online-Bezahlung',
          'Wir schützen deine Daten gemäß DSGVO',
        ],
        extraInfo: {
          item1: {
            headline: 'Eine Stunde übrig am Monatsende?',
            text: 'Diese wird automatisch in den nächsten Monat übertragen.',
          },
          item2: {
            headline: 'Die Zeit wird knapp?',
            text: 'Zusätzliche Stunden können jederzeit dazugebucht werden.',
          },
        },
        pricePreview: {
          perUnit: '/Einheit',
          contractDuration: 'Auswahl der Laufzeit',
          perMonth: '\xa0/\xa0Monat',
        },
      },
    },
    onlineTutoringAdvantageTiles: {
      tile1: {
        heading: 'Probleme mit dem Unterricht\n' + 'in der Schule?',
        description:
          'Die Schule ist nicht darauf ausgelegt, die Stärken jedes einzelnen Kindes zu fördern. Wir bieten demgegenüber anhand der aktuellen Situation und den individuellen Bedürfnissen den passenden Unterricht.',
      },
      tile2: {
        heading:
          'Wir bieten einen Plan, \n' + 'der sich am Lerntyp\n' + 'deines Kindes orientiert.',
        description:
          'Nicht alle Schülerinnen und Schüler lernen gleich. Daher richten wir den Unterricht am Lerntyp aus und erstellen einen individuellen Lernplan, der sehr gute Ergebnisse verspricht.',
      },
      tile3: {
        heading: 'Unser Ziel ist, dass dein Kind\n' + 'langfristig mit Freude lernt.',
        description:
          'Wir möchten nicht nur Fachwissen vermitteln, sondern dass dein Kind an sich glaubt, weil es erste Erfolge erzielt. Das ist der erste Schritt, um wieder selbstständig lernen zu können.',
      },
      button: {
        default: 'Jetzt mehr über TutorSpace Premium erfahren',
        tutorspacePlus: 'Gratis Probestunde buchen',
      },
    },
    onlineTutoringAdvantageTilesV2: {
      tile1: {
        heading: 'Probleme mit dem Unterricht\nin der Schule?',
        description:
          'Die Schule ist nicht darauf ausgelegt, die Stärken jedes einzelnen Kindes zu fördern. Wir bieten bei der Online-Nachhilfe demgegenüber anhand der aktuellen Situation und den individuellen Bedürfnissen den passenden Unterricht.',
      },
      tile2: {
        heading: 'Wir erstellen einen Plan, \nder sich am Lerntyp\ndeines Kindes orientiert.',
        description:
          'Nicht alle Schülerinnen und Schüler lernen gleich. Daher richten wir die individuelle Online-Nachhilfe am Lerntyp aus und erstellen einen maßgeschneiderten Lernplan, der sehr gute Ergebnisse verspricht.',
      },
      tile3: {
        heading: 'Unser Ziel ist, dass dein Kind\n' + 'langfristig mit Freude lernt.',
        description:
          'Während der Online-Nachhilfe möchten wir nicht nur Fachwissen vermitteln, sondern dass dein Kind an sich glaubt, weil es erste Erfolge erzielt. Das ist der erste Schritt, um wieder selbstständig lernen zu können.',
      },
      button: {
        default: 'Jetzt mehr über TutorSpace Premium erfahren',
        tutorspacePlus: 'Gratis Probestunde buchen',
      },
    },
    onlineTutoringText: {
      paragraph1: {
        heading: 'Online-Nachhilfe – warum eigentlich?',
        content:
          'Bildung ist ein essenzieller Faktor für einen guten Start ins Erwachsenenleben. Während zu Beginn der Schulzeit die Motivation noch groß ist und jedes Kind mit einem rasanten Raketenstart nach den Mathesternen greift, können schwierige Themen, überladene Lehrpläne, eine angespannte Lernumgebung und Co. für Turbulenzen sorgen. Eine schlechte Note wird häufig mit Scheitern gleichgesetzt. Bei der Online-Nachhilfe begegnen wir dem Gefühl des Scheiterns mit einem produktiven Neustart: Dein Kind erhält für die umfangreiche Unterstützung im schulischen Bereich einen persönlichen Tutor. Bevor Schulangst und Demotivation überhaupt entstehen, fördert die Nachhilfe dein Kind nachhaltig. 1-zu-1-Unterricht und Lernen im eigenen Tempo – davon profitierst du bei qualitativer Online-Nachhilfe.',
      },
      paragraph2: {
        heading: 'Online-Nachhilfe bei TutorSpace Premium',
        content:
          'Bei der TutorSpace Premium Online-Nachhilfe begegnen wir Lernlücken und Schulstress mit zielgerichteten Lernplänen, wöchentlicher 1 zu 1 Unterstützung, Hausaufgabenhilfe und mehr. Wir sind eine staatlich anerkannte Bildungseinrichtung, TÜV-zertifiziert und Teil des Bundesverbandes für Nachhilfe- und Nachmittagsschulen. In unserer smarten Lernumgebung fühlt sich dein Kind direkt wohl. Egal, ob Mathe-Nachhilfe online für die Oberstufe, Physik-Nachhilfe online oder Unterstützung bei Fremdsprachen. So können mit der Online-Nachhilfe Lücken schnell geschlossen werden und dein Kind sammelt neues Selbstbewusstsein. #b#Unsere Premium Online-Tutoren beziehen Lernbegabungen, -tempo und -stile in den Unterricht ein#b#, um deinem Kind bestmögliche Unterstützung beim nachhaltigen Lernen zu bieten.',
      },
      paragraph3: {
        heading: 'Auf den richtigen Lehrer kommt es an',
        content:
          'Wir möchten den perfekten Lehrer für dein Kind finden. Daher gibt es bei TutorSpace Premium nur ausgewählte und von uns überprüfte Online-Tutoren aus ganz Deutschland. Unsere professionellen Lehrkräfte müssen zunächst Tests bestehen, um unterrichten zu dürfen. Dazu gehören fachliche Tests, Unterrichtssimulationen, ein umfangreicher Backgroundcheck mit polizeilichem Führungszeugnis sowie individuelle und strukturierte Bewerbungsgespräche. Pädagogische Skills liegen unseren Tutoren im Blut.\n' +
          'Wir möchten nämlich, dass dein Kind nicht nur zum richtigen Ergebnis kommt, sondern auch den Weg dorthin versteht. Die private Online-Nachhilfe bei TutorSpace wird professionell auf dein Kind abgestimmt, sodass wir auf alle Wünsche und Bedürfnisse individuell eingehen können.',
      },
      paragraph4: {
        heading: 'Wie funktioniert der Unterricht online?',
        content:
          'Lernen ist ein komplexer Prozess – und auch die Rahmenbedingungen sind für die Neuschaffung von Wissen im Kopf deines Kindes bedeutungsvoll. Damit dein Kind effektiv lernen kann und dabei Spaß hat, haben wir eine innovative Lernplattform entwickelt. Die interaktive Zusammenarbeit bietet sowohl genug Freiheit für das Kind als auch für den Tutor. Wichtig ist eine Lernumgebung, die motiviert, viel Raum für gemeinsames Arbeiten und auch Flexibilität bietet. Wir bei TutorSpace Premium haben deshalb für die Online-Nachhilfe ein virtuelles Klassenzimmer zum interaktiven Lernen ausgearbeitet. Dieses besteht aus einer digitalen Schultafel, integrierten Lernvideos, Unterrichtsaufzeichnungen, Bildschirmübertragungen sowie der Möglichkeit, Arbeitsblätter hochzuladen.',
      },
      paragraph5: {
        heading: 'Für jede Unterrichtsstunde spenden wir',
        content:
          'Nicht jeder Mensch auf der Welt hat die Möglichkeit, Unterricht und Online-Nachhilfe so wie wir wahrzunehmen. Auch wenn die Schule bei uns eine Selbstverständlichkeit ist – viele Kinder auf unserer Erde haben keine Chance auf Unterricht. Wir sehen Bildung als einen Grundstein für das spätere Leben und eine sichere Zukunft an. Deshalb unterstützen wir die Organisation PROBONO Schulpartnerschaften e.V. Diese Organisation setzt sich für die Verbesserung der Schulbildung an afrikanischen Schulen ein. Jede Nachhilfe-Stunde bei TutorSpace Premium fördert eine Stunde Unterricht für Kinder in Tansania. So können wir Waisenkinder in Tansania mit Bildungsstipendien den Schulbesuch ermöglichen. Und du tust nicht nur deinem Kind etwas Gutes, sondern auch anderen.',
      },
      paragraph6: {
        heading: 'Worauf achten beim Suchen nach Nachhilfe?',
        content:
          'Klingt prinzipiell einfach, dennoch sollte man ein paar Dinge beachten. Zunächst einmal solltest du dir überlegen, zu welchem Zweck die Nachhilfe seil soll. Soll der Unterricht auf eine Prüfung vorbereiten? In diesem Fall kommen unsere Pakete für Prüfungsvorbereitung in Frage. Müssen Lernlücken aufgearbeitet werden, weil viel in der Schule verpasst wurde? Hier würden wir eine Routine von 2 bis 4 mal pro Woche empfehlen. Ist das Kind sich nur ab und zu unsicher und möchte bei den Hausaufgaben begleitet werden? Dann eignet sich eine langfristige Lernbegleitung. Du siehst, es gibt viele Optionen. Unser großes Ziel ist es, deine Anlaufstelle Nummer eins für Eltern zu sein, wenn es um das Thema Schule geht. Deshalb haben wir für alle Themen ein passendes Angebot.',
      },
      paragraph7: {
        heading: 'Wir ebnen den Weg zu besseren Leistungen - unser Lernkonzept',
        content:
          'Mit TutorSpace Premium ist schnell ein passender Online-Tutor gefunden. Nach einem ersten Kennenlernen entwirft dieser einen Lernplan mit Zielen, Meilensteinen und Co. Das Tempo, die Lernmaterialien und die Themen orientieren sich direkt an deinem Kind, nicht an der breiten Masse. Es geht um langfristige Förderung, denn niemand lernt von heute auf morgen – schulischer Erfolg benötigt Zeit. Darüber hinaus ist für den nachhaltigen Lernerfolg auch die Uhrzeit wichtig: Eine Nachhilfe-Stunde sollte zu einer Uhrzeit stattfinden, zu der dein Kind besonders aufnahmefähig ist. Mit TutorSpace Premium ist deine Familie komplett flexibel - für ein ausgeglichenes Familienleben. Wir unterstützen dein Kind ganzheitlich während der schulischen Laufbahn.',
      },
      paragraph8: {
        heading:
          'Direkt loslegen - registriere dich für eine kostenlose Probestunde und finde deinen Nachhilfelehrer online!',
        content:
          'Wir möchten, dass jedes Kind die Chance auf gute Noten hat und sind überzeugt: Bildung ist für jeden da. Deshalb haben wir unsere Premium Online-Nachhilfe mit einem transparenten Laufzeit- und Kostenmodell gestaltet. Wähle die Laufzeit und die Anzahl der Lerneinheiten pro Woche bei der Online-Nachhilfe aus und wir schneiden die Lernthemen individuell auf dein Kind zu. Zum Ausprobieren bieten wir deinem Kind gerne eine #a#kostenlose und unverbindliche Probestunde#a# an. Keine Zeit, um den Kopf in den Sand zu stecken! Schon bald wird dein Kind mehr Spaß am Lernen haben – und auch die Noten werden sich verbessern. Du wirst sehen, wie dein Kind wieder Spaß am Lernen entwickelt und jede Klausur etwas einfacher wird.',
      },
    },
    onlineTutoringReviews: {
      source: 'Quelle',
      confirmed: 'Verifiziert',
      review1: {
        author: 'Frau Kötke',
        summary: 'Sehr zufrieden mit der Online-Nachhilfe',
        content:
          'Super nettes und kompetentes Team. Gehen sowohl auf die Bedürfnisse und Fragen der Lehrer als auch der Schüler ein und erzeugen dadurch die perfekte Online-Nachhilfe. Durch die flexible Buchbarkeit der Stunden kann jede Nachhilfestunde perfekt an den Alltag und etwaigen Terminen angepasst werden.',
      },
      review2: {
        author: 'Stefan',
        summary: 'Sogar Azubis ist zu helfen',
        content:
          'Ich bin derzeit Azubi und war sehr lange auf der Suche nach einer fachkompetenten Nachhilfe für mich was garnicht so einfach war. Kurz nach dem ich eine Nachricht hinterlassen habe ging alles so schnell. (Was mir sehr wichtig war, da ich in Zeitdruck bin) Ich habe noch am selben Tag eine Probestunde bekommen mit einem Online-Lehrer der wirklich für MEINE Lernziele erwählt worden ist. Außerdem war meine Kontaktperson immer zu erreichen wenn ich eine Frage hatte und hat auch immer eine Antwort die mich weiter gebracht hat. Er war sehr bemüht mir zu helfen was ich sehr schätze. Wirklich nur zu empfehlen.',
      },
      review3: {
        author: 'Frau Dietrich-Graubner',
        summary: 'Motivation Level 8000',
        content:
          'Unser Eindruck ist sehr positiv. Eine schnelle Bindung auf Augenhöhe in einer angenehmen und entspannten Online-Lernumgebung in der auf die individuellen Bedürfnisse eingegangen wird. Durch diese Entspannung war unser Kind von Anfang an motiviert.',
      },
      review4: {
        author: 'Frau Dzana',
        summary: 'Kann ich nur weiter empfehlen!',
        content:
          'Uns macht es mega viel Freude und meiner Tochter natürlich online Einzelunterricht zu bekommen, meine Tochter ist positiv überrascht wie aufmerksam und entspannt die Online-Nachhilfelehrer gegenüber ihr sind. Ihre Noten sind top ich bin so glücklich das ich TutorSpace gefunden habe !!!',
      },
      review5: {
        author: 'Herr Ermold',
        summary: 'Ich kann nur gutes sagen!',
        content:
          'Nach meiner Anfrage für Online-Nachhilfe habe ich ziemlich schnell einen Anruf bekommen. Der Ansprechpartner ist sehr gut auf mich eingegangen, hat sehr genau zugehört und sich gemeinsam mit mir eine Lösung überlegt. Der Nachhilfelehrer war genauso top! Mir persönlich gefiel es bei dem Lehrer, dass man sich am Anfang kurz über Video sieht, das Problem schildert und anschließend über das „Skizzenprogramm“ arbeitet. Ich hatte kein Unwohlsein und das zwischenmenschliche hat einfach gestimmt ! Die Abwicklung des Vertrages ist auch sehr schnell und problemlos abgewickelt! Top!',
      },
      review6: {
        author: 'Frau Peeters',
        summary: 'Sehr professionelles Online-Nachhilfe-Portal',
        content:
          'Abwicklung und Buchung der Stunden läuft sehr unkompliziert. Sympathische , junge Frau, die die Nachhilfe gibt.Unsere Tochter freut sich immer auf die Stunde.Bereits nach wenigen Einheiten hat unser Kind gute Erfolge/ Verbesserung bez.Noten in Klassenarbeit erzielt. Der Stressfaktor Lernen , der sicher auch bei anderen Familien bekannt ist, wird komplett ausgeräumt.Wir sind begeistert!',
      },
    },
    video: {
      title: 'Dein Partner, wenn es um Nachhilfe geht.',
      description:
        '„Mehr als jemals zuvor hat sich TutorSpace dem Ziel verschrieben, jedem Schüler zu ermöglichen, sein volles Potential zu erreichen und den außerschulischen Unterricht für alle menschlicher und wirkungsvoller zu machen.“',
      descriptionExtra: 'Patrick Nadler, Geschäftsführer TutorSpace',
    },
    faq: {
      heading: 'Häufig gestellte Fragen\nzu TutorSpace Premium.',
      contact: {
        info: 'Hast du noch Fragen zu TutorSpace Premium?\nWir sind für dich da!',
        reachability: 'Montag bis Freitag: 09:00 - 18:00 Uhr',
      },
    },
    compare: {
      heading: 'Was unterscheidet unsere \nPremium-Lehrkräfte von anderen?',
      infoText:
        'Wenn deine Erwartungen an Nachhilfe steigen, ist die Wahrscheinlichkeit groß, dass andere Anbieter dir nicht mehr ausreichen. TutorSpace-Online erfüllt deine Anforderungen und bietet dir eine flexible Lernumgebung, beider die Qualität der Nachhilfe im Vordergrund steht.',
      tutorspace: 'TutorSpace',
      others: 'Andere Anbieter',
      content: [
        {
          point: 'Einfache und geeignete Lernumgebung',
          tutorspace: 'Im Web von überall zugänglich',
          others: 'Meist Installation mehrerer Programme notwendig.',
        },
        {
          point: 'Strenge Überprüfung der Lehrkräfte',
          tutorspace: 'Prüfungskonzept von Pädagogen',
          tutorspaceExtra: 'Nur 1/8 besteht das Auswahlverfahren.',
          others: '',
        },
        {
          point: 'Flexibler Lehrerwechsel',
          tutorspace: 'Einfach selbst entscheiden',
          others: '',
        },
        {
          point: 'Einfaches Buchen oder Ändern von Terminen',
          tutorspace: 'Einfach und unkompliziert',
          tutorspaceExtra: 'Termine individuell und flexibel',
          others: 'Meistens Terminfindung über Telefon, E-Mail oder Chat.',
        },
        {
          point: 'Termine am Wochenende',
          tutorspace: 'Uneingeschränkt lernen',
          others: '',
        },
        {
          point: 'Sicherheit und alle geläufigen Zahlungsmethoden',
          tutorspace: 'Für optimalen Komfort',
          others: '',
        },
      ],
    },
    advantages: {
      heading: 'Deine Vorteile im Überblick:',
      contents: {
        content1: {
          title: 'Betreuung',
          description: 'Eltern und Kinder haben bei uns einen\npersönlichen Ansprechpartner.',
        },
        content2: {
          title: 'Geprüfte Lehrkräfte',
          description: 'Alle Lehrer haben ein mehrstufiges\nAuswahlverfahren durchlaufen.',
        },
        content3: {
          title: '1:1 Unterricht',
          description: 'Bei uns wird dein Kind\nindividuell gefördert.',
        },
        content4: {
          title: 'Interaktives Lernen',
          description:
            'Unser virtuelles Klassenzimmer wurde\nspeziell für den Online-Unterricht entwickelt.',
        },
        content5: {
          title: 'Ersatzlehrer',
          description: 'Dein Lehrer kann einmal\nnicht? Wir finden für dich Ersatz.',
        },
        content6: {
          title: 'Sicherheit',
          description: 'Alle Lehrer haben ein einwandfreies\npolizeiliches Führungszeugnis',
        },
        content7: {
          title: 'Lernplan',
          description: 'Unser Lernplan wird individuell am\nLerntyp deines Kindes ausgerichtet.',
        },
        content8: {
          title: 'Anpassbarkeit',
          description: 'Das gewählte Unterrichtspaket kann jeden\nMonat einfach angepasst werden.',
        },
        content9: {
          title: 'Flexibilität',
          description: 'Nicht verwendete Stunden können\nauch noch im Folgemonat verwendet werden.',
        },
      },
    },
    advantagesV2: {
      heading: 'Deine Vorteile der Online-Nachhilfe im Überblick:',
      contents: {
        content1: {
          title: 'Betreuung',
          description:
            'Eltern und Kinder haben bei uns einen\npersönlichen Online-Tutor und Ansprechpartner.',
        },
        content2: {
          title: 'Geprüfte Lehrkräfte',
          description: 'Alle Online-Tutoren haben ein mehrstufiges\nAuswahlverfahren durchlaufen.',
        },
        content3: {
          title: '1:1 Unterricht',
          description: 'Bei uns wird dein Kind\nindividuell gefördert.',
        },
        content4: {
          title: 'Interaktives Lernen',
          description:
            'Unser virtuelles Klassenzimmer wurde\nspeziell für die Online-Nachhilfe entwickelt.',
        },
        content5: {
          title: 'Ersatzlehrer',
          description: 'Dein Lehrer kann einmal\nnicht? Wir finden für dich Ersatz.',
        },
        content6: {
          title: 'Sicherheit',
          description: 'Alle Online-Tutoren haben ein einwandfreies\npolizeiliches Führungszeugnis',
        },
        content7: {
          title: 'Lernplan',
          description:
            'Unser Lernplan für die Online-Nachhilfe wird individuell am\nLerntyp deines Kindes ausgerichtet.',
        },
        content8: {
          title: 'Anpassbarkeit',
          description: 'Das gewählte Unterrichtspaket kann jeden\nMonat einfach angepasst werden.',
        },
        content9: {
          title: 'Flexibilität',
          description:
            'Nicht verwendete Stunden können\nauch noch im Folgemonat für Online-Nachhilfestunden verwendet werden.',
        },
      },
    },
    reviews: {
      heading: 'Überzeuge dich selbst.\nWas Kinder und Eltern über uns sagen:',
    },
    howWeChooseElitetutors: {
      heading: 'So wählen wir die Lehrkraft\nfür dein Kind aus:',
      content: {
        bulletpoints_SEARCH_CARD: {
          regular1: 'Nur ca. ',
          bold: '15 %',
          regular2: ' aller Bewerbungen werden bei uns angenommen.',
        },
        bulletpoints_SCHOOL_ICON: {
          regular1: 'Prüfung der Kompetenzen durch',
          bold: ' fachliche Tests.',
          regular2: '',
        },
        bulletpoints_GROUP_ICON: {
          regular1: 'Nachweis der sozialen Kompetenzen durch',
          bold: ' Unterrichtssimulation.',
          regular2: '',
        },
        bulletpoints_SPEECH_ICON: {
          regular1: 'Individuelle und strukturierte',
          bold: ' Bewerbungsgespräche.',
          regular2: '',
        },
        bulletpoints_CHECK_ICON: {
          regular1: 'Background-Check durch erweitertes',
          bold: ' polizeiliches Führungszeugnis.',
          regular2: '',
        },
        promotion: 'Unsere Plattform wird gefördert durch den Innovationsfonds Rheinland-Pfalz.',
        promotionElite:
          'Unser Premium-Angebot wird gefördert durch den Innovationsfonds Rheinland-Pfalz.',
      },
      button: 'Kostenlos Nachhilfe finden',
      buttonTutorspacePlus: 'Gratis Probestunde buchen',
      buttonHomePage: 'Jetzt gratis testen',
    },
    seoBox: {
      item1: {
        title: 'TITLE1',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
      },
      item2: {
        title: 'TITLE2',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
      },
    },
    guideline: {
      headline: 'Unser Leitfaden für den Nachhilfeunterricht',
      textBold: 'In Kooperation mit dem VNN ',
      text:
        '(Bundesverband Nachhilfe- und Nachmittagsschulen) haben wir wertvolle Informationen, Tipps und Ansätze für Nachhilfelehrer zu Papier gebracht.',
      downloadButton: 'Kostenlose PDF-Datei herunterladen!',
    },
    endActionButton: {
      tutorspacePlus: 'Gratis Probestunde buchen',
      onlineTutoring: 'Jetzt mehr über TutorSpace Premium erfahren',
    },
    topPromotionBar: {
      textDesktop:
        'TutorSpace unterstützt ukrainische Geflüchtete - Jetzt kostenlos einen ehrenamtlichen Deutschlehrer finden. Mehr erfahren >>',
      textMobile: 'Wir unterstützen die Ukraine >>',
    },
    trustpilotPromotionBar: {
      text: 'Bessere Noten. Entspannte Eltern.',
    },
    promotionBar: {
      new: 'Neu',
      text: 'Mit TutorSpace Premium Lernlücken schließen! ›',
    },
    seo: {
      tutorspacePremium: {
        title: 'TutorSpace Premium - Individuelle Nachhilfe',
        description:
          'Optimale 1:1 Betreuung durch Premium-Tutoren. Lerntyporientierter Lernplan für den Schüler. Virtuelles Klassenzimmer für die interaktive Zusammenarbeit.',
      },
    },
    premium: {
      headingComparison:
        'Neben diesem Vergleichstool bieten wir auch individuelle Nachhilfe bei geprüften Lehrkräften',
      headingHomePage: 'So muss Nachhilfe sein.',
      bulletpoints: {
        bulletpoint1: 'Freude am Lernen, weil die Chemie zwischen Lehrer und Schüler stimmt',
        bulletpoint2: 'Lernen im 1:1 Unterricht mit lerntyporientiertem Lernplan',
        bulletpoint3: 'Interaktiver Unterricht in unserem modernen Online-Klassenzimmer',
      },
      button: 'Mehr über TutorSpace Premium erfahren',
      text:
        '<b>Unabhängig von unserer freien Vergleichsplattform</b> bieten wir mit TutorSpace Premium eigene hochqualifizierte Online-Lehrkräfte ab 19,95€/pro Stunde, sodass dein Kind sein volles Potential entfalten kann.',
    },
    refugeeHeader: {
      news: 'Aktuelles',
    },
    donationPromotion: {
      heading: 'Mit jeder Stunde\nfördern wir Unterricht in Tansania ',
      infoBoxBadge: 'Gültig für Online-Unterricht innerhalb von TutorSpace Premium',
      paragraphs: {
        paragraph1:
          'Das ist unser Versprechen an dich und an Kinder, für die Bildung nicht selbstverständlich ist.\n' +
          '“Stunde für Stunde” - so wollen wir mit TutorSpace Premium unseren Beitrag zu mehr Bildungsgerechtigkeit leisten.',
        paragraph2:
          'Du bist dir nicht sicher, ob das in Zeiten des Greenwashings nur Marketing ist?\nWir verstehen das! Deshalb legen wir transparent offen, <a href="{{link}}">wo der Unterricht stattfindet.</a>',
      },
    },
    refugeeInitiativePromotion: {
      heading: 'Gemeinsam Geflüchteten\nhelfen, Deutsch zu lernen und anzukommen',
      paragraphs: {
        paragraph1:
          'Auch wir hoffen natürlich für alle Geflüchteten, dass sie möglichst bald wieder in ihre gewohnte Umgebung zurückkehren können. Wir sind aber auch realistisch und befürchten, dass vielen für absehbare Zeit eine Rückkehr verwehrt sein wird. Umso wichtiger erscheint es uns, ihnen eine schnelle und gute Integration in Deutschland zu ermöglichen und hierbei ist Sprache ein wesentlicher Faktor.',
        paragraph2:
          'Wir stellen deshalb unsere Plattform für entsprechenden Unterricht kostenfrei zur Verfügung. Natürlich sind Geflüchtete aus allen Ländern herzlich willkommen.',
      },
      infoBox: {
        text1: 'Gratis Vermittlung\nvon Deutschlehrern',
        text2: 'Wir stellen Lern-\nunterlagen bereit',
        text3: 'Koordination mit\nFlüchtlingszentren',
        text4: 'Organisation von\nDeutschlehrern',
      },
      button: 'Jetzt mehr erfahren',
    },
    refugeesBecomeTeacher: {
      heading: 'Unterstütze Geflüchtete!\nMelde dich als ehrenamtlicher Helfer.',
      description:
        'Sprichst du Deutsch? Dann kannst du auch mithelfen. Mit unseren speziellen Lernunterlagen muss man kein Lehrer sein, um Deutsch auf dem einfachsten Niveau beizubringen. Jede Hilfe zählt!',
      button: 'Jetzt Geflüchteten helfen',
    },
    firstAidKitGerman: {
      heading: 'Unser Erste-Hilfe-Set Deutsch',
      description:
        'Natürlich kann man auf schnellem Wege den Geflüchteten beibringen, sich zu verständigen. Bei uns erhalten alle ehrenamtlichen Helfer Lernunterlagen mit Audiodateien und PDFs für die 10 wichtigsten Themenbereiche.',
      list:
        'Deutsches Alphabet und Leseregeln\n' +
        'Sich begrüßen und verabschieden\n' +
        'Kennenlernen\n' +
        'Meine Familie\n' +
        'Um Hilfe bitten und sich verständigen\n' +
        'Wie geht’s? Gesundheit und Wohlbefinden\n' +
        'Danke, Bitte & Entschuldigung\n' +
        'Einkaufen\n' +
        'Formulare ausfüllen\n' +
        'Unterwegs',
      buttonDownloadUkrainian: 'Jetzt herunterladen (Ukrainisch)',
      buttonDownloadRussian: 'Jetzt herunterladen (Russisch)',
    },
    refugeesAboutUs: {
      heading: 'Wer wir sind',
      description:
        'Wir sind ein Team aus Freiwilligen, Unternehmern und Experten, das geflüchteten ukrainischen Familien hilft, sich durch eine bessere deutsche Sprache in Deutschland zurechtzufinden. Die Initiative wird von TutorSpace und DeinSprachcoach organisiert.',
      people: [
        {
          name: 'Patrick Nadler',
          role: 'Organisator',
          description:
            'Gründer und Geschäftsführer TutorSpace, Vorstandsvorsitzender Bundesverband für Nachhilfe und Nachmittagsschulen',
          url: 'https://www.linkedin.com/in/patrick-nadler/',
        },
        {
          name: 'Maria Hasbolat',
          role: 'Organisator',
          description:
            'Gründerin DeinSprachcoach, hilft täglich 1.000.000+ Fans, die deutsche Sprache zu meistern - Instagram, TikTok & YouTube',
          url: 'https://www.linkedin.com/in/maria-hasbolat-982307122/',
        },
        {
          name: 'Dr. Christoph Jaschinski',
          role: 'Organisator',
          description:
            'Gründer und Prokurist TutorSpace, Arzt, Wissenschaftler und Unternehmer – studierte Medizin, Biochemie und Jura',
          url:
            'https://www.linkedin.com/in/dr-med-christoph-h-h-jaschinski-mag-iur-heidelberg-a0b47872/',
        },
        {
          name: 'Dr. Felix M. Michl',
          role: 'Organisator',
          description:
            'Gründer und Prokurist TutorSpace, Rechtsanwalt; davor Juraprofessor und Geschäftsführer des Start-ups "SCRAEGG"',
          url: 'https://www.linkedin.com/in/dr-felix-m-michl-bb711933/',
        },
      ],
    },
    refugeesOurPartners: {
      heading: 'Unsere Partner',
    },
    refugeesWeNeedHelp: {
      heading: 'Wir brauchen deine Hilfe!',
      description:
        'Wenn du denkst, dass du uns bei der Rekrutierung, Betreuung, Kommunikation oder finanziell helfen kannst, lass es uns bitte wissen.',
      button: 'Jetzt Kontakt aufnehmen',
    },
    promotionCertified: {
      reviews: 'Bewertungen',
      reviewRatingInWords: 'Hervorragend',
      verifiedCompany: 'VERIFIZIERTES UNTERNEHMEN',
      // remember to change the JSON-LD in ReviewReferences.tsx when changing `numberOfReviews` or `averageRating`
      numberOfReviews: '206',
      averageRating: '4,8',
    },
    knownFrom: {
      headline: 'Bekannt aus:',
    },
    pressContact: {
      button: 'In Kontakt treten',
    },
  },
  userProfile: {
    heading: 'Hallo %{firstName}!',
    officialName: 'Offizieller Name',
    updateUserNameForm: {
      description:
        'Dies ist der Name auf deinem Reisedokument, wie z.B. einem Führerschein oder Ausweisdokument. Andere Nutzer auf TutorSpace können nur deinen Vornamen und den ersten Buchstaben deines Nachnamens sehen.',
    },
    email: 'E-Mail Adresse',
    updateEmailForm: {
      description:
        'Diese E-Mail wird bei der Kontaktvermittlung an deinen Lernpartner übertragen. Also stelle sicher, dass du diese E-Mail bestätigst und dort regelmäßig Zugriff hast.',
      field: 'Neue E-Mail eingeben',
    },
    messages: {
      yourConversations: 'Deine Konversationen',
      noMessages: 'Aktuell sind noch keine Nachrichten vorhanden.',
      noResultsCTAHeader:
        'Wenn du einen Lehrer anfragst, siehst du hier den Verlauf und die Kontaktdaten.',
      noResultsCTAButton: 'Jetzt Lehrer anfragen',
    },
    deleteAccount: {
      button: 'Mein Konto löschen',
      confirmation: {
        title1: 'Willst du wirklich',
        title2: 'dein Profil löschen?',
        description1: 'Es tut uns Leid, wenn wir deinen Erwartungen nicht gerecht geworden sind.',
        description2: 'Es gehen alle Daten, auch dein Unterrichtsangebot, verloren....',
        cancelButton: 'Zurück zum Profil',
        okButton: 'Ich will meinen Account löschen!',
      },
      notification: 'Dein Account wurde gelöscht.',
    },
    deleteOffer: {
      deleteOfferCTA: 'NACHHILFEANGEBOT LÖSCHEN',
      modalTitle1: 'Willst du wirklich dein',
      modalTitle2: 'Angebot löschen?',
      modalText1: "Du kannst dein Angebot auch jederzeit im Account temporär 'inaktiv' schalten.",
      modalText2: 'Damit gehen nicht alle deine Daten verloren.',
      backToProfile: 'Zurück zum Profil',
      confirmDeletion: 'Nachhilfeangebot löschen!',
    },
    updatePhoneForm: {
      fields: {
        newPhoneNumber: 'Neue Telefonnummer eingeben',
        code: 'Gib deinen Sicherheitscode ein',
      },
      updatingPhoneDescription:
        'Dies ist die Telefonnummer, die nach einer erfolgreichen Vermittlung an deinen Lernpartner übertragen wird, um dich zu kontaktieren.',
      codeDescription:
        'Wir haben dir deinen Code per Textnachricht an {{phoneNumber}} geschickt. Es kann einen Moment dauern, biser eintrifft.',
      sendCodeAgainButton: 'Code nicht erhalten? Versuche es noch einmal',
    },
  },
  tutoringInstituteProfile: {
    onMobile: {
      backToHomepage: 'Zurück zur Homepage',
      text1: 'Suchst du nach deiner Nachhilfeschule?',
      text2: 'Melde dich auf tutorspace.de auf deinem Computer an.',
    },
    yourCooperation: 'Derzeitige Kooperation',
    subscriptions: {
      monthly: 'Monatlich',
      PPLMonthly: 'Monatlich {{amount}}/Kontakt',
      placeSingle: '1 Standort',
      placeSmallRange: '2-{{count} Standorte',
      placeLargeRange: '{{count}}+ Standorte',
      price: '{{currency}}{{price}} / {{interval}}',
      interval: {
        [SubscriptionInterval.Month]: 'Monat',
      },
      perPlace: 'Pro Standort',
      features: {
        title: 'Alle Funktionen enthalten',
        items1: 'Direkte Verlinkung',
        items2: 'Unendlich Kunden',
        items3: 'Starker Auftritt',
        items4: '7-Tage in der Woche Kundenservice',
      },
      subscriptionUsage: {
        noActiveSubscriptions: 'Kein aktives Abonnement',
        first: 'Premium-Anzeigen: 1 Standort',
        second: 'Premium-Anzeigen: 2-10 Standorte',
        third: 'Premium-Anzeigen: 10+ Standorte',
        PPL: 'Zahlung pro gekauften Kontaktdaten',
      },
      provideBillingInfo: 'Bitte gib deine Zahlungsinformationen an.',
      payDueInvoices:
        'Bitte zahle die fälligen Rechnungen, sonst werden deine Angebote für andere unsichtbar.',
      payDueInvoicesFromPreviousSubscription:
        'Bitte zahle fällige Rechnungen aus dem vorherigen Abonnement, um die Aktivierung eines neuen zu ermöglichen.',
      publishLocationToStartSubscription:
        'Du hast die Zahlungsmethode und Rechnungsadresse hinzugefügt. Veröffentliche deinen Standort, um das Abonnement zu starten.',
      subscriptionNextPaymentIs:
        'Die nächste Zahlung ist {{amount}} {{currency}} und wird am {{date}} eingezogen.',
      subscriptionNextPaymentIsAutomatic: 'Die Zahlung wird automatisch monatlich verlängert.',
      PPLNextPaymentIs:
        'Die nächste Zahlung ist {{amount}} {{currency}} und wird am {{date}} eingezogen. Dabei ändert sich die Zahlung mit der Anzahl an gekauften Leads bis zum Monatsende.',
      discountLabel: '-{{percentOff}}% RABATT ANGEWENDET',
      cancelSubscription: {
        button: 'Abo kündigen',
        confirmation: {
          title1: 'Möchtest du das Abonnement',
          title2: 'wirklich kündigen?',
          cancelButton: 'Zurück zum Profil',
          okButton: 'Ich möchte das Abonnement kündigen!',
        },
        notification: 'Abonnement wurde gekündigt.',
        cancelBanner: 'Das Abonnement wird am {{date}} automatisch gekündigt',
      },
    },
    paymentMethods: 'Zahlungsmethoden',
    invoices: {
      title: 'Rechnungen',
      subscriptionChangesNote: 'Änderungen werden ab der nächsten Rechnung gültig.',
      columnTitle: {
        productName: 'Produkt',
        number: 'Referenz',
        date: 'Datum',
        price: 'Zahlung',
        status: 'Status',
        download: 'Download',
      },
      status: {
        pay: 'Zahlen',
        paid: 'Bezahlt',
      },
    },
    cardExpiresAt: 'Ablaufdatum',
    payByInvoice: 'Rechnung erfolgt am Monatsende.',
    standardPaymentMethod: 'Standard',
    setAsStandardMethod: 'Als Standard festlegen',
    removePaymentMethod: 'Löschen',
    offersPage: {
      statistics: 'Gesamt',
      yourOffers: 'Deine Schulen',
      offersOnline: 'Standorte online',
      reviews: 'Bewertung',
      totalOffersAppearedOnSearch: 'In Suche erschienen',
      totalOffersDetailsDisplayed: 'Besucher',
      addOffer: 'Anbieten',
      active: 'Aktiv',
      inactive: 'Inaktiv',
      warningOffersAreInactive:
        'Bevor du deine Angebote aktivieren kannst, musst du eine Zahlungsmethode hinterlegen und die Rechnungsinformationen in den Kontoeinstellungen angeben. Sind diese Informationen hinterlegt und es bestehen keine offenen Rechnungen, kannst du dein Nachhilfeangebot mit dem Schalter auf "aktiv" setzen.',
      warningOffersAreInactiveTooltip:
        'Bitte behebe Probleme, damit die Standorte & die Verlinkungen wieder für Google und unsere Nutzer sichtbar sind.',
      deleteOffer: {
        modalTitle1: 'Willst du wirklich dein',
        modalTitle2: 'Angebot löschen?',
        modalText1: "Du kannst dein Angebot auch jederzeit im Account temporär 'inaktiv' schalten.",
        modalText2: 'Damit gehen nicht alle deine Daten verloren.',
        back: 'Zurück zum Panel',
        confirmDeletion: 'Nachhilfeangebot löschen!',
      },
      offerTable: {
        offerName: 'Name',
        location: 'Ort',
        street: 'Straße',
        rating: 'Bewertung',
        foundInSearch: 'In Suche erschienen',
        visitor: 'Besucher',
        status: 'Status',
        edit: 'Bearbeiten',
        delete: 'Löschen',
      },
    },
    paymentsPage: {
      addPaymentMethodCTA: 'Zahlungsmethode hinzufügen',
      editBillingInfoCTA: 'Rechnungsadresse hinzufügen',
      billingInfoForm: {
        header: 'Rechnungsinformationen',
        warning: 'Änderungen werden ab der nächsten Rechnung gültig.',
        name: 'Name des Unternehmens',
        email: 'E-Mail für die Rechnung',
        address1: 'Straße und Hausnummer',
        country: 'Land',
        city: 'Stadt',
        zip: 'ZIP/PLZ',
        vat: 'VAT/UST-ID',
        vatPlaceholder: 'z.B DE123456789',
        vatId: 'VAT ID',
        confirmButton: 'Änderungen speichern',
      },
      addMethodForm: {
        header: 'Zahlungsmethode hinzufügen',
        creditCardData: 'Kreditkarteninformationen',
        invoice: {
          header1: 'Bezahle mit Rechnung',
          infoDisclaimer: 'Du musst die Rechnung jeden Monat manuell bezahlen.',
          description1:
            'Wenn du die Zahlung per Rechnung hinzufügst, wird diese als Standard festgelegt. Ab diesem Moment musst du die Rechnungen jeden Monat manuell bezahlen.',
          description2:
            'Jeden Monat erhältst du eine Rechnung per E-Mail mit einem Zahlungslink. Außerdem wird es in der Rechnungsliste angezeigt.',
        },
        savePaymentMethodCTA: 'Zahlungsmethode hinzufügen',
        sepa: {
          accountNumber: 'SEPA-Kontonummer',
          accountHolder: 'Angaben zum Kontoinhaber',
          legal:
            'Wir ermächtigen (A) TutorSpace GmbH und Stripe, dem Zahlungsdienstleister, Zahlungen von unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weisen wir unser Kreditinstitut an, die von TutorSpace GmbH und Stripe auf unser Konto gezogenen Lastschriften einzulösen. Hinweis: Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit unserem Kreditinstitut vereinbarten Bedingungen.',
        },
        paymentMethod: {
          card: 'Kreditkarte',
          SEPA: 'SEPA',
          invoice: 'Rechnung',
        },
      },
      confirmPaymentStartModal: {
        title: 'Standort aktivieren',
        content:
          'Du aktivierst deinen ersten Standort. Ab sofort wird dann jeder öffentliche Standort in Rechnung gestellt. Der Preis pro Standort kann je nach Anzahl der Standort variieren.',
        confirmCTA: 'Standort aktivierien',
        cancelCTA: 'Abbrechen',
      },
      lastPayment: 'Letzte Zahlung: ',
      noPaymentYet: 'Es gab noch keine Zahlung.',
      billingInfoIsNotProvided: 'Du hast keine Rechnungsadresse hinzugefügt.',
    },
  },
  tutorDetails: {
    info: {
      age: 'Jahre alt',
      impressum: 'Impressum',
      subjectsOffered: 'Angebotene Fächer',
      reviews: 'Bewertungen',
      elitetutor: '🥇 TutorSpace Premium',
      elitetutorExtraInfo: 'Diese Lehrkraft gehört zu den besten 1% in Deutschland.',
    },
    approximateLocationMap: {
      title: 'Ungefährer Ort der Anzeige',
    },
  },
  publishOffer: {
    emptyState: {
      CTAHeader: 'Worin bist du gut?',
      CTAButton: 'Nachhilfeangebot erstellen',
    },
    pageHeaderText: 'Nachhilfeangebot erstellen',
    backButton: 'Zurück',
    subjects: {
      heading: 'In welchem Fach willst du Nachhilfe geben?',
      inputLabel: 'Deine Unterrichtsfächer',
      placeholder: 'Fach hinzufügen (z.B. Mathe)',
    },
    description: {
      heading: 'Beschreibe Schülern und Eltern dein Angebot',
      paragraph:
        'Schreib eine kurze Zusammenfassung über dich und deine Nachhilfe. Du kannst beispielsweise hervorheben, was an deinem Unterricht und dir besonders ist und wie die Nachhilfe ablaufen wird.',
      paragraph2:
        'Wenn du geflüchteten Familien in Deutsch hilfst: Nutze doch bitte Google Translate, um deine Beschreibung ein weiteres Mal in Russisch übersetzt einzufügen.',
      note:
        'Hinweis: Bitte gib in der Beschreibung keine Kontaktdaten an. Bei der Vermittlung sorgen wir für eine sichere Übertragung deiner Daten.',
    },
    descriptionInstruction: {
      heading: 'Tipps, wie dein Nachhilfeangebot episch wird',
      paragraphs: {
        paragraph1: 'Stelle sicher, dass du auf folgende Informationen eingehst:',
        paragraph2:
          '- Deine Qualifikationen/ Noten\n- Persönliche Informationen zu dir\n- Wie lange gibst du schon Nachhilfe?',
        paragraph3:
          'Es geht ja schließlich darum, einen Schüler oder ein Elternteil von dir zu überzeugen oder?',
        paragraph4:
          'Nutze die Gelegenheit, um schon beim ersten Kontakt hier auf TutorSpace Vertrauen aufzubauen.',
        paragraph5: 'Zeige, dass du der beste Tutor in der Umgebung bist!',
      },
    },
    location: {
      heading: 'In welcher Umgebung findet der Unterricht statt?',
      paragraph:
        'Schüler können deine genaue Adresse nicht öffentlich einsehen. Wir nutzen die Daten nur, um deinen Unterricht für Suchende in der Umgebung anzuzeigen. Wenn du nur online unterrichten willst, wird die Adresse von uns nicht weiter verwendet, sie ist aber eine Pflichtangabe.',
      landRegion: {
        label: 'Land/Region',
        placeholder: 'Wähle hier ein Land/eine Region aus.',
      },
      street: {
        label: 'Adresse',
        placeholder: 'z.B. Hauptstraße 4',
      },
      city: {
        label: 'Stadt',
        placeholder: 'z.B. Berlin',
      },
      postcode: {
        label: 'Postleitzahl',
        placeholder: 'z.B. 13581',
      },
    },
    video: {
      heading: 'Optional: Stelle dich in einem Video vor',
      paragraph:
        'Durch ein kurzes Vorstellungsvideo hebst du dich von anderen Nachhilfelehrern ab. Überzeuge Eltern, warum genau DU perfekt geeignet bist, ihr Kind zu unterstützen.',
      uploadVideo: 'Video hochladen',
      dragVideo: 'oder ziehe es hier rein',
      uploadNewVideo: 'Neues Video hochladen',
      deleteVideo: 'Video löschen',
      deleteVideoWarning: 'Willst du das Profilvideo wirklich löschen?',
      approvalInfo: {
        heading: 'Dein Video wird im Nachgang manuell überprüft',
        text:
          'Nach Veröffentlichen deines Nachhilfeangebotes prüfen wir dein Video innerhalb von 48h. Du bekommst per Mail Bescheid, sobald es auf TutorSpace sichtbar ist.',
      },
    },
    photo: {
      heading: 'Füge deinem Nachhilfeangebot ein Foto hinzu',
      paragraph:
        'Hilf Schülern, sich ein Bild von dir zu machen und Vertrauen aufzubauen. Angebote mit Bild bekommen im Durchschnitt 2.5x mehr Anfragen. Du kannst dein Bild später jederzeit ändern.',
      uploadPhoto: 'Foto hochladen',
      dragPhoto: 'oder ziehe es hier rein',
      uploadNewPicture: 'Neues Bild hochladen',
      uploadPhotos: 'Lade bis zu {{maxPhotoCount}} Bilder hoch oder lege sie hier ab',
      uploadPhotoMaxSizeDescription: 'bis zu {{maxPhotoSize}} pro Bild',
      defaultPhoto: 'Titelbild',
    },
    photoInstruction: {
      heading: 'Tipps, wie du tolle Fotos für dein Nachhilfeangebot machst',
      paragraphs: {
        paragraph1:
          'Ein seriöses und qualitativ hochwertiges Foto sorgt für mehr Vertrauen bei potenziellen Schülern. Das Foto gehört zu den ersten Dingen, die sie in deiner Anzeige sehen werden! Und außerdem, unter uns, {{firstName}}: Du siehst fantastisch aus!',
        paragraph2:
          "Nimm das Foto im Portrait-Format auf, um das beste Ergebnis zu erzielen. Setz dein bestes Lächeln auf und 'KLICK': Das Foto ist fertig.",
        paragraph3:
          'Portraitbilder sehen in natürlichem Licht am besten aus. Wenn du die Fotos abends oder nachts aufnimmst, mach das Licht an. Vermeide es, den Blitz zu benutzen.',
      },
    },
    videoInstruction: {
      heading: 'So überzeugst du Eltern ohne Probleme:',
      paragraphs: {
        paragraph1:
          'Gewinne das Vertrauen von Eltern, in dem sie dich als Person kennen lernen können - schon vor der ersten Nachhilfeeinheit!',
        paragraph2:
          'So wird dein Video perfekt:\n- Achte auf einen ordentlichen und sauberen Hintergrund.\n- Rede direkt mit dem Elternteil\n- Eine Handykamera ist vollkommen ausreichend\n- Verwende warmes Licht\n- Die Kamera während der Aufnahme wenig bewegen\n- Wenig Hintergrundgeräuche\n- Verzichte auf Filter oder Special Effects',
        paragraph3:
          'Dinge, über die du sprechen kannst:\n- Einen Satz, in dem du dich als Person vorstellst,\n- Warum gibst du Nachhilfe?\n- Was macht deinen Unterricht besonders\n - Was unterrichtest du?',
        paragraph4:
          'Und zum Schluss der wichtigste Tipp: Keine Angst vor der Kamera und hab Spaß bei der Aufnahme Du siehst top aus :-)',
      },
    },
    price: {
      heading: 'Lege einen Preis für deine Nachhilfe fest',
      paragraph1:
        'Verbessere deine Chancen auf neue Schüler. Richte deinen Stundenpreis so ein, dass dein Unterricht in deiner Umgebung wettbewerbsfähig ist.',
      paragraph2:
        'Wenn du ausschließlich im Bereich “Deutsch für Geflüchtete” unterrichten willst, kannst du einen beliebigen Preis angeben, da jedes Engagement gegenüber geflüchteten Familien ehrenamtlich ist.',
      label: 'Preis pro 45 Minuten',
    },
    priceInstruction: {
      heading: 'Den passenden Preis für deine Nachhilfestunde festlegen',
      paragraphs: {
        paragraph1: 'Der Preis ist u.a. abhängig von folgenden Faktoren:',
        paragraph2:
          '- Qualifikationen des Tutors\n- Klassenstufe und Schulform\n- Ort des Unterrichts\n- Benötigtes Unterrichtsmaterial',
      },
    },
    level: {
      heading: 'Bis zu welchem Niveau unterrichtest du?',
      paragraph1:
        'Gib hier an, bis zu welchem Niveau du Nachhilfe in den angegebenen Fächern unterrichten willst.',
      paragraph2:
        'Wenn du ausschließlich im Bereich “Deutsch für Geflüchtete” unterrichten willst, musst du eine beliebige Option angeben, da jedes Engagement gegenüber geflüchteten Familien das Basisniveau Deutsch betrifft.',
      label: 'Ich unterrichte bis ...',
      placeholder: 'z.B. Kursstufe',
    },
    place: {
      heading: 'Wo soll die Nachhilfe stattfinden?',
      paragraph:
        'Gehe auf die Bedürfnisse der Interessenten ein, indem du bei dir selbst, beim Schüler oder auch als Online-Nachhilfe über den Computer unterrichtest.',
    },
    age: {
      heading: 'In welchem Jahr bist du geboren?',
      paragraph:
        'Schaffe Vertrauen bei Eltern und Schülern. Unsere Erfahrung von tausenden Vermittlungen haben gezeigt, dass sich durch das ungefähre Alter die Interessenten sicherer fühlen und dein Angebot anfragen.',
      label: 'Geburtsjahr',
      placeholder: 'z.B. 1987',
    },
    guide: {
      heading: 'Die Vorteile mit unserem Tutorenprogramm',
      example1: {
        heading: '1. Interessierte Kunden finden dein Inserat',
        paragraph:
          'Jeder, der bei dir Nachhilfe nehmen möchte, muss seine Kontaktdaten bestätigen.',
      },
      example2: {
        heading: '2. Wenn ein Schüler dich anfragt, wirst du benachrichtigt',
        paragraph:
          'Du erhältst sofort eine E-Mail und eine SMS auf dein Handy, sobald dich jemand anfragt. So bist du immer sofort informiert.',
      },
      example3: {
        heading: '3. Du legst fest, wer bei dir unterrichtet wird',
        paragraph:
          'Damit deine Kontaktdaten  verschlüsselt übergeben werden, musst du jede Anfrage selbst annehmen. So hast du immer volle Kontrolle, wer deine Daten bekommt.',
      },
    },
    publish: {
      heading: 'Du kannst dein Nachhilfeangebot jetzt veröffentlichen!',
      paragraph:
        'Du kannst ab heute deine ersten Anfragen bekommen. Wenn du deine Angaben aktualisieren möchtest, kannst du dies ganz einfach in deinem Profil heraus machen.',
      publishButton: 'Nachhilfeangebot veröffentlichen',
      updateButton: 'Nachhilfeangebot aktualisieren',
    },
    error: {
      unsupportedMediaType: {
        UNSUPPORTED_MEDIA_TYPE: 'Nicht unterstütztes Video-Format.',
        MEDIA_INFO_INVALID: 'Nicht unterstützter Videoinhalt. {{errorDetail}}',
        VIDEO_DURATION_LIMIT_EXCEEDED: 'Videolänge darf 60s nicht überschreiten.',
      },
    },
  },
  publishInstituteOffer: {
    name: {
      heading: 'Gib den Namen deiner Schule ein',
      paragraph:
        'Hilf Schülern, Eltern und Tutoren dein Angebot anhand deiner Marke  zu erkennen. Dein Name wird in deiner Anzeige mit dem Stadtnamen kombiniert, sodass die Schule einfach über Google auffindbar ist.',
      label: 'Markenname des Institutes (ohne Ortsbezeichnung)',
      placeholder: 'Hier eingeben',
      instruction: {
        heading: 'Wie unterstützen wir dich, auf Google gefunden zu werden?',
        paragraphs: {
          paragraph1:
            'Durch die Kombination aus Wörtern wie "Nachhilfeschule", dem Markennamen und der Stadt sorgen wir dafür, dass du auch unabhängig von TutorSpace besser über Google gefunden wirst.',
          paragraph2:
            'Zusammen mit den verfügbaren Bewertungen, unserer Qualitätssicherung und allen Informationen über die Nachhilfeschule vertraut Google unserer Plattform als objektiver Vergleichsplattform.',
        },
      },
    },
    photo: {
      heading: 'Füge deinem Nachhilfeangebot ein Foto hinzu',
      paragraph:
        'Hilf Schülern/Eltern sich ein Bild von dir zu machen und Vertrauen aufzubauen. Angebote mit Bild bekommen im Durchschnitt  2.5x mehr Anfragen. Du kannst dein Bild später jederzeit  ändern und bis zu 7 Bildern hochladen.',
      instruction: {
        heading: 'Tipps, wie du tolle Fotos von deiner Schule machst',
        paragraphs: {
          paragraph1:
            'Seriöse und qualitativ hochwertige Fotos sorgen für mehr Vertrauen bei potenziellen SchülerInnen. Die Bilder gehört zu den ersten Dingen, die sie in Deiner Anzeige sehen werden!',
          paragraph2: 'Bei Nachhilfeschulen funktionieren sehr gut vor allem:',
          paragraph3:
            '- Bilder vom Gebäude außen\n- Bilder des Teams\n- Bilder während des Unterrichts\n- Bilder wie Logo oder Siegel',
        },
      },
    },
    location: {
      heading: 'In welcher Umgebung findet der Unterricht statt?',
      paragraph:
        'Wir nutzen die Daten nur, um deinen Unterricht für Suchende in der Umgebung  anzuzeigen. So können wir beispielsweise einer Mutter in Köln auch Nachhilfeinstitute in Köln vorschlagen.',
    },
    price: {
      heading: 'Lege einen Preis für die Nachhilfe fest',
      paragraph:
        'Selbstverständlich variiert der Preis bei verschiedenen möglichen Leistungen deiner Schule. Näheres dazu kannst du jederzeit im Freitext erläutern. Diese Angabe dient lediglich als Vergleichswert.',
      label: 'Mindestpreis pro Stunde',
    },
    level: {
      heading: 'Bis zu welchem Niveau wird unterrichtet?',
      paragraph:
        'Gebe hier an, bis zu welchem Niveau die Nachhilfe in den angegebenen Fächern unterrichtet wird.',
      label: 'Wir unterrichten bis zur',
    },
    subjects: {
      heading: 'In welchen Fächern wird Nachhilfe angeboten?',
      inputLabel: 'Unterrichtsfächer',
    },
    place: {
      heading: 'Wo kann der Unterricht stattfinden?',
      paragraph:
        'Gehe auf die Bedürfnisse der Interessenten ein, indem du als Nachhilfeschule die Leistungen ankreuzt, die du anbietest. So wissen Eltern und Schüler genau, was auf sie zukommt.',
    },
    groupSizes: {
      heading: 'Auf welche Art wird unterrichtet?',
      paragraph:
        'Wird bei dir in der Nachhilfeschule in Gruppen unterrichtet oder ausschließlich alleine mit dem Lehrer? Vielleicht wird ja auch beides angeboten?',
    },
    openingHours: {
      heading: 'Wann hat deine Nachhilfeschule geöffnet?',
      paragraph:
        'Durch die Öffnungszeiten wissen Schüler und Eltern wann du zu erreichen bist. Falls du Vor-/Nachmittags bzw. am Wochenende geschlossen hast, kannst du einfach die Felder leer lassen.',
    },
    contact: {
      heading: 'Wie können Schüler dich erreichen?',
      paragraph:
        'In deinem Nachhilfeangebot werden die direkten Kontaktdaten angezeigt. So finden interessierte Eltern ohne Umwege direkt zu deiner Nachhilfeschule. Über einen Button kommst du auch direkt zu deiner Website, um mehr zu erfahren.',
      primaryContact: {
        label: 'Primärer Ansprechpartner',
        placeholder: 'z.B. Max Mustermann',
      },
      phoneNumber: {
        label: 'Telefonnummer',
        placeholder: 'z.B.  +49600700800',
      },
      email: {
        label: 'E-mail-Adresse',
        placeholder: 'E-mail-Adresse',
      },
      websiteUrl: {
        label: 'Link zur Website',
        placeholder: 'z.B. www.nachhilfeschulex.de',
      },
      impressumUrl: {
        label: 'Link zum Impressum',
        placeholder: 'z.B. schulex.de/impressum',
      },
      impressumFile: {
        label: 'Oder lade eine PDF hoch',
      },
    },
    errors: {
      fillRequiredAndChooseDefaultImage:
        'Bitte fülle alle Felder aus und wähle das Titelbild für dein Angebot aus.',
    },
  },
  offerListCard: {
    title: '{{firstName}} gibt Nachhilfe in {{subjects}}',
    titleRefugees: '{{firstName}} hilft dir, Deutsch zu lernen',
    subjectRefugees: 'Deutsch für Geflüchtete',
  },
  offerDetails: {
    description: 'Beschreibung',
    teachingLevelUpTo: 'Unterrichtsniveau bis:',
    classLocation: 'Unterrichtsort',
    groupTypes: 'Unterrichtsart',
    contact: 'Kontakt',
    contactPerson: 'Ansprechpartner',
    phone: 'Telefonnummer',
    email: 'E-Mail Adresse',
    hours: 'Öffnungszeiten',
    notProvided: 'Nicht angegeben',
  },
  reportOffer: {
    reportCTA: 'Angebot melden',
    title1: 'Warum willst du das',
    title2: 'Nachhilfeangebot melden?',
    reasons: {
      [ReportOfferReason.NotTutoringOffer]: 'Es ist kein Nachhilfeangebot.',
      [ReportOfferReason.NotAvailable]: 'Es ist nicht mehr verfügbar.',
      [ReportOfferReason.MultipleAccounts]: 'Es wurde doppelt erstellt.',
      [ReportOfferReason.Offensive]: 'Unpassend/ anstößig.',
    },
    buttonText: 'Senden',
  },
  offerPdf: {
    documentTitle: 'Abreißzettel',
    title: 'Ich helfe dir in {{subjectList}}',
    priceLabel: 'Preis:',
    priceValue: 'Ab {{price}} pro 45 Minuten',
    phone: 'Tel: {{phoneNumber}}',
    email: 'Email: {{email}}',
    moreInformation1: 'Nähere Infos und Bewertungen unter:',
    moreInformation2: 'Auf ',
    moreInformation3: ' in {{city}} suchen oder QR-Code scannen.',
    ripOff: {
      tutoringInSubjects: 'Nachhilfe {{subjectList}}',
      moreInformation: ' für Bewertungen und mehr Informationen.',
    },
  },
  ratings: {
    showMoreCTA: 'Mehr erfahren',
    showAllReviewsCTApt1: 'Alle',
    showAllReviewsCTApt2: 'Bewertungen anzeigen',
    showMyRatingsCTA: 'Mehr zu den Bewertungen',
    badges: {
      expert: 'Experte im Fach',
      flexible: 'Super flexibel',
      motivator: 'Motivator',
      excellent: 'Exzellenter Unterricht',
      communication: 'TOP Kommunikation',
      fun: 'Lernen mit Spaß',
    },
  },
  becomeATutorPage: {
    title: 'Unterrichte auf deine Art und Weise',
    subTitle: 'Du bringst das Wissen. Wir vermitteln dir Schüler.',
    tutorWeb: 'Tritt unserer wachsenden Lehrer-Community bei!',
    goodSide: 'Worin bist du gut?',
    opinions: 'Meinungen von Eltern und Schülern',
    register: 'Melde dich jetzt an und erstelle dein erstes Unterrichtsangebot',
    faq: 'Fragen & Antworten',
    button: 'Jetzt Nachhilfeangebot erstellen',
  },
  searchResultsPage: {
    common: {
      subtitleOnline: 'Online-Nachhilfe in %{subject}',
      subtitleLocalCity: 'Nachhilfe in %{city}',
      subtitleLocalSubject: 'Nachhilfe in %{subject}',
      subtitleLocalSubjectCity: 'Nachhilfe für %{subject} in %{city}',
      titleNoInformation: '%{count} private Nachhilfelehrer',
      recommendedEliteTutor:
        'Aber vielleicht können dir unsere zertifizierten Premium-Tutoren in %{subject} helfen?',
      noResultsFound: 'Keine passenden Tutoren verfügbar.',
      noResultsFoundBerlin:
        'Keine passenden Tutoren verfügbar. Aber vielleicht findest du in anderen Stadtteilen welche.',
      nearbyCities: 'Orte mit Nachhilfelehrern in deiner Nähe',
      itemList: {
        level: 'Niveau:',
        ratings: 'Bewertungen',
        bookTrial: 'Kostenlose Probestunde buchen',
        book: 'Stunde buchen',
        bookTrialInfo: 'danach ab {{price}} / pro Schulstunde',
        bookInfo: 'ab {{price}} / pro Schulstunde',
        eliteLevel: 'Niveau: bis Abitur',
        more: 'weitere',
        verifiedTutor: 'Geprüfter Premium-Tutor',
      },
      infoSection: {
        title: 'Nachhilfelehrer in {{city}}: An deine Lernziele angepasste Schülernachhilfe.',
      },
      otherTutoringOptions: {
        local: {
          first: {
            title: 'Keine passenden lokalen Tutoren gefunden?',
            subTitle: 'Diese Tutoren unterrichten dich auch online in {{subject}}!',
            subTitleNoSubject: 'Diese Tutoren unterrichten dich auch online!',
          },
          second: {
            title: 'Bewährte Nachhilfeschulen in deiner Umgebung!',
            subTitle:
              'Hier findest du auf jeden Fall Nachhilfe für {{subject}} in der Umgebung {{city}}.',
            subTitleNoSubject: 'Hier findest du auf jeden Fall Nachhilfe in der Umgebung {{city}}.',
          },
        },
        school: {
          first: {
            title: 'Keine Nachhilfeschule, die dir gefällt?',
            subTitle:
              'Diese privaten Tutoren unterrichten dich auch bei dir zu Hause in {{city}} und {{subject}}!',
            subTitleNoSubject:
              'Diese privaten Tutoren unterrichten dich auch bei dir zu Hause in {{city}}!',
          },
          second: {
            title: 'Online-Nachhilfe ist eine tolle Möglichkeit, um zu lernen!',
            subTitle: 'Diese Tutoren unterrichten dich auch online in {{subject}}!',
            subTitleNoSubject: 'Diese Tutoren unterrichten dich auch online!',
          },
        },
        premium: {
          title: 'Keinen passenden Lehrer gefunden?',
          subTitle:
            'Einer unserer Bildungsberater meldet sich innerhalb von 24h bei dir und hilft dir, den passenden Nachhilfelehrer zu finden.',
          button: 'Jetzt Nachhilfelehrer finden',
        },
      },
    },
    schools: {
      title: '%{count} Nachhilfeschulen in %{city}',
      paragraph1:
        'Erfahrene und bewährte Nachhilfeschulen in {{city}} finden. Durch den Vergleich von Nachhilfeschulen in {{subject}} ersparst du dir so manchen Aufwand mit schwarzen Brettern oder Zeitungsanzeigen - in der Regel muss man ja sogar bei Verwandten nachfragen, wen sie empfehlen können. Und selbst dann hat man nur keinen Vergleich. Wir von TutorSpace helfen dir in dieser Situation.',
      paragraph2:
        'Folgende Nachhilfeschulen unterstützen dich mit Nachhilfe in Mathe, Nachhilfe in Englisch, Nachhilfe in Deutsch und vielen anderen Fächern. Worauf wartest du noch? Vergleiche passende Institute in {{city}} und kontaktiere sie dann direkt über E-Mail, Telefon oder ihre Website. Und schon kann die erste Nachhilfestunde beginnen!',
    },
    localTutors: {
      title: '%{count} private Nachhilfelehrer für %{subject}',
      paragraph1:
        'Qualifizierte und günstige private Nachhilfe in %{subject} finden. Durch den Vergleich von Nachhilfelehrern ersparst du dir so manchen Aufwand mit schwarzen Brettern oder Zeitungsanzeigen - in der Regel muss man ja sogar bei Verwandten nachfragen, wen sie empfehlen können. Und selbst dann hat man nicht wirklich einen Vergleich. Wir von TutorSpace helfen dir in dieser Situation.',
      paragraph1Extra:
        'Egal ob #berlin#%{subject}-Nachhilfe in Berlin#berlin#, #hamburg#Nachhilfe in Hamburg#hamburg#, #muenchen#Nachhilfe in München#muenchen#, #frankfurt#Frankfurt#frankfurt#, #stuttgart#Stuttgart#stuttgart# oder einer anderen Stadt in Deutschland.',
      paragraph2:
        'Folgende motivierte Lehrer geben dir private Nachhilfe in %{subject} und auch in vielen anderen Fächern. Worauf wartest du noch? Vergleiche passende Unterrichtsangebote und frage den passenden Tutor an. Und schon kann die erste Nachhilfestunde bei dir vor Ort oder online beginnen!',
    },
    onlineTutors: {
      title: '%{count} private Nachhilfelehrer für Online-Nachhilfe in %{subject}',
      paragraph1:
        'Qualifizierte und günstige #online#Online-Nachhilfe finden#online#. Durch den Vergleich von Nachhilfelehrern in %{subject} ersparst du dir so manchen Aufwand mit schwarzen Brettern oder Zeitungsanzeigen - in der Regel muss man ja sogar bei Verwandten nachfragen, wen sie empfehlen können. Und selbst dann hat man nicht wirklich einen Vergleich. Wir von TutorSpace helfen dir in dieser Situation.',
      paragraph2:
        'Folgende motivierte Lehrer geben dir #physik#Online-Nachhilfe in Physik#physik#, #mathe#Online-Nachhilfe in Mathe#mathe#, #deutsch-schule#Online-Nachhilfe in Deutsch#deutsch-schule#, #englisch#Online-Nachhilfe in Englisch#englisch# und auch in vielen anderen Fächern. Worauf wartest du noch? Vergleiche passende Unterrichtsangebote und frage den passenden Tutor an. Und schon kann die erste virtuelle Nachhilfestunde online beginnen!',
    },
    eliteTutors: {
      title: 'TutorSpace Premium:',
      subtitle: 'Die besten Lehrer Deutschlands für Online-Nachhilfe in %{subject}',
      paragraph1:
        'Alle Online-Lehrer durchlaufen ein sehr strenges Überprüfungsverfahren und nur ein winziger Bruchteil derer, die sich bewerben, schaffen es bei uns online zu unterrichten.',
      paragraph2:
        'Die Bereitstellung eines hervorragenden Unterrichts steht im Vordergrund unserer Arbeit im Bereich der Online-Nachhilfe. In unserem 1:1 Online-Unterricht wird dein Kind nur von den besten Tutoren in ganz Deutschland unterrichtet. Nach den speziellen Bedürfnissen, wobei du jederzeit den Tutor kostenlos wechseln kannst.',
    },
    localTutorsPerCity: {
      title: '%{count} private Nachhilfelehrer für %{subject} in %{city}',
      titleNoSubject: '%{count} private Nachhilfelehrer in %{city}',
      paragraph1:
        'Qualifizierte und günstige private Nachhilfe in %{city} finden. Durch den Vergleich von Nachhilfelehrern in Englisch, Nachhilfelehrern in Deutsch und vielen anderen Fächern ersparst du dir so manchen Aufwand mit schwarzen Brettern oder Zeitungsanzeigen - in der Regel muss man ja sogar bei Verwandten nachfragen, wen sie empfehlen können. Und selbst dann hat man nicht wirklich einen Vergleich, um den besten Nachhilfelehrer zu finden. Wir von TutorSpace helfen dir in dieser Situation.',
      paragraph2:
        'Folgende motivierte Lehrer geben dir #mathe#Mathe Nachhilfe#mathe#, #englisch#Englisch Nachhilfe#englisch#, #deutsch-schule#Deutsch Nachhilfe#deutsch-schule# und in vielen anderen Fächern. Worauf wartest du noch? Vergleiche passende Unterrichtsangebote in %{city} und frage den passenden Tutor an. Und schon kann die erste Nachhilfestunde bei dir vor Ort oder online beginnen!',
    },
    refugeeTutors: {
      titleWithCity: '%{count} private Deutschlehrer für Geflüchtete in %{city}',
      titleOnline: '%{count} private Online-Deutschlehrer für Geflüchtete',
      paragraph1:
        '1) Deutschlehrer anfragen (max. 3 pro Tag)\n' +
        '2) Deutschlehrer nimmt die Anfrage an\n' +
        '3) Kontaktdaten werden beidseitig ausgetauscht\n' +
        '4) Ihr könnt euch treffen oder zusammen online lernen',
    },
    nearbyPlaces: {
      title: 'Nachhilfe in %{place}',
    },
  },
  infoVideoBox: {
    onlineTutoringPromotion: {
      headline:
        'Kein Nachhilfeangebot, das dir gefällt?\nLerne mehr über die Möglichkeiten unserer Online-Nachilfe!',
      buttonText: 'Jetzt den passenden Online-Lehrer für dein Kind finden!',
    },
    onlineTutorSearchSectionPromotion: {
      headline: 'Online-Nachhilfe für Schüler ab der 4. Klasse bis zum Abitur.',
      subheadline:
        'Im virtuellen Klassenzimmer wird auf motivierende und regelmäßige Art an den Noten gearbeitet.',
      buttonText: 'Gratis Probestunde',
    },
    onlineTutoringPromotionSchoolDetailsPage: {
      headline: 'Keine Nachhilfeschule, die dir gefällt?',
      subheadline: 'Lerne mehr über die Möglichkeiten unserer Online-Nachilfe!',
      buttonText: 'Jetzt den passenden Online-Lehrer für dein Kind finden!',
      infoText:
        'Alle Online-Lehrer durchlaufen ein sehr strenges Überprüfungsverfahren und nur ein winziger Bruchteil derer, die sich bewerben, schaffen es bei uns online zu unterrichten.\n' +
        'Die Bereitstellung eines hervorragenden Unterrichts steht im Vordergrund unserer Arbeit im Bereich der Online-Nachhilfe. In unserem 1:1 Online-Unterricht wird dein Kind nur von den besten Tutoren in ganz Deutschland unterrichtet. Nach den speziellen Bedürfnissen, wobei jederzeit der Tutor kostenlos gewechselt werden kann.',
    },
    onlineTutoringPromotionDetailsPage: {
      headline: 'Kein Tutor, der dir gefällt?',
      subheadline: 'Lerne mehr über die Möglichkeiten unserer Online-Nachilfe!',
      buttonText: 'Jetzt den passenden Online-Lehrer für dein Kind finden!',
      infoText:
        'Alle Online-Lehrer durchlaufen ein sehr strenges Überprüfungsverfahren und nur ein winziger Bruchteil derer, die sich bewerben, schaffen es bei uns online zu unterrichten.\n' +
        'Die Bereitstellung eines hervorragenden Unterrichts steht im Vordergrund unserer Arbeit im Bereich der Online-Nachhilfe. In unserem 1:1 Online-Unterricht wird dein Kind nur von den besten Tutoren in ganz Deutschland unterrichtet. Nach den speziellen Bedürfnissen, wobei jederzeit der Tutor kostenlos gewechselt werden kann.',
    },
  },
  applyAsEliteTutor: {
    headerText: 'Als Premium-Onlinetutor bewerben',
    headline: 'In welchem Fach willst du online unterrichten?',
    infoText:
      'Für die jeweiligen Fächer wird dir nach diesem Berwerbungsformular ein Link zu einer Prüfung zugesendet. Bestehst du diese Prüfung, hast du es fast geschafft und kannst bei uns als Premium-Tutor unterrichten!',
    requirements: {
      headline: 'Bestätige folgende Vorraussetzungen',
      infoText:
        'Eine Bedingung für TutorSpace Premium ist es, mehr als sechs Stunden in der Woche Nachhilfe geben zu können und über 18 Jahre alt zu sein.',
      requirementSelection: {
        moreThanSixHours: 'Ich habe pro Woche mehr als 6 Stunden Zeit für Nachhilfeeinheiten.',
        olderThan18: 'Ich bin über 18 Jahre alt.',
        elitetutorTerms:
          'Ich habe die <a href="https://drive.google.com/file/d/1ECUw9WQ_fxQXJIff8vPr8qJN0-9tWsmn/view?usp=sharing" target="_blank">Vereinbarung für Lehrkräfte</a> gelesen und bin damit einverstanden.',
      },
    },
    guide: {
      heading: 'Die Vorteile unseres Premium-Tutorenangebots.',
      example1: {
        heading: 'Bezahlung bei gratis Probestunden.',
        paragraph:
          'Wir betreiben Werbung und versorgen dich stets mit Kunden. Wir bieten diesen gratis Probestunden und zahlen dich dafür ganz regulär aus.',
      },
      example2: {
        heading: 'Innovative Unterrichtsmöglichkeit.',
        paragraph:
          'Zahlreiche hilfreiche Features in unserem virtuellen Klassenraum (Whiteboard, Screensharing, Aufnahmefunktion etc.) helfen dir.',
      },
      example3: {
        heading: 'Wir kümmern uns um alles.',
        paragraph:
          'In den Suchergebnissen wirst du hervorgehoben. Wir übernehmen Fragen, Probleme, Wünsche deiner Kunden und wickeln Buchung, Zahlung etc. über unsere Plattform ab.',
      },
    },
    button: {
      apply: 'Als Premium-Tutor bewerben',
      return: 'Zu den Tests',
    },
    success: {
      title: 'Super!',
      infoText:
        'Den Link zum Aufnahmetest in deinen ausgewählten Fächern haben wir dir zugesendet.\n Hinweis: Keine Mail erhalten? Ein Blick in den SPAM-Ordner kann sich lohnen!',
      specificInfoText: 'Du hast bereits %{subjectName} bestanden.',
    },
    retry: {
      title: 'Leider hast du einen Test nicht bestanden!',
      infoText: 'Du hast alle 3 Monate eine neue Chance einen Test zu bestehen.',
      specificInfoText:
        'Einen erneuten Versuch in %{subjectName} kannst du ab dem %{date} vornehmen.',
    },
    declined: {
      title: 'Bewerbung nicht mehr möglich',
      infoText:
        'Im Aufnahmeprozess haben wir uns leider für einen anderen Bewerber entschieden.\n Schon kleine Faktoren können im Bewerbungsverfahren ausschlaggebend sein für unsere Entscheidung.\n Wir wünschen die aber auch weiterhin viel Erfolg mit der gratis Vermittlung über TutorSpace.',
    },
    empty: {
      title: 'Komisch!',
      infoText:
        'Zu den Fächern in deiner Bewerbung gibt es keinen Test.\nBitte versuche es erneut. Falls diese Meldung weiterhin erscheint, wende dich bitte unter support@tutorspace.de an den Support.',
    },
  },
  timeSlotPicker: {
    selectionError: 'Bitte immer zwei zusammenhängende Timeslots wählen!',
    updateAvailabilitySuccess: 'Deine Verfügbarkeiten wurden erfolgreich hinterlegt.',
    button: {
      save: 'Woche speichern',
      disableStartRestriction: 'Toggle Admin-Mode',
    },
    vacancyReminder: {
      title: 'Achtung, du hast wenige Verfügbarkeiten eingetragen.',
      info1:
        'Bitte trage pro Woche mind. {{recommendedHours}} Stunden Verfügbarkeiten ein (Buchungen + freie Zeiten). Wir empfehlen immer die Angaben für die nächsten 2 Wochen einzutragen.',
    },
  },
  footer: {
    getTheAppStrip: {
      heading: 'Hol dir die App!',
      paragraph:
        'Lade dir die TutorSpace-App herunter und finde ganz bequem motivierte Nachhilfelehrer oder gib selbst Nachhilfe online oder in deiner Umgebung.',
    },
    links: {
      imprint: 'Impressum',
      termsOfUse: 'Nutzungsbedingungen',
      dataProtection: 'Datenschutz',
    },
    subjects: {
      title: 'Schulfächer',
      onlineTitle: 'Online-Nachhilfe',
    },
    forTutors: {
      title: 'Für Nachhilfelehrer',
      becomeATutor: 'Werde Nachhilfelehrer',
    },
    forStudents: {
      title: 'Für Nachhilfeschüler',
      teachersNearYou: 'Lehrer in deiner Nähe',
    },
    localTutoring: {
      title: 'Vergleichsplattform',
      subItems: {
        becomeATutor: 'Werde Nachhilfelehrer',
        compareFree: 'Kostenlos Nachhilfe vergleichen',
        howItWorks: 'So funktionierts',
      },
    },
    onlineTutoring: {
      title: 'TutorSpace Premium',
      extra: 'BELIEBT',
      subItems: {
        bookAFreeTrialLesson: 'Gratis Probestunde buchen',
        becomeAnEliteTutor: 'Werde Premium-Tutor',
        onlineTutoring: 'Online-Nachhilfe',
      },
    },
    aboutTutorSpace: {
      title: 'Erfahre mehr',
      subItems: {
        jobs: 'Jobs & Karriere',
        aboutUs: 'Über Uns',
        faq: 'FAQ & Hilfe',
        donationInitiative: 'Spendeninitative',
        forTutoringInstitutes: 'Für Nachhilfeschulen',
        blog: 'Blog',
        contact: 'Kontakt',
        press: 'Presse',
        alena: 'Abi-Trainerin Alena',
        founder: 'Unser Gründer',
      },
    },
    supportAndHelp: {
      title: 'Support und Hilfe',
      subItems: {
        youAskWeAnswer: 'Du fragst - Wir antworten',
        contact: 'Kontakt',
        blog: 'Blog',
        faq: 'Du benötigst Hilfe?',
      },
    },
    rewards: {
      bestTututors: {
        title: 'Deutschlands beste Tutoren unterrichten bei uns online',
      },
    },
    citys: {
      title: 'Städte',
      cityList: {
        0: 'Berlin',
        1: 'Hamburg',
        2: 'München',
        3: 'Köln',
        4: 'Frankfurt am Main',
        5: 'Stuttgart',
        6: 'Düsseldorf',
      },
    },
  },
  header: {
    title: 'TutorSpace',
    menuItems: {
      search: {
        title: 'Suchen',
        subMenu: {
          comparison: {
            description: 'Kostenlos private Nachhilfe finden',
            title: 'Vergleichsplattform',
          },
          premium: {
            description: '1:1 Unterricht von hochqualifizierten Tutoren',
            title: 'TutorSpace Premium',
          },
        },
      },
      offer: {
        title: 'Anbieten',
        subMenu: {
          private: {
            description: 'Du bist auf der Vergleichsplattform zu sehen',
            title: 'Private Nachhilfe geben',
          },
          premium: {
            description: 'Bequem von zu Hause aus unterrichten',
            title: 'Nachhilfe bei TutorSpace Premium geben',
          },
        },
      },
      aboutUs: {
        title: 'Erfahre mehr',
        subMenu: {
          aboutUs: {
            title: 'Über uns',
          },
          jobs: {
            title: 'Jobs & Karriere',
          },
          donationInitiative: {
            title: 'Spendeninitiative',
          },
          faq: {
            title: 'Hilfe & FAQ',
          },
          press: {
            title: 'Presse',
          },
        },
      },
    },
  },
  premiumHeader: {
    title: 'TutorSpace Premium',
    menuItems: {
      teacher: 'Unsere Lehrkräfte',
      pricing: 'Preise',
      rating: 'Bewertungen',
    },
  },
  errors: {
    unknownError: 'Unbekannter Fehler',
    forbiddenError: 'Unzulässige Anfrage',
    internalServerError: 'Der Server ist gerade nicht erreichbar. Versuche es später erneut.',
    incorrectCredentials: 'E-Mail oder Password falsch',
    communicationError: 'Der Server ist gerade nicht erreichbar. Versuche es später erneut.',
    error: 'Fehler',
    someOfValuesAreIncorrect: 'Bitte überprüfe deine Angaben.',
    unableToAuthenticateWithFacebook: 'Verbindung zu Facebook fehlgeschlagen.',
    tokenExpired:
      'Dein Link, um das Passwort zurückzusetzen, ist abgelaufen. Bitte fordere einen neuen Link an.',
    incorrectEmail: 'Ungültige E-Mail',
    offerAlreadyReported: 'Du hast das Angebot schon gemeldet.',
    invalidVatNumberForSelectedCountry: 'Die USTID ist nicht gültig für das ausgewählte Land.',
    contactSupport: 'Es ist ein Fehler aufgetreten. Bitte wende dich an unseren Support',
    missingValues: 'Bitte wähle ein Fach aus.',
    missingCity: 'Bitte wähle einen Ort aus.',
    noBBBJoinURL: 'Du kannst diesen Raum erst am {{date}} um {{time}} betreten.',
    noBBBJoinURLClosed: 'Dieser Raum ist geschlossen.',
    noBBBJoinPermission: 'Du darfst diesen Raum nicht betreten.',
    BBBRoomNotFound: 'Diesen Raum gibt es nicht.',
    eliteratingError: 'Es gab ein Problem beim Laden von den Bewertungen.',
  },
  buttons: {
    login: 'Einloggen',
    forgotPassword: 'Passwort vergessen?',
    myMessages: 'Meine Nachrichten',
    myOffer: 'Mein Nachhilfeangebot',
    myProfile: 'Mein Profil',
    instituteProfile: 'Kontoeinstellungen',
    instituteOffers: 'Meine Nachhilfeschulen',
    logout: 'Ausloggen',
    register: 'Registrieren',
    ok: 'OK',
    next: 'Weiter',
    becomeTutor: 'Werde ein Nachhilfelehrer',
    save: 'Speichern',
    delete: 'Löschen',
    continueWithFacebook: 'Weiter mit Facebook',
    continueWithThisFacebookAccount: 'Weiter mit diesem Facebook-Account',
    continumeWithApple: 'Weiter mit Apple',
    otherFormOfRegisteration: 'Auf andere Weise registrieren',
    giveLesson: 'Nachhilfe geben',
    search: 'Suchen',
    requestLessons: 'Unterricht anfragen',
    editOffer: 'Nachhilfeangebot bearbeiten',
    offerPdf: 'PDF',
    share: 'Teilen',
    rejectOfferRequest: 'Absage abschicken',
    acceptOfferRequest: 'Kontaktdaten senden',
    acceptOfferRequestSchool: 'Jetzt Kontaktdaten ansehen',
    showContactDetails: 'Kontaktdaten anzeigen',
    keepLooking: 'Weiter suchen',
    activate: 'Veröffentlichen',
    deactivate: 'Pausieren',
    update: 'Aktualisieren',
    edit: 'Bearbeiten',
    abort: 'Abbrechen',
    sendRequest: 'Jetzt direkt Anfrage senden',
    visitWebsite: 'Website besuchen',
    verify: 'Verifizieren',
    trialEliteSeeker: 'Jetzt kostenlose Probestunde ausmachen',
    trialEliteSeekerShort: 'Gratis Probestunde',
    bookALesson: 'Stunde buchen',
    confirm: 'Bestätigen',
  },
  validator: {
    form: {
      isRequired: 'Wird benötigt',
      generalFormatIsNotValid: 'Bitte überprüfe diesen Eintrag',
      symbolsNotValid: 'Der Eintrag enthält ungültige Zeichen',
      isDefaultPhotoRequired: 'Wähle ein Titelbild',
      emailFormatIsNotValid: 'Überprüfe deine E-Mail',
      commentFormatIsNotValid: 'Bitte verwende keine Links in deiner Bewertung',
      passwordFormatIsNotValid: 'Überprüfe dein Passwort',
      passwordsMustMatch: 'Die Passwörter müssen übereinstimmen',
      inputTooShort: 'Der Text muss mindestens {{minLength}} Zeichen lang sein',
      inputTooLong: 'Der Text darf maximal 1600 Zeichen lang sein',
      passwordTooShort: 'Das Password muss mindestens 8 Zeichen lang sein',
      phoneNumberIsNotValid: 'Telefonnummer ist ungültig',
      phoneValidationCodeIsNotValid: 'Bestätigungscode ungültig',
      fileSizeCannotBeMoreThan: 'Die Datei darf nicht größer sein als {{maxFileSize}}.',
      valueMustBeSmallerThan: 'Der Wert muss kleiner als {{maxValue}} sein',
      valueMustBeHigherThan: 'Der Wert muss höher als {{minValue}} sein',
      openingHoursOverlaps: 'Die Öffnungszeiten sind gleich',
      textIsTooShort: 'Dieses Feld muss mindestens {{minLength}} Zeichen lang sein',
      textMustHaveAtLeastOneLetter: 'Der Text muss mindestens einen Buchstaben enthalten',
      textMustHaveAtLeastOneNumber: 'Der Text muss mindestens eine Nummer haben',
    },
  },
  messages: {
    or: 'oder',
    accessTokenSuccessfullyRefreshed: 'Zugangstoken wurde erfolgreich aktualisiert',
    notFound: 'Nicht gefunden',
    offerNotFound: 'Angebot nicht gefunden',
  },
  confirmation: {
    welcomeBackToTutorSpace: 'Willkommen zurück bei TutorSpace!',
    welcomeToTheTutorSpaceCommunity: 'Willkommen in der TutorSpace-Community :-)',
    youHaveBeenSuccessfullyLoggedOut: 'Du wurdest erfolgreich abgemeldet.',
    yourPhoneNumberVerifiedSuccessfully: 'Deine Telefonnummer wurde erfolgreich bestätigt.',
    yourRequestForThisOfferWasSendSuccessfully:
      'Deine Anfrage wurde erfolgreich übertragen. Der Lehrer wird in Kürze darauf reagieren.',
    weHaveSentYouConfirmationEmail: 'Deine E-Mail wurde erfolgreich aktualisiert.',
    userNameWasSuccessfullyUpdated: 'Benutzername wurde erfolgreich aktualisiert.',
    userFullBirthdayWasSuccessfullyUpdated: 'Geburtstag wurde erfolgreich aktualisiert.',
    youHaveRejectedRequestOfferSuccessfully:
      'Die Absage wurde erfolgreich an den Schüler übertragen.',
    youHaveAcceptedRequestOfferSuccessfully:
      'Gratulation! Deine Kontaktdaten wurden erfolgreich an den Lernenden übertragen. Er wird sich in den nächsten Tagen bei dir melden.',
    weHaveSentYouEmailToResetPassword:
      'Wir haben dir eine Email zum Zurücksetzen des Passwortes gesendet.',
    yourPasswordHasBeenSuccessfullyUpdated: 'Dein Passwort wurde erfolgreich aktualisiert.',
    yourOfferWasSuccessfullyPublished: 'Super! Dein Nachhilfeangebot kann jetzt gefunden werden.',
    yourOfferWasSuccessfullyUpdated: 'Du hast dein Angebot erfolgreich aktualisiert.',
    yourUserTypeWasSuccessfullyUpdated: 'Die Vermittlung kann jetzt beginnen!',
    youHaveSuccessfullyReportedAnOffer: 'Das Nachhilfeangebot wurde erfolgreich gemeldet.',
    youHaveSuccessfullyRatedAnOffer: 'Das Nachhilfeangebot wurde erfolgreich bewertet.',
    offerWasDeletedSuccessfully: 'Das Angebot wurde entfernt',
    userVideoWasDeletedSuccessfully: 'Das Video wurde entfernt',
  },
  fields: {
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordConfirmation: 'Gib dein Passwort erneut ein',
    newPassword: 'Neues Passwort',
    newPasswordConfirmation: 'Neues Passwort bestätigen',
    phoneVerificationCode: 'Code',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    emailPlaceholder: 'Gebe deine E-Mail ein',
    cancel: 'Zurück',
    accept: 'Auswählen',
  },
  search: {
    title: 'Über 11.000 private Nachhilfelehrer im direkten Vergleich',
    title1: 'Über 11.000 private',
    title2: 'Nachhilfelehrer im direkten Vergleich',
    manImage: {
      title: 'Luisa, Tutorin in Mathematik und Englisch seit 2018.',
    },
    promotion: {
      offer: 'Nachhilfe geben:',
    },
    tutoringKindTabsShort: {
      [TutoringKind.onSiteTutoring]: 'Vor Ort',
      [TutoringKind.onlineTutoring]: 'Online',
      [TutoringKind.tutoringSchools]: 'Institut',
    },
    tutoringKindTabs: {
      [TutoringKind.onSiteTutoring]: 'Nachhilfe vor Ort',
      [TutoringKind.onlineTutoring]: 'Online-Nachhilfe',
      [TutoringKind.tutoringSchools]: 'Nachhilfeschulen',
    },
    fields: {
      inWhichSubjectDoYouNeedHelp: 'In welchem Fach wird Hilfe benötigt?',
      inWhichSubjectDoYouNeedHelpShort: 'Fach',
      where: 'Wo: Ort, Stadt oder PLZ',
      requestTeachingLevel: 'Klassenstufe wählen',
      collapseText: 'Nachhilfeangebote filtern',
    },
    popular: 'Beliebt:',
    sortDropdown: {
      sortWith: 'Sortieren nach',
      hint: 'Ergebnisse innerhalb von 12 km Umkreis.',
      options: {
        [SortOffersBy.Default]: 'Standardsortierung',
        [SortOffersBy.Rating]: 'Bewertung (höchste zuerst)',
        [SortOffersBy.Distance]: 'Entfernung (niedrigste zuerst)',
        [SortOffersBy.Newest]: 'Veröffentlichung (neueste zuerst)',
        [SortOffersBy.HighestPrice]: 'Preis (höchste zuerst)',
        [SortOffersBy.LowestPrice]: 'Preis (niedrigste zuerst)',
      },
    },
  },
  refugeeInitiativeSearch: {
    title: 'Finde einen ehrenamtlichen Deutschlehrer',
    subHeadline:
      'Auf unserer Vermittlungsplattform verbinden wir ehrenamtliche Deutschlehrer mit ukrainischen Geflüchteten, die gezwungen waren, ihre Heimat zu verlassen.',
    tutoringKindTabsShort: {
      [TutoringKind.onSiteTutoring]: 'Vor Ort',
      [TutoringKind.onlineTutoring]: 'Online',
    },
    tutoringKindTabs: {
      [TutoringKind.onSiteTutoring]: 'Deutschlehrer vor Ort',
      [TutoringKind.onlineTutoring]: 'Deutschlehrer online',
    },
    fields: {
      where: 'Wo: Ort, Stadt oder PLZ',
    },
    searchButton: 'Suchen',
  },
  tutorSpacePlusSearch: {
    title:
      'Hochqualifizierte Online-Lehrkräfte, damit\ndein Kind sein volles Potential entfalten kann.',
    infoBox: {
      headline: 'Unser Erfolgskonzept:',
      bulletPoints: [
        'Freude am Lernen, weil die Chemie \nzwischen Lehrer und Schüler stimmt',
        'Lernen im 1:1 Unterricht mit \nlerntyporientiertem Lernplan',
        'Interaktiver Unterricht in unserem \nmodernen Online-Klassenzimmer',
      ],
    },
    button: 'Gratis Probestunde buchen',
  },
  tutorSpacePlusSearchV2: {
    title:
      'Hochqualifizierte Online-Nachhilfe, damit\ndein Kind sein volles Potential entfalten kann.',
    infoBox: {
      headline: 'Unsere Online-Nachhilfe:',
      bulletPoints: [
        'Freude am Lernen, weil die Chemie \nzwischen Online-Tutor und Schüler stimmt',
        'Lernen im 1:1 Unterricht mit \nlerntyporientiertem Lernplan',
        'Interaktiver Unterricht in unserem \nvirtuellen Klassenzimmer',
      ],
    },
    button: 'Gratis Probestunde buchen',
  },
  registerAsElitetutor: {
    seoTitle: 'TutorSpace - Neue Talente werden gesucht!',
    seoDesc: 'Werde ein Teil von TutorSpace und unterstütze Schüler.',
    yes: 'Ja',
    no: 'Nein',
    toNextQuestion: 'Zur nächsten Frage',
    next: 'Weiter',
    generalInfo: {
      becomeAPartOfTutorspace: 'Werde ein Teil von TutorSpace Premium',
      doYouWantToEarn: 'Möchtest du Schüler unterrichten und dir dabei etwas dazuverdienen?',
      aboveAveragePayment: '→ überdurchschnittlicher Stundensatz',
      discoverOur: 'Entdecke unsere',
      onlineSchool: 'Online-Schule',
      weOfferMore: 'Wir bieten unseren Tutoren mehr als nur Nachhilfestunden',
      forYourLessons: 'Für deinen Unterricht',
      useDigitalClassroom:
        'Nutze das digitale Klassenzimmer mit Bildschirmübertragung und integriertem Whiteboard.',
      forYourTutoring: 'Für deine Nachhilfe',
      weServeYouWithStudents:
        'betreiben wir aktiv Werbung und versorgen sich stets mit lernwilligen Schülern.',
      forYourFlexibility: 'Für deine Flexibilität',
      decideForYourself: 'Entscheide selbst wann und wie viel du unterrichten möchtest.',
      forYourEarnings: 'Für deine Verdienste',
      weOfferSecurePayments: 'bieten wir Buchung und sichere Zahlung über unsere Plattform.',
      forYourStudents: 'Für deine Schüler',
      weSupportYou: 'vertreten wir dich bei allen Fragen, Problemen und Wünschen.',
    },
    subjectSelection: {
      title: 'In welchen Fächern kannst du bis zum Abitur unterrichten?',
      multipleChoiceIsPossible: '(Mehrfach Auswahl möglich)',
      questionXOfY: 'Frage {{index}} von {{totalQuestions}}',
    },
    excitingBrand: {
      title: 'Möchtest du für eine spannende Marke unterrichten?',
    },
    multipleMonths: {
      title: 'Kannst du dir vorstellen Schüler über mehrere Monate zu unterrichten?',
    },
    germanLevel: {
      title: 'Wie gut ist dein Deutsch?',
      nativeSpeaker: 'Muttersprachler',
      c2: 'C2',
      c1: 'C1',
      b2: 'B2',
      other: 'A1-A2-B1',
    },
    germanLevelTooLow: {
      description:
        'Tut uns Leid - um bei uns zu unterrichten, musst du leider mindestens B2 Niveau haben.',
    },
    contactDetails: {
      youMadeIt: 'Du hast es geschafft!',
      weWantToGetToKnowYou:
        'Wenn du über 18 bist und ein Abitur hast, wollen wir dich näher kennenlernen.',
      title: 'Eintragen und bis zu 17\xA0€ pro Unterrichtseinheit verdienen:',
      firstName: 'Dein Vorname',
      lastName: 'Dein Nachname',
      email: 'Deine E-Mail',
      phoneNumber: 'Deine Nummer',
      zipCode: 'Postleitzahl',
      sendNow: 'Jetzt absenden!',
      secure: '🔒 Sichere Verbindung.',
      iAgree:
        'Wenn ich fortfahre, erlaube ich die Kontaktaufnahme zu Jobmöglichkeiten für den Nachhilfeunterricht und erkläre mich mit den <a href="{{url}}" target="_blank" rel="noreferrer">Nutzungsbedingungen</a> der TutorSpace GmbH einverstanden.',
    },
    teachingLevel: {
      description:
        'Jetzt geht es um dein Nachhilfeangebot! Beantworte folgende Fragen und bist startklar.',
      title: 'Bis zu welchem Niveau unterrichtest du?',
      fourthGrade: '4. Klasse',
      seventhGrade: '7. Klasse',
      tenthGrade: '10. Klasse',
      abitur: 'Abitur',
      university: 'Universität / Studium',
    },
    tutoringType: {
      title: 'Wo kann die Nachhilfe stattfinden?',
      atYourHome: 'Bei dir zu Hause',
      atTheStudentsHome: 'Beim Schüler zu Hause',
      online: 'Online',
    },
    price: {
      title: 'Lege einen Preis für deine Nachhilfe fest',
      description: 'pro 45 Minuten und ohne Centbeträge',
      placeholder: 'z. B. 14, 18 oder 20',
    },
    introduceYourself: {
      title: 'Wie würdest du dich Eltern vorstellen?',
      description: '(Du kannst den Text später noch anpassen)',
      placeholder: 'Schreib eine kurze Zusammenfassung über dich und deine Nachhilfe.',
    },
    address: {
      title: 'Was sind deine Adressdaten?',
      description:
        'Diese Info ist für niemanden sichtbar. Als staatlich anerkannte Bildungseinrichtung müssen wir sie aber abfragen.',
      placeholder: 'Deine Adresse',
    },
    photo: {
      title: 'Füge deinem Nachhilfeangebot ein Foto hinzu',
      description:
        'Eltern wollen sehen, wer das Kind unterrichten soll. Ein seriöses und sympathisches Foto sorgt für mehr Vertrauen.',
      upload: 'Hier klicken und Foto hochladen',
      uploadDescription: '(max. 5 MB, .pdf, .jpg, .png)',
      submit: 'Datei absenden',
    },
    birthday: {
      lastStep: 'Letzter Schritt!',
      title: 'Wann ist dein Geburtstag?',
      description:
        'Diese Info ist für niemanden sichtbar. Als staatlich anerkannte Bildungseinrichtung müssen wir sie aber abfragen.',
      placeholder: 'Dein Geburtstag',
    },
    done: {
      youMadeIt:
        'Geschafft! Damit du direkt loslegen kannst, haben wir schon ein Profil für dich angelegt und <b>du erhältst gleich 3\xA0E\u2011Mails von uns</b> 🎉',
      first: 'Bestätige deine E-Mail',
      second: 'Ändere das Passwort',
      third: 'Starte direkt mit dem ersten Bewerbungsschritt',
      pathToFirstStudent: {
        title: 'Der Weg zum ersten Schüler:',
        first: {
          title: 'Online-Test ablegen (20 Minuten)',
          description: 'Zeige, dass du deine Fächer beherrschst.',
        },
        second: {
          title: 'Unterrichtssimulation (20 Minuten)',
          description: 'Zeige, dass du auch schwierige Situationen gut meisterst.',
        },
        third: {
          title: 'Onboarding und der erste Schüler',
          description: 'Nach einem kurzen Onboarding bist du auch schon für Schüler buchbar.',
        },
      },
    },
  },
  becomePremiumTutor: {
    title: 'Online-Nachhilfe geben, Geld verdienen und\nJugendliche nachhaltig fördern.',
    infoBox: {
      headline: 'Warum TutorSpace Premium?',
      bulletPoints: [
        'Überdurchschnittlicher Verdienst',
        'Wir organisieren, du unterrichtest.',
        'Bestbewertete Online-Schule in DACH*',
      ],
    },
    button: 'Jetzt bewerben',
  },
  advantagesPremiumTutor: {
    heading: 'Was dir TutorSpace Premium bietet',
    description:
      'Bei uns bist du dein eigener Boss. Du bestimmst\nwie der Unterricht ablaufen soll.',
    contents: {
      content1: {
        title: 'Absolute Flexibilität',
        description: 'Unterrichte wann\nund wo es dir passt.',
      },
      content2: {
        title: 'Hoher Verdienst',
        description: 'Wir zahlen über den\nmarktüblichen Konditionen.',
      },
      content3: {
        title: 'Ohne Aufwand',
        description: 'Wir vermitteln dir Schülerinnen\nund Schüler und organisieren alles.',
      },
      content4: {
        title: 'Schnelle Bewerbung',
        description: 'Du kannst diese Woche schon\nunterrichten, wenn du willst!',
      },
      content5: {
        title: 'Ausgeklügelte Technik',
        description:
          'Du musst nichts installieren. Einfach einloggen und in\nunserem virtuellen Klassenzimmer loslegen.',
      },
      content6: {
        title: 'Ansprechpartner',
        description: 'Dein persönlicher Ansprechpartner\nist immer für dich da.',
      },
      content7: {
        title: 'Wachse mit uns',
        description: 'Trainiere deine pädagogischen Kompetenzen\nund optimiere deinen Lebenslauf.',
      },
      content8: {
        title: 'Gutes tun',
        description: 'Fördere nachhaltig den Lernerfolg\nund die Zukunftschancen von Jugendlichen.',
      },
      content9: {
        title: 'Alles für die Tutoren',
        description: 'Das Team ist immer für dich da, um\ndein Feedback umzusetzen.',
      },
    },
  },
  premiumTutorRequirements: {
    tileWeWant: {
      heading: 'Das brauchen wir von dir',
      bulletpoint1: 'Du unterrichtest leidenschaftlich und empathisch.',
      bulletpoint2: 'Du bist volljährig und hast das (Fach-)Abitur.',
      bulletpoint3: 'Du hast einen Laptop, stabiles Internet, Kamera und ein Mikrofon.',
    },
    tileJoinUs: {
      heading: 'Schließ dich uns an',
      bulletpoints: [
        'Zeig uns bei einem kurzen fachlichen Test, dass du es drauf hast.',
        'Lerne uns in einem kurzen Gespräch kennen und schau, ob die Chemie stimmt.',
        'Unterzeichne deinen Vertrag und lege los mit deiner ersten Unterrichtseinheit.',
      ],
    },
  },
  premiumTutorReviews: {
    heading: 'Überzeuge dich selbst.\nWas unsere Lehrkräfte über uns sagen:',
  },
  premiumTutorPay: {
    heading: 'So viel Potential steckt in\ndeinem neuen Job',
    description: 'Wir wissen, dass guter Unterricht auch gut bezahlt werden muss.',
    earnings: {
      from: 'von',
      to: 'bis',
      perMonth: 'p.M.',
      amountLower: '680€',
      hoursPerWeekLower: 'mit nur 10 Wochenstunden',
      amountUpper: '1340€',
      hoursPerWeekUpper: 'bei ca. 20 Wochenstunden',
      arrowUpper: '»',
    },
  },
  premiumTutorFaq: {
    heading: 'Häufig gestellte Fragen',
    subheading: 'Du fragst – Wir antworten.',
    contact: {
      name: 'Caroline',
      description: 'Rekrutierung von Lehrkräften',
    },
    question1: 'Wie lange dauert die Bewerbung?',
    answer1:
      'In der Regel dauert es nur wenige Tage, bis du anfangen kannst, bei uns zu unterrichten. Sobald du den fachlichen Test für dein Wunsch-Fach bestanden hast, kannst du dich bei uns für ein Kennenlerngespräch eintragen. Wenn wir uns füreinander entschieden haben, senden wir dir direkt deinen Vertrag. Nach einer kurzen persönlichen Schulung, in der wir dir alles zeigen, kannst du auch schon deine erste Schülerin oder deinen ersten Schüler übernehmen und loslegen. Der erste Schritt: Klicke auf "Jetzt bewerben".',
    question2: 'Was ist der fachliche Test?',
    answer2:
      'Der fachliche Test ist unser Weg, um sicherzustellen, dass du kompetent in deinem Unterrichtsfach bist. Das Online-Quiz dauert ca. 15-20 Minuten und fragt deine Kenntnisse bis zum Abitur ab. Du gelangst automatisch zum Test, wenn du auf "Jetzt bewerben" klickst. Nimm dir eine ruhige Minute, konzentriere dich und zeig uns, dass du es drauf hast!',
    question3: 'Welche Fächer kann ich unterrichten?',
    answer3:
      'Du kannst bei uns alle gängigigen Schulfächer unterrichten: Mathe, Deutsch, Englisch, Physik oder Chemie, Geschichte, Biologie oder Erdkunde sowie die Fremdsprachen Französisch, Italienisch, Spanisch und Latein. Weil wir individuell fördern, brauchen wir auch mal wieder spezielle Fächer: Willst du also exotische Fächer wie z.B. Niederländisch unterrichten, sprich uns individuell an!',
    question4: 'Wie viel werde ich verdienen?',
    answer4:
      'Wir bezahlen dich überdurchschnittlich mit bis zu 17€ pro Stunde. Je länger du bei uns unterrichtest, desto mehr kannst du verdienen.',
    question5: 'Was unterscheidet TutorSpace Premium von anderen Anbietern?',
    answer5:
      'Wir setzen auf Qualität und bieten dir eine moderne, flexible und freundliche Arbeitsumgebung. Unsere Verträge sind lehrkraftorientiert, wir haben immer ein offenes Ohr für deine Anliegen und bezahlen im Vergleich überdurchschnittlich. Du hast immer einen persönlichen Ansprechpartner und es gilt: Wir sind immer für dich da!',
  },
  donationInitiative: {
    donationHeader: {
      title: 'Gemeinsam Bildung ermöglichen',
      description:
        'Weil Bildung für viele Menschen nicht selbstverständlich ist. Weil wir jedem Schüler, unabhängig von Herkunft und Familie, ermöglichen wollen, sein volles Potential zu entfalten.',
    },
    banner: {
      text: 'Mit jeder Stunde fördern wir Unterricht in Tansania',
    },
    tiles: {
      tile1: {
        title: 'Mit deiner Nachhilfe \nGutes tun.',
        description:
          'Wir sind überzeugt, dass Bildung der Grundstein für ein besseres Leben ist. Weltweit haben jedoch sehr viele Kinder noch keinen Zugang zu Bildung. Mit deiner Hilfe möchten wir einen Beitrag leisten, um dies zu ändern: Deine Nachhilfestunde fördert nicht nur dein Kind. \nMit jeder Nachhilfestunde bei TutorSpace Premium fördern wir Unterricht in Tansania.',
      },
      tile2: {
        title: 'Unser Herzensprojekt:\nStipendien für Waisenkinder in Tansania.',
        description:
          'Durch die Nachhilfe ermöglichst du einem bedürftigen Waisenkind in Tansania eine Schulausbildung. Das Stipendium umfasst neben den Schulgebühren auch Verpflegung, Unterbringung, Schulmaterialien sowie ein kleines Taschengeld.',
      },
    },
    donationPartner: {
      title: 'Unser Partner:\nPROBONO Schulpartnerschaften e.V.',
      description:
        'Die gemeinnützige Organisation unterstützt seit 2004 Schulbildung im östlichen Afrika. PROBONO engagiert sich für die nachhaltige Armutsbekämpfung durch die Förderung von Bildung, die Verbesserung der Lern- und Lebensbedingungen an afrikanischen Schulen sowie den interkulturellen Dialog und Austausch zwischen Kindern und Jugendlichen aus Deutschland und Ostafrika.',
    },
  },
  requestTutoringFlow: {
    privateRequest: 'Was muss {{firstName}} noch wissen?',
    inWhichSubjectDoYouNeedAHelp: 'In welchem Fach wird Hilfe benötigt?',
    whatSubjectPlaceholder: 'z.B. Mathe',
    whatLocation: 'Wo soll die Nachhilfe stattfinden?',
    whatLevel: 'In welcher Klasse ist dein Kind oder auf welchem Niveau soll unterstützt werden?',
    whatLevelPlaceholder: 'z.B. 2. Klasse',
    howManyHours: 'Wie viele Stunden pro Monat ist geplant für Nachhilfeunterricht?',
    howManyHoursPlaceholder: 'z.B. 4h',
    howManyHoursDisclaimer: 'Info: Die Angabe ist vollkommen unverbindlich.',
  },
  forms: {
    login: {
      title: 'Einloggen',
    },
    forgotPassword: {
      title: 'Du hast dein Passwort vergessen?',
      messages: {
        enterEmail:
          'Bitte gib deine Email ein und wir senden dir einen Link, um dein Passwort zurückzusetzen.',
      },
      buttons: {
        submit: 'Absenden',
      },
    },
    setPassword: {
      title: 'Aktualisiere dein Passwort',
      instruction: 'Es muss aus mindestens acht Zeichen bestehen.',
    },
    registerWithEmail: {
      title: 'Kostenlos Registrieren',
      messages: {
        lastNameDescription:
          'Es wird nur der erste Buchstabe deines Nachnamens angezeigt. Wir stehen für eine vertrauenswürdige, seriöse Plattform - mit der Angabe deines Namens hilfst du uns Missbrauch zu vermeiden.',
        emailDescription:
          'Wir informieren dich per E-Mail zu deinem Nachhilfeangebot oder Neuigkeiten zu deiner Anfrage an einen Nachilfelehrer.',
        termsOfUseDescription1: 'Mit der Registrierung stimme ich den',
        termsOfUseDescription2: ' zu.',
        alreadyHaveAnAccount: 'Hast du bereits ein Nutzerkonto?',
        notHaveAnAccount: 'Du hast noch kein Nutzerkonto?',
      },
      buttons: {
        termsOfUse: 'Nutzungsbedingungen',
      },
    },
    continueWithFacebookConfirmation: {
      title: 'Willkommen zurück',
      buttons: {
        continuneWithFacebook: 'Weiter mit Facebook',
      },
    },
    registerPhone: {
      title: 'Registrierung abschließen',
      messages: {
        weWillSendYouSmsValidationCode:
          'Wir werden dir eine SMS senden, um deine Nummer zu bestätigen. Es können die üblichen Gebühren für die Nachrichten- und Datenübertragung anfallen.',
        whyWeValidatePhoneQuestion: 'Warum brauchen wir deine Handynummer?',
        whyWeValidatePhoneAnswerPart1:
          'Damit sich Schüler und Lehrer gegenseitig nach einer erfolgreichen Vermittlung kontaktieren können, benötigen wir deine Telefonnummer.',
        whyWeValidatePhoneAnswerPart2:
          'Mit TutorSpace bieten wir dir eine kostenlose Nachhilfevermittlung. Über die Handynummer sorgen wir für eine vertrauenswürdige Nachhilfeplattform und die Echtheit der Nutzer.',
      },
      buttons: {
        haveAccountAlready: 'Hast du bereits ein Nutzerkonto?',
      },
    },
    verifyPhone: {
      title: 'Bestätige deine Nummer',
      messages: {
        enterPhoneVerificationCode:
          'Gib den Code ein, der soeben an {{phoneNumber}} gesendet wurde:',
        haveYouNotReceivedSms: 'Du hast keine SMS erhalten?',
      },
      buttons: {
        sendPhoneVerificationCodeAgain: 'Erneut abschicken',
      },
    },
    selectUserType: {
      title: 'Der letzte Schritt',
      messages: {
        iam: 'Ich trete TutorSpace bei als:',
        helpUsAdopt:
          'Hilf uns TutorSpace besser auf dich anzupassen, sodass du besser zurecht findest.',
        parent: 'Elternteil',
        student: 'Student*in',
        schoolboy: 'Schüler*in',
        institution: 'Institut',
        other: 'Keines der genannten',
      },
    },
  },
  offerRequestMessages: {
    requestDetails: {
      subjects: 'Angefragte Fächer',
      hoursPerMonth: 'Geplante Stunden pro Monat',
      teachingLevel: 'Niveau des Schülers',
      teachingLocation: 'Art der Nachhilfe',
    },
    // seeker
    [OfferRequestType.SENT]: {
      [OfferRequestStatus.PENDING]: {
        label: 'Anfrage abgeschickt',
        labelSchool: '',
        title: 'Du hast {{firstName}} um Nachhilfe gebeten',
        description:
          'Er hat deine Anfrage erhalten. Wenn er noch Schüler aufnehmen kann, wirst du benachrichtigt!',
        descriptionSchool: '',
      },
      [OfferRequestStatus.ACCEPTED]: {
        label: 'Gerne kann ich dich beim lernen unterstützen!',
        labelSchool: '',
        title: 'Gerne kann ich dich beim lernen unterstützen!',
        description:
          'Wenn du auf den Button klickst, siehst du meine Kontaktdaten. Ich freue mich auf dich :-)',
        descriptionSchool: '',
      },
      [OfferRequestStatus.REJECTED]: {
        label: 'Rückmeldung vom Lehrer erhalten ...',
        labelSchool: '',
        title: 'Leider kann ich dich im Moment nicht unterstützen ...',
        description:
          'Viel Glück bei der weiteren Suche! Ich bin mir aber sicher, dass ein anderer Lehrer bei TutorSpace dir weiterhelfen kann.',
        descriptionSchool: '',
      },
    },
    // tutor
    [OfferRequestType.RECEIVED]: {
      [OfferRequestStatus.PENDING]: {
        label: 'Deine Hilfe ist gefragt',
        labelSchool: 'Anfrage für deine Schule {{schoolName}}',
        title: '{{firstName}} will bei dir Nachhilfe nehmen!',
        description:
          'Sende ihm deine Kontaktdaten zu, damit ihr euch für die Nachhilfe absprechen könnt!',
        descriptionSchool:
          'Schalte die Kontaktdaten frei, um den Vor- & Nachnamen, die verifizierte Telefonnummer und die E-Mail des Schülers zu bekommen.',
      },
      [OfferRequestStatus.ACCEPTED]: {
        label: 'Deine Hilfe ist gefragt',
        labelSchool: 'Anfrage für deine Schule {{schoolName}}',
        title: '{{firstName}} will bei dir Nachhilfe nehmen!',
        description:
          'Sende ihm deine Kontaktdaten zu, damit ihr euch für die Nachhilfe absprechen könnt!',
        descriptionSchool:
          'Schalte die Kontaktdaten frei, um den Vor- & Nachnamen, die verifizierte Telefonnummer und die E-Mail des Schülers zu bekommen.',
      },
      [OfferRequestStatus.REJECTED]: {
        label: 'Deine Hilfe ist gefragt',
        labelSchool: 'Anfrage für deine Schule {{schoolName}}',
        title: '{{firstName}} will bei dir Nachhilfe nehmen!',
        description:
          'Sende ihm deine Kontaktdaten zu, damit ihr euch für die Nachhilfe absprechen könnt!',
        descriptionSchool:
          'Schalte die Kontaktdaten frei, um den Vor- & Nachnamen, die verifizierte Telefonnummer und die E-Mail des Schülers zu bekommen.',
      },
    },
    unknown: 'Unbekannt',
    yourContactDetailsHaveBeenSuccessfullyTransmitted:
      'Deine Kontaktdaten wurden erfolgreich übermittelt.',
    YouHaveDeclinedRequest: 'Du hast die Anfrage von {{firstName}} abgelehnt.',
    beforeAcceptOrRejectRequest:
      'Mit der Freischaltung der Kontaktdaten wird der vereinbarte Betrag pro Lead automatisch abgebucht.',
    contactPopup: {
      title: 'Die Nachhilfe kann beginnen',
      noPhoneNumber: 'Nicht angegeben',
      description:
        'Nimm jetzt Kontakt auf mit deinem Lernpartner, um die erste private Nachhilfestunde zu organisieren.',
    },
    anonymousUser: 'Nutzer nicht mehr verfügbar',
  },
  seoRoute: {
    [AppRoute.Home]: {
      title: '1-zu-1 Online-Nachhilfe & Hausaufgabenhilfe | TutorSpace',
      description:
        'Finde bei TutorSpace erschwingliche und bestbewertete Online-Nachhilfe für alle Fächer und Klassen. Jetzt Nachhilfe gratis testen!',
    },
    [AppRoute.SearchLocalTutorsBySubject]: {
      title: '{{subject}} Nachhilfe ᐅ Die besten 10 privaten Nachhilfelehrer',
      description:
        'lll➤ TutorSpace Nachhilfevergleich für {{subject}} ✅ Private Lehrer finden. Mehr als {{availableTutors}} Angebote für {{subject}} vergleichen  ▷ Kostenlos Lehrer kontaktieren!',
    },
    [AppRoute.SearchLocalTutorsPerCity]: {
      title: '{{city}} Nachhilfe ᐅ Die besten 10 privaten Nachhilfelehrer',
      description:
        'lll➤ TutorSpace Nachhilfevergleich in {{city}} ✅ Private Lehrer finden. Mehr als {{availableTutors}} Angebote in {{city}} vergleichen  ▷ Kostenlos Lehrer kontaktieren!',
    },
    [AppRoute.SearchLocalTutorsPerCityAndSubject]: {
      title: '{{city}} Nachhilfe für {{subject}} ᐅ Die besten 10 privaten Nachhilfelehrer',
      description:
        'lll➤ TutorSpace Nachhilfevergleich für {{subject}} in {{city}} ✅ Private Lehrer finden. Mehr als {{availableTutors}} Angebote für {{subject}} in {{city}} vergleichen  ▷ Kostenlos Lehrer kontaktieren!',
    },
    // when empty it will be taken from AppRoute.Home,
    [AppRoute.PublishOffer]: {
      title: '',
      description: '',
    },
    [AppRoute.EditOffer]: {
      title: '',
      description: '',
    },
    [AppRoute.SearchOnlineTutors]: {
      title: 'Online-Nachhilfe {{subject}} ᐅ die 10 besten privaten Tutoren',
      description:
        'lll➤ TutorSpace Nachhilfevergleich für {{subject}} ✅ Private Lehrer finden. Mehr als {{availableTutors}} Angebote für {{subject}} vergleichen  ▷ Kostenlos Lehrer kontaktieren!',
    },
    [AppRoute.SearchSchools]: {
      title: '{{city}} Nachhilfeschulen ᐅ die 10 besten Institute in {{city}}',
      description:
        'lll➤ TutorSpace Nachhilfevergleich in {{city}} ✅ Nachhilfeschulen finden & Bewertungen vergleichen. Mehr als {{availableTutors}} Angebote in {{city}} vergleichen  ▷ Direkt Institut anfragen!',
    },
    [AppRoute.RefugeeInitiative]: {
      title: 'Deutsch für Geflüchtete - Ehrenamtliche Helfer finden',
      description:
        'lll➤ Ein großes Angebot an privaten und ehrenamtlichen Deutschlehrern, die Geflüchteten helfen wollen. Jetzt einfach und gratis Lehrer anfragen.',
    },
    [AppRoute.SearchRefugeesOnline]: {
      title: 'Ehrenamtlicher Deutschunterricht online - Deutsch für Geflüchtete',
      description:
        'lll➤ Individueller Deutschunterricht online für Geflüchtete ✓ Ehrenamtliche Lehrer ✓ Lernunterlagen für Deutsch ✓ gratis Vermittlung ✓ Jetzt gratis ausprobieren!',
    },
    [AppRoute.SearchRefugeesPerCity]: {
      title: 'Ehrenamtlicher Deutschunterricht in {{city}} - Deutsch für Geflüchtete',
      description:
        'lll➤ {{city}}: Ein großes Angebot an ehrenamtlichen Deutschlehrern in {{city}} findest du bei TutorSpace. Jetzt Kontaktdaten zum Deutschkurs in {{city}} anfragen.',
    },
    [AppRoute.TutorDetails]: {
      title: '',
      description: '',
    },
    [AppRoute.SchoolDetails]: {
      title: '{{schoolName}} {{city}} | TutorSpace',
      description:
        '{{schoolName}} in {{postcode}} {{city}} ✅ Das sagen Nutzer über {{schoolName}} ✅ Finde mehr zu dieser Nachhilfeschule!',
    },
    [AppRoute.UserProfile]: {
      title: '',
      description: '',
    },
    [AppRoute.MyOfferRequests]: {
      title: '',
      description: '',
    },
    [AppRoute.MyOffer]: {
      title: '',
      description: '',
    },
    [AppRoute.ForTutorsBecomeTutor]: {
      title: '',
      description: '',
    },
    [AppRoute.ForStudentsTeachersNearYou]: {
      title: '',
      description: '',
    },
    [AppRoute.AboutTSHowDoesItWork]: {
      title: '',
      description: '',
    },
    [AppRoute.AboutTSForTutoringInstitutes]: {
      title: '',
      description: '',
    },
    [AppRoute.SupportAndHelpYouAskWeAnswer]: {
      title: '',
      description: '',
    },
    [AppRoute.SupportAndHelpContact]: {
      title: '',
      description: '',
    },
    [AppRoute.FooterImpressum]: {
      title: 'Impressum - TutorSpace',
      description:
        'Impressum TutorSpace Die mobile App TutorSpace ist ein Ort, an dem kompetente Nachhilfelehrer und hilfesuchende Schüler oder Studenten zusammen',
    },
    [AppRoute.FooterTOS]: {
      title: 'Nutzungsbedingungen - TutorSpace',
      description:
        'Durch die Verwendung unserer App und Website stimmst du diesen Nutzungsbedingungen zu. Bitte lies diese sorgfältig durch.',
    },
    [AppRoute.FooterDataProtection]: {
      title: 'Datenschutz - TutorSpace',
      description: '',
    },
    [AppRoute.ServiceAndAdviceHowDoesItWork]: {
      title: '',
      description: '',
    },
    [AppRoute.GetStartedNowAdvertiseOffer]: {
      title: '',
      description: '',
    },
    [AppRoute.SetPassword]: {
      title: '',
      description: '',
    },
    [AppRoute.NotFound]: {
      title: '',
      description: '',
    },
  },
  static: {
    termsOfUse: {
      title: 'Nutzungsbedingungen',
      bodyHeading: 'Nutzungsbedingungen für TutorSpace',
      providerHeading: 'Anbieter und Vertragspartner',
      scope: {
        heading: 'Geltungsbereich und Begriffsbestimmung',
        paragraphs: {
          paragraph1:
            '1.1 Die vorliegende Nutzungsbedingungen gelten für alle auf über die TutorSpace Web- und Appplattform angebotenen Leistungen.',
          paragraph2:
            '1.2 Für die Geschäftsbeziehungen zwischen TutorSpace (im Folgenden: “TutorSpace” oder “wir”), und dir, dem Nutzer von TutorSpace (im Folgenden: “Dir” oder “Du”), gelten ausschließlich die nachfolgenden Nutzungsbedingungen.',
          paragraph3:
            '1.3 Es gelten die zum Zeitpunkt des Vertragsschlusses aktuellen Nutzungsbedingungen. Wir können die Nutzungsbedingungen von Zeit zu Zeit aktualisieren und ändern. Die aktuelle Version der Nutzungsbedingungen kannst Du jederzeit unter Nutzungsbedingungen einsehen. Für die nachträglichen Änderungen der Nutzungsbedingungen im Rahmen eines bestehenden Vertragsverhältnisses gilt: TutorSpace wird Dich rechtzeitig über die geplante Änderung informieren. Wenn der Nutzer nicht innerhalb von 4 Wochen der Änderung widerspricht, gilt dies als Zustimmung zu den geänderten Nutzungsbedingungen. TutorSpace wird bei der Mitteilung der geplanten Änderungen auf diese Folge des Schweigens für den Nutzer besonders hinweisen. Falls Du der Änderung der Nutzungsbedingungen widersprichst, ist TutorSpace zur außerordentlichen Kündigung berechtigt. Entgegenstehende, sowie abweichende Bedingungen werden nicht anerkannt, es sei denn, wir stimmen ihrer Geltung ausdrücklich schriftlich zu.',
          paragraph4:
            '1.4 Die nachfolgenden Nutzungsbedingungen gelten auch dann ausschließlich, wenn wir in Kenntnis entgegenstehender und/oder von den nachfolgenden Nutzungsbedingungen abweichender Bedingungen die Lieferung und Leistung vorbehaltlos ausführen.',
          paragraph5:
            '1.5 Ergänzend zu den nachfolgenden Nutzungsbedingungen gelten für die Inanspruchnahme unseres Video-Unterrichtes - gekennzeichnet mit dem Begriff “Premium” - <a href="https://drive.google.com/file/d/1yYy21pqWzoyLu1xwXHPznHBiQiBBPMnT/view">folgende AGB’s</a>.',
          paragraph6:
            '1.6 Bei Inanspruchnahme unseren Leistungen als Nachhilfeschule gelten ergänzend <a href="https://drive.google.com/file/d/1-FAJxm5ZQdEqbLm5wR60M7dzop-V7RbK/view?usp=sharing">folgende AGB’s für gewerbliche Kunden</a>. Für den Fall, dass sich Regelungen den Nutzungsbedingungen widersprechen, gehen die besagten AGB’s inhaltlich vor. Als Nachhilfeschule gelten Unternehmen, die mit > 2 Lehrkräften zusammenarbeiten, um Unterrichtsdienstleistungen zu erbringen. Im Registrierungsprozess ist der entsprechende Account als Nachhilfeinstitut zu kennzeichnen.',
        },
      },
      description: {
        heading: 'Beschreibung der Leistung',
        paragraphs: {
          paragraph1:
            '2.1 TutorSpace ist eine Applikation, über die Kunden eigene Nachhilfeangebote erstellen können und/ oder die Angebote anderer Nutzer anfragen können. Dabei ermöglicht die Plattform, nach der kostenlosen Registrierung, Schülern und Nachhilfelehrern ein Profil zu erstellen, andere Profile zu suchen und anzufragen.',
          paragraph2:
            '2.2 Für die Nutzung des Angebotes ist es erforderlich, dass Du dich über die TutorSpace Website oder App registrierst. Der Nutzer kann sich sowohl mit seiner Email und einem Passwort, als auch mit seinem Facebook-oder Apple-Account registrieren und anmelden. Ohne eine Registrierung ist die Applikation nicht vollständig nutzbar und Nachhilfelehrer nicht anfragbar.',
          paragraph3:
            '2.3 Nachhilfeschulen ist es untersagt ohne ausdrückliche schriftliche Erlaubnis des Anbieters andere Nachhilfeanbieter über die Plattform zu kontaktieren oder mit diesen zu interagieren.',
          paragraph4:
            '2.4 Die Registrierung von mehreren Accounts auf eine Person ist nicht zulässig.',
          paragraph5:
            '2.5 In dem Nachhilfeangebot darf auf eventuelle weitere Nachhilfeangebote nicht hingewiesen werden, bzw. damit geworben werden.',
          paragraph6: '2.6 Wir behalten uns vor, entsprechende Accounts zu löschen.',
          paragraph7:
            '2.7 Ein Erfolg der Nachhilfevermittlung wird nicht geschuldet. TutorSpace stellt lediglich die Vermittlungsplattform zur Verfügung.',
          paragraph8:
            '2.8 Auf Anforderung ist der Nutzer verpflichtet, TutorSpace seine Identität durch entsprechende Nachweise zu bestätigen.',
          paragraph9:
            '2.9 Die Dienstleistung oder Teile davon sind in deutscher Sprache und möglicherweise nicht in allen Ländern verfügbar. Wir übernehmen keine Gewähr dafür, dass das Angebot auf TutorSpace oder Teile davon für eine bestimmte Region geeignet sind.',
          paragraph10:
            '2.9.1 Wir behalten uns das Recht vor, die Dienstleistungen (oder Teile davon) jederzeit und ohne Vorankündigung vorübergehend oder permanent zu ändern, zu aktualisieren, zu modifizieren oder einzustellen. ',
        },
        link:
          'Wenn du dich freiwillig engagieren möchten, solltest du nicht nur die allgemeinen COVID-19-Richtlinien, sondern auch die Richtlinien der Organisation, bei der du dich freiwillig engagierst, einhalten. Auch wenn du dich vor Ort freiwillig engagierst, indem du Mitgliedern deiner Gemeinde beim Einkaufen, beim Abholen der Rezepte oder beim Gassigehen mit dem Hund hilfst, solltest du immer einen Abstand von 2 Metern einhalten und die allgemeinen COVID-19-Richtlinien befolgen.',
      },
      account: {
        heading: 'TutorSpace Account',
        paragraphs: {
          paragraph1:
            '3.1 Ohne Account können lediglich Nachhilfeangebote auf TutorSpace gesucht und/oder abgerufen werden. Die Registrierung ist kostenlos.',
          paragraph2:
            '3.2 Die Nutzung von TutorSpace ist natürlichen Personen ab 18 Jahren gestattet, die in der Lage sind, einen rechtsverbindlichen Vertrag abzuschließen, sowie mündigen Minderjährigen zwischen 14 und 18 Jahren mit Einverständnis der Eltern.',
          paragraph3:
            '3.3 Ein Account ist nicht übertragbar. Du bestätigst, dass alle Informationen, die Du TutorSpace bei der Registrierung und/oder dem Abschluss bereitstellst, richtige, vollständige, exakte und aktuelle Angaben sind. Soweit sich Deine bei der Registrierung und/oder dem Abschluss der Mitgliedschaft angegebenen Daten ändern, bist Du selbst für deren Aktualisierung verantwortlich.',
          paragraph4:
            '3.4 Du bestätigst, dass Du diese Daten während der Laufzeit Deiner Mitgliedschaft auf TutorSpace pflegst und aktualisierst, um diese auf dem aktuellen Stand zu halten.',
          paragraph5:
            '3.5 Soweit Du einen Account über die TutorSpace Website oder App erstellst, wirst Du aufgefordert, diesen durch ein Kennwort zu schützen. Du erklärst dich damit einverstanden, dass es in Deiner Verantwortung liegt, die Vertraulichkeit und Sicherheit Deines Passwortes zu wahren.',
          paragraph6:
            '3.6 Du verstehst und erkennst an, dass Du die alleinige Verantwortung und Haftung für jegliche Aktivitäten trägst, die unter Deinem Account stattfinden. Es sei denn, Du kannst nachweisen, dass eine Verletzung Deiner Sorgfaltspflicht nicht vorliegt.',
          paragraph7:
            '3.7 Du bestätigst und erklärst Dich damit einverstanden, dass der Account nur für Deine persönliche Nutzung bzw. bei minderjährigen Mitgliedern nur für die Nutzung durch das Mitglied selbst vorgesehen ist und dass es Dir nicht gestattet ist, Deinen Account und/oder Dein Kennwort Dritten bereitzustellen.',
        },
      },
      obligations: {
        heading: 'Mitwirkungspflichten und Verhalten',
        paragraphs: {
          paragraph1:
            '4.1 Du bist verpflichtet, bei der Nutzung von TutorSpace alle anwendbaren Gesetze und sonstigen Rechtsvorschriften der Bundesrepublik Deutschland zu beachten.',
          paragraph2:
            '4.2 Es ist Dir untersagt, Daten oder Inhalte zu veröffentlichen, die gegen Rechtsvorschriften verstoßen oder fremde Schutz-oder Urheberrechte oder sonstige Rechte Dritter verletzen.',
          paragraph3:
            '4.3 Du darfst weder Software, noch andere Techniken oder Verfahren im Zusammenhang mit der Nutzung von TutorSpace verwenden, die geeignet sind, den Betrieb, die Sicherheit und die Verfügbarkeit der Serviceleistungen und deren Plattform zu beeinträchtigen.',
        },
      },
      warrantyAndLiability: {
        heading: 'Gewährleistung und Haftung',
        paragraphs: {
          paragraph1:
            '5.1 Soweit nachfolgend nicht anderweitig geregelt, haften wir für Sach-und Rechtsmängel nach den hierfür geltenden gesetzlichen Vorschriften, insbesondere §§ 434 ff. BGB.',
          paragraph2:
            '5.2 Die Verjährungsfrist für gesetzliche Mängelansprüche beträgt zwei Jahre. Die Verjährungsfrist beginnt mit der Erstellung eines Kontos.',
          paragraph3:
            '5.3 Für leichte Fahrlässigkeit haften wir nur, sofern wesentliche Pflichten, d. h. Verpflichtungen, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglichen und auf deren Einhaltung Du regelmäßig vertrauen und vertrauen darfst, verletzt werden.',
          paragraph4:
            '5.4 Bei Verletzung solcher Pflichten ist die Haftung von uns auf solche Schäden begrenzt, mit deren Entstehung im Rahmen des jeweiligen Vertrages typischerweise gerechnet werden muss.',
          paragraph5: '5.5 Im Übrigen ist die Haftung für leichte Fahrlässigkeit ausgeschlossen.',
          paragraph6:
            '5.6 Die vorstehenden Haftungsbeschränkungen gelten nicht für Schäden aus der Verletzung einer Garantie oder aus der schuldhaften Verletzung von Leben, Körper oder Gesundheit, bei Vorsatz oder grober Fahrlässigkeit sowie einer zwingenden gesetzlichen Haftung nach dem Produkthaftungsgesetz.',
          paragraph7:
            '5.7 Die Haftung der Organe, Mitarbeiter und Erfüllungsgehilfen von TutorSpace ist im gleichen Umfang ausgeschlossen wie die Haftung von TutorSpace.',
          paragraph8:
            '5.8 Für die permanente elektronische Verfügbarkeit der elektronischen Plattform übernimmt TutorSpace keine Gewährleistung und Haftung, da es aufgrund von technischen Erfordernissen zu Einschränkungen kommen kann. Störungen der Qualität des Zugangs zum Internet und des Datenverkehrs im Internet aufgrund höherer Gewalt und aufgrund von Ereignissen, die TutorSpace nicht zu vertreten hat und die die Leistungen wesentlich erschweren oder unmöglich machen, führen nicht zu einem Anspruch auf Schadensersatz wegen der Nichtverfügbarkeit.',
        },
      },
      dataProtection: {
        heading: 'Datenschutz',
        paragraphs: {
          paragraph1:
            '6.1 Wir erheben und verwenden die von Dir mitgeteilten personenbezogenen Daten (wie z. B. Name, Anschrift, Telefonnummer oder E-Mail-Adresse) gemäß den Bestimmungen des Bundesdatenschutzgesetzes und der EU-Datenschutzgrundverordnung. ',
          paragraph2:
            '6.2 Die Datenschutzbestimmungen kannst Du unter dem folgenden Link einsehen: <a href="/datenschutz">Datenschutz</a> ',
          paragraph3:
            '6.3 Der Nutzer gewährt TutorSpace das unentgeltliche, nicht ausschließliche, zeitlich unbefristete und räumlich uneingeschränkte Recht, in die Plattform eingestellte Bewertungen, Fotos, Grafiken, Texte, Audionachrichten und Videos zur Bereitstellung auf der Plattform und zur Bewerbung der Plattform zu verwenden, zu bearbeiten, zu vervielfältigen und zu verbreiten.',
          paragraph4:
            '6.4. Die Anwendung von Tools zur Datenanalyse auf Inhalte der Plattform ist nicht gestattet.',
        },
        link: 'Datenschutz',
      },
      settlementOfDisputes: {
        heading: 'Streitbeilegung',
        paragraphs: {
          paragraph1:
            'Informationen zur Online-Streitbeilegung nach Art. 14 Abs. 1 Verordnung (EU) Nr. 524/2013 (ODR-Verordnung) und nach § 36 VSBG (Verbraucherstreitbeilegungsgesetz): Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/main/">https://ec.europa.eu/consumers/odr/main/</a> finden. Wir sind nicht bereit und auch nicht verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
        },
      },
      finalProvisions: {
        heading: 'Schlussbestimmungen',
        paragraphs: {
          paragraph1:
            '8.1 Auf Verträge zwischen TutorSpace und Dir findet das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts Anwendung.',
          paragraph2:
            '8.2 Ist der Vertragspartner Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen, ist Gerichtsstand Stuttgart.',
          paragraph3:
            '8.3 TutorSpace ist jedoch berechtigt, am Sitz des Vertragspartners zu klagen.',
        },
      },
      updatedOn: 'Stand Juni 2021',
    },
    impressum: {
      title: 'Impressum',
      operatorHeading: 'Betreiber',
      representedBy: 'vertreten durch',
      headquarters: 'Sitz der Gesellschaft',
      ceo: 'Geschäftsführer: Patrick Nadler',
      ceoRegisterNumber: 'Registernummer: HRB 66993, Amtsgericht Ludwigshafen',
      phone: 'Telefonnummer',
      email: 'E-Mail Adresse',
      website: 'Website',
      taxIdHeading: 'Umsatzsteuer-ID',
      findDataProtection: 'Informationen zum Datenschutz bei TutorSpace findest du',
      contentManagement:
        'Verantwortlicher für journalistisch-redaktionelle Inhalte gem. § 18 Abs. 1 MStV',
      onlineDispute: {
        heading: 'Online Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO',
        paragraph1:
          'Informationen zur Online-Streitbeilegung nach Art. 14 Abs. 1 Verordnung (EU) Nr. 524/2013 (ODR-Verordnung) und nach § 36 VSBG (Verbraucherstreitbeilegungsgesetz): Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="https://ec.europa.eu/consumers/odr/main/">https://ec.europa.eu/consumers/odr/main/</a> finden. Wir sind nicht bereit und auch nicht verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.',
      },
      imageSourcesHeading: 'Bildquellen',
    },
    dataProtection: {
      applicationHeading: 'Datenschutzerklärung der Applikation',
      title: 'Datenschutz',
      websiteHeading: 'Datenschutzerklärung zur Website',
      preText: {
        paragraph1:
          'Das Schützen Deiner Informationen ist für TutorSpace (uns) eine Priorität. Daher haben wir uns entsprechend bemüht, Dir unsere Datenerhebung, den Umgang mit den Daten und deren Verarbeitung transparent und verständlich aufzubereiten.',
        paragraph2:
          'Unser Angebot (“TutorSpace”, “Angebot”, “Website” oder “Plattform”) wird von dem im <a href="/impressum">Impressum</a> beschriebenen Unternehmen zur Verfügung gestellt.',
        paragraph3:
          'Du hast Fragen zum Datenschutz bei TutorSpace? Melde Dich gerne beim entsprechenden Ansprechpartner. Informationen zu Deinen Rechten findest Du im unteren Teil dieser Erklärung.',
      },
      basics: {
        heading: '1. Wann findet diese Datenschutzerklärung Anwendung?',
        paragraph1:
          'Unsere Datenschutzerklärung beschreibt, auf welche Art und Weise und an welchen Stellen wir Daten erheben und verwenden. Dies erfolgt zum Beispiel, sobald Du',
        bulletpoints: {
          bulletpoint1: 'unsere Websites oder Apps besuchst',
          bulletpoint2: 'unsere Social Media-Kanäle nutzt',
          bulletpoint3: 'Dich zu unserem Newsletter anmeldest',
          bulletpoint4: 'Dich bei unserem Support meldest (im Folgenden: unsere "Angebote")',
        },
        paragraph2:
          'Wenn Du als Mitarbeiter, Bewerber oder als Partner/Lieferant/Auftragnehmer mit uns arbeitest, gelten die Datenschutzbestimmungen in den Vertragsunterlagen.',
      },
      operator: {
        heading: '2. Wer ist verantwortlich für den Umgang mit Daten?',
        paragraph1:
          'Die TutorSpace GmbH betreibt unter der Marke TutorSpace viele Services rund um das Thema Nachhilfe. Wenn neben TutorSpace noch andere Parteien in der Verarbeitung Deiner Daten involviert ist, informieren wir darüber im Verlaufe dieser Datenschutzerklärung.',
        paragraph2: 'Die Kontaktdaten des Verantwortlichen findest Du im Impressum.',
      },
      dataCollection: {
        heading: '3. Welche Daten werden verarbeitet?',
        paragraphs: {
          paragraph0: '<b>3.1. Automatisierte Datenerhebung über unsere Websites und Apps</b>',
          paragraph1:
            'Im Allgemeinen kannst Du unser Angebot nutzen, ohne einen eigenes Konto zu erstellen bzw. in diesem Zuge erst personenbezogene Daten bereitzustellen. Diese nichtpersonenbezogenen Daten können von uns keiner individuellen Person zugeordnet werden und werden anonym erfasst.',
          paragraph2: 'Hierzu zählen beim Aufruf einer Website etwa die folgenden Daten:',
          paragraph3:
            'Jenseits von diesen Daten erheben wir innerhalb unserer Angebote auch Informationen über Deine Art und Weise der Nutzung mit sog. Cookies oder anderen Messverfahren. Der Zweck der Erhebung und die Verfahren werden in dieser Datenschutzerklärung an einer anderen Stelle erklärt.',
          paragraph4:
            'Die Server-Logfiles werden für maximal 30 Tage gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt aus Sicherheitsgründen, um z.B. Missbrauchsfälle aufklären zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen, bis der Vorfall endgültig geklärt ist.',
          paragraph5: '<b>3.2. Kontoerstellung</b>',
          paragraph6:
            'Die Erstellung eines eigenen Nutzerkontos erlaubt Dir erweiterte Funktionen auf unserer Plattform auszuführen (bspw. das Erstellen eines Nachhilfeangebots / Kontaktieren eines Tutors/ Nachrichtenverwaltung). Für die Registrierung benötigst Du lediglich eine E-Mail-Adresse, ein Passwort, sowie eine verifizierte Handynummer, die es uns erlaubt, für mehr Sicherheit auf unserer Plattform zu sorgen.',
          paragraph7: 'Registrierung mit anderen Social Media Plattformen',
          paragraph8:
            'Mit einem sog. “Social Login” kannst Du auch Drittanbieterplattformen wie Facebook und Apple zur Anmeldung für Dein TutorSpace Konto nutzen. Je nachdem welche Einstellungen Du in der jeweiligen Drittanbieterplattform vornimmst, werden Name/Anrede/E-Mail/Profilfoto oder anderes an uns weitergegeben. Verantwortlich für die personenbezogenen Daten ist hier die Drittanbieterplattform.',
          paragraph9: '<b>3.3. Eingegebene Daten</b>',
          paragraph10:
            'Abhängig vom Zweck der Nutzung unseres Angebotes erheben wir Daten, die Du eingibst, zu denen Du aber nicht verpflichtet bist. Jedoch werden diese Daten für die Verwendung von einigen Funktionen notwendig.',
          paragraph11: 'Diese Daten sind grundsätzlich folgende:',
          paragraph12: '<b>3.3.1. Als Interessent/ Nachhilfesuchender</b>',
          paragraph13:
            'Als Interessent suchst Du mittels gewisser Daten wie Stadt/Preis/Fach/Unterrichtsniveau/... nach Angeboten. Auch verschiedene Filter nach eben diesen Parametern können vorgenommen werden. Welche Informationen allerdings erhoben werden, wird Dir immer sichtbar bei den einzelnen Funktionen visuell dargelegt.',
          paragraph14: '<b>3.3.2. Als Nachhilfelehrer</b>',
          paragraph15:
            'Als privater oder gewerblicher Nachhilfelehrer speichern wir Daten, die Du in Deinem Angebot oder den damit verbundenen Services angegeben hast.',
          paragraph16: '<b>3.3.2. Als gewerbliche Nachhilfeschule</b>',
          paragraph17:
            'Als gewerbliche Nachhilfeschule speichern wir Daten, die Du in Deinem Angebot oder den damit verbundenen Services angegeben hast.',
          paragraph18: '<b>3.3.3. Newsletter-Abonnent mit werblichen Angeboten</b>',
          paragraph19:
            'Als Abonnent von unserem E-Mail Newslettern oder anderen E-Mails mit Werbeangeboten speichern wir die E-Mail-Adresse sowie ergänzende Daten zum Newsletter (Öffnungsraten, Interaktionen, Zeitpunkt der Abbestellung). Das Austragen vom Empfang weiterer Mails ist immer am Ende jeder Sendung mit einem Klick möglich.',
          paragraph20: '<b>3.3.4. Besucher auf Social Media</b>',
          paragraph21:
            'Auf unseren Social Media Angeboten werden die meisten Daten vom Anbieter erhoben und dieser ist auch verantwortlich für die Verarbeitung und Nutzung. Interaktionen sowie die Nutzung von Funktionen, die notwendig sind in Bezug auf unsere Beiträge oder unsere Konten auf Social Media, werden von uns verarbeitet.',
          paragraph22: 'Beispiel',
          paragraph23:
            'Beim Besuch unserer Facebook-Seite erfasst Facebook u.a Deine IP-Adresse sowie weitere Informationen, die in Form von Cookies auf Deinem PC vorhanden sind. Diese Informationen werden verwendet, um uns als Betreiber der Facebook-Seite statistische Informationen über die Inanspruchnahme der Facebook-Seite zur Verfügung zu stellen. Nähere Informationen hierzu stellt Facebook unter folgendem Link zur Verfügung: <a href="http://de-de.facebook.com/help/pages/insights">http://de-de.facebook.com/help/pages/insights</a>. Mit Hilfe dieser anonymisierten Besucherstatistiken sind wir in der Lage, die Qualität unserer Facebook-Seite und unserer Inhalte zu bewerten. Diese Statistiken werden auf der Basis von Nutzungsdaten erstellt, die Facebook über Deine Interaktion mit unserer Facebook-Fanpage erhebt. Auf die Erzeugung und Darstellung haben wir als Betreiber der Facebook-Fanpage keinen Einfluss. Facebook hat sich uns gegenüber verpflichtet, für die Verarbeitung der Seiten-Insights-Daten primär die Verantwortung und die Erfüllung Deiner Rechte nach der EU-Datenschutzgrundverordnung zu übernehmen und Dir das Wesentliche der hierfür geltenden Vereinbarung zur Verfügung zu stellen.',
          paragraph24:
            'Gemäß dem Urteil des Europäischen Gerichtshofs (EuGH) vom 5. Juni 2018 (Az. C-210/16) besteht zwischen der Facebook Ireland Limited, 4 Grand Canal Square, Dublin, Ireland und uns eine gemeinsame Verantwortung bzgl. der Verarbeitung der Insights-Daten. Hierbei übernimmt Facebook Ireland Limited die primäre Verantwortung gemäß DSGVO für die Verarbeitung von Insights-Daten und erfüllt sämtliche Pflichten im Hinblick auf die Verarbeitung von Insights-Daten. Für die Geltendmachung der Rechte u.a. auf Auskunft in Bezug auf Insights-Daten wende Dich bitte an Facebook Ireland Limited. Weitere Informationen dazu findest Du auf der Facebookseite unter folgendem Link: <a href="https://www.facebook.com/legal/terms/page_controller_addendum">https://www.facebook.com/legal/terms/page_controller_addendum</a>.',
          paragraph25:
            'Rechtsgrundlage für die Nutzung der Insights-Daten ist Art. 6 Abs. 1 Buchstabe f) DS-GVO. Wir als Betreiber der Facebook-Fanpage haben ein berechtigtes Interesse, insbesondere um auf Nutzerbeiträge reagieren sowie unsere Angebote stetig verbessern zu können. Rückschlüsse auf einzelne User sowie Zugriffe auf einzelne Userprofile durch sind uns nicht möglich. Die nach der DSGVO erforderlichen Informationen zu der Datenverarbeitung im Rahmen von Seiten-Insights erhältst Du bei Facebook in den Datenschutzhinweisen unter <a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a>.',
        },
        bulletpoints: {
          bulletpoint1: 'Dateiname der Seite',
          bulletpoint2: 'von Dir im Rahmen von Eingaben übergebene Informationen',
          bulletpoint3: 'Vorgängerseiten beim Navigieren in unseren Angeboten',
          bulletpoint4: 'Datum/ Uhrzeit',
          bulletpoint5: 'übertragene Datenmenge',
          bulletpoint6: 'Zugriffsstatus (Seite nicht mehr verfügbar etc.)',
          bulletpoint7: 'Typ und Betriebssystem des verwendeten Webbrowsers',
          bulletpoint8:
            'IP-Adresse des Clients und ggf. dessen Domainnamen bzw. der Name des Internet-Service-Providers',
          bulletpoint9: 'Anmeldedaten, Statistiken zu Seitenaufrufen und Verkehrsdaten',
        },
      },
      legalBasisNew: {
        heading: '4. Welchem Zweck dient die Datenverarbeitung? Was ist die Rechtsgrundlage?',
        preText: {
          paragraph1:
            'Hauptsächlich erfolgt die Datenverarbeitung, damit wir unsere Angebote und alle damit verbundenen Funktionen auf Dich angepasst bereitstellen können.',
          paragraph2:
            'Die Verarbeitung dient auch weiterer Zwecke, die wir nachfolgend transparent darstellen. Zusammenfassend lassen sich folgende benennen:',
          bulletpoints: [
            'Nutzungsanalyse',
            'Ausspielung von Online-Werbung',
            'Direktwerbung via Mail',
            'Sicherheits- und Rechtszwecke',
          ],
        },
        paragraphs: {
          paragraph: '<b>4.1. Zur Bereitstellung unserer Angebote</b>',
          paragraph1:
            'Daten werden von uns verarbeitet, damit wir unsere Angebote zur Verfügung stellen können. Insbesondere bezieht sich das auf das Bereitstellen eines Marktplatzes für Nachhilfeanbieter und Interessenten, sowie der Kontaktherstellung zwischen beiden Parteien.',
          paragraph2:
            'Datenfelder, die verpflichtend auszufüllen sind, sind notwendig, um die jeweiligen Funktionen bereitstellen zu können. Entsprechende Felder sind bei der Nutzung als solche gekennzeichnet.',
          paragraph3:
            'Beispiel: Du kannst ein Nachhilfeangebot aufgeben. Hierbei speichern wir Deine Angebotsdaten sowie Accountdaten, um Dein Angebot Interessenten zu empfehlen und diese mit Dir zu verbinden.',
          paragraph4:
            'Die Bereitstellung unserer Angebote geht einher mit dem Zweck, diese bedarfsgerecht und individuell gestalten zu können. Dies ist auch ein Kernstück unseres Angebotes und dient dazu, den bestmöglichen Nutzen für Dich zu erbringen. Daher empfehlen wir auch die Anmeldung eines Nutzerkontos. Weitere Informationen zu den hierfür eingesetzten Verfahren unter Ziffer 4.4.',
          paragraph5:
            'Damit Dein Angebot auf mehr Interessenten stößt, verbreiten wir unsere Inserate teilweise auch über kooperierende Plattformen/Websites. In diesem Fall geben wir Daten, die von Dir in den Inseraten öffentlich sichtbar sind weiter an eben diese Kooperationspartner (Stand 21.05.2021 Scoolio GmbH, markt.de GmbH & Co. KG). Auch verwenden wir Inserate in Benachrichtigungs-E-Mails und nutzen diese in sozialen Medien (wie. z.B. Google/Facebook).',
          paragraph6:
            'Im Zuge definierter Prozesse ermöglichen wir Dir, über unsere Bewertungsfunktion auch zur Qualitätssicherung auf unserer Plattform beizutragen. Bewertungen sind ein wichtiger Indikator für Interessenten für die Entscheidungsfindung. Zu diesem Zweck wirst Du von uns auch gebeten, eine solche Bewertung zu gegebenem Zeitpunkt abzugeben.',
          paragraph7:
            'Des Weiteren findet auch eine Verarbeitung von Daten statt, die zur Durchführung und Abwicklung eingegangener Vertragsverhältnisse notwendig ist (z.B. bei der Kontaktaufnahme bei bestehenden Problemen). Hierzu gehört auch, dass wir Daten zu einem etwaigen Vertragsschluss zwischen Dir und anderen Nutzern unserer Marktplattform erheben und anderweitig verarbeiten, sofern dies für die Abrechnung unserer Leistungen gegenüber Deinem Vertragspartner oder für andere berechtigte Interessen, wie etwa die Durchsetzung rechtlicher Ansprüche, erforderlich ist.',
          paragraph8:
            'Diese Verarbeitungen erfolgen auf Basis gesetzlicher Vorschriften, die es uns gestatten, personenbezogene Daten zu verarbeiten, soweit es für die Nutzung eines Dienstes bzw. die Erfüllung eines Vertrages erforderlich ist (Art. 6 Abs. 1 b) DSGVO).',
          paragraph9:
            'In einigen Fällen holen wir auch Deine Einwilligung in die Datenverarbeitung ein (Art. 6 Abs. 1 a) DSGVO).',
          paragraph10:
            '<b>4.2. Zur Zusendung von individuellen Empfehlungen einschließlich E-Mail Werbung</b>',
          paragraph11:
            'Wenn Du nach Art. 6 Abs. 1 a DSGVO) zugestimmt hast oder wir anderweitig hierzu berechtigt sind, findet die Datenverarbeitung auch statt, um Dir Empfehlungen (Produkte, Dienstleistungen, Events, und Werbeaktionen) zukommen zu lassen. Dabei werden Kanäle wie Telefon, SMS, Post und Social Media Netzwerke von Drittanbietern genutzt.',
          paragraph12:
            'Diese Informationen können auf Deine Präferenzen zugeschnitten sein. Dabei können wir Eingabedaten von Dir mit Verhaltensdaten durch die Nutzung unserer Angebote zusammenführen. Beispielsweise können wir Dir passende Angebote in Stuttgart per E-Mail zukommen lassen, wenn Du zuvor nach Nachhilfe in Berlin auf unserer Plattform gesucht hast.',
          paragraph13: '<b>4.3. Zur Ausspielung von Online-Werbung</b>',
          paragraph14:
            'Unsere Angebote enthalten Online-Werbung, die für Dich basierend auf Deinem Nutzerverhalten und Prognosen optimiert ausgespielt werden.',
          paragraph15:
            'Zu diesem Zweck erheben wir Daten basierend auf Deinen Aktivitäten, auf unseren Angeboten sowie, falls Du auf eine Werbeanzeige geklickt hast, den Aktivitäten auf den jeweiligen Unterseiten des Werbepartners. Das hilft uns, den Erfolg der angezeigten Werbung messbar zu machen und Dir das beste Nutzererlebnis zu bieten.',
          paragraph16:
            'Beispiel: Werbung, die Dir nicht gefällt, wird nicht mehr angezeigt und ersetzt durch Werbung mit lokalem Bezug, wenn Du zuvor nach Inseraten in Berlin gesucht hast.',
          paragraph17: '<b>4.4. Plugins</b>',
          paragraph18:
            'Auf unseren Angeboten verwenden wir sog. Plugins von anderen Herstellern, die unsere Funktionen ermöglichen oder unterstützen.',
          paragraph19:
            'Beispiel: Wir verwenden Google Maps zur Visualisierung von digitalen Karten und der Umgebung der jeweiligen Inserate. Hierbei erhebt Google als Hersteller Deine IP-Adresse und ggf. andere Daten. Es gelten die Datenschutzbestimmungen von Google, abrufbar unter <a href="https://www.google.com/intl/de/policies/privacy/">https://www.google.com/intl/de/policies/privacy/</a>. Diese Verarbeitung erfolgen durch gesetzliche Vorschriften und das überwiegend berechtigte Interesse von TutorSpace hieran. (Art. 6 Abs. 1 f) DSGVO). Das berechtigte Interesse gründet sich hier auf die bedarfsgerechte Gestaltung unseres Angebotes durch bspw. Google Maps. ',
          paragraph20:
            'Verschiedene “Teilen”-Funktionalitäten von sozialen Netzwerken sind teilweise nicht als Plugin, sondern als Link eingebunden. Du kannst die Funktion in diesem Fall mit nur einem Klick nutzen. In der URL des Links werden dann die entsprechenden Inhalte eingebunden, die geteilt werden sollen. Aber erst, wenn Du Dich auf dem Sozialen Netzwerk anmeldest, kannst Du den Inhalt auch teilen. Die darauf folgende Verarbeitung und Erhebung von Daten bei den Drittanbietern liegt in deren Verantwortung und ist geregelt durch die jeweiligen Datenschutzerklärungen.',
          paragraph21: '<b>4.5. Zum Schutz vor Missbrauch</b>',
          paragraph22:
            'Auch erheben und verarbeiten wir Informationen, um im Interesse und Recht unserer Kunden und Seitenbesucher Missbrauchsfällen innerhalb unserer Angebote effektiv Einhalt zu gebieten.',
          paragraph23:
            'Beispiel: Bei Kontaktherstellung zwischen Interessent und Nachhilfelehrer erheben wir automatisch Informationen wie Name, Angebotsdaten und Anfragedaten. Dadurch können wir betrügerische Aktivitäten frühzeitig erkennen und den Austausch blockieren.',
          paragraph24:
            'Darüber hinaus nutzen wir Deine Daten zur Sicherstellung der Systemsicherheit sowie zur Nachverfolgung von Fremdzugriffen und anderer unzulässiger Zugriffsversuche auf unsere Server.',
          paragraph25:
            'Diese Verarbeitungen erfolgen im Rahmen der Erbringung der von Dir angefragten Angebote (Art. 6 Abs. 1 lit. b) DSGVO) bzw. auf Basis gesetzlicher Vorschriften, die es uns gestatten, personenbezogene Daten zum Schutz vor Missbrauch und zur Rechtsverfolgung zu verarbeiten und weil TutorSpace ein überwiegendes berechtigtes Interesse an Verarbeitungen zur Gewährleistung der Funktionsfähigkeit und Sicherheit des Angebots hat (Art. 6 Abs. 1 f) DSGVO).',
          paragraph26: 'Für diesen Zweck nutzen wir die bereits beschriebene Cookie-Technologie.',
          paragraph27: '<b>4.6. Zur Marktrecherche und Markttransparenz</b>',
          paragraph28:
            'Die von unseren Nachhilfeanbietern veröffentlichten Inseratsdaten (Fach, Preis, Alter, …) werden von uns verwendet sowie veröffentlicht, um Markttransparenz zu schaffen. Dabei werden allerdings jegliche Informationen der einzelnen Inserate und Inserenten entfernt. Somit ist kein Rückschluss auf einzelne Personen möglich.',
          paragraph29:
            'Die Verarbeitung der Daten begründen wir in dem berechtigten Interesse (Art. 6 Abs. 1 f) DSGVO) in Bezug auf die wirtschaftliche Verwertung dieser Auswertungen.',
        },
      },
      thirdparty: {
        headline: '5. Wem geben wir Daten weiter?',
        paragraphs: {
          paragraph1:
            'Personenbezogene Daten werden neben den bisher genannten Fällen nur in folgenden Szenarien weitergegeben:',
          paragraph2: '<b>5.1. Weitergabe im Zuge der Bereitstellung unserer Angebote</b>',
          paragraph3:
            'Im Zuge der Ermöglichung unserer Angebote und Services geben wir Daten beispielsweise an Nachhilfeinstitute weiter, sofern Du diese angefragt hast. Nähere Informationen zu den Empfängern gehen bei Verwendung der Funktionen aus der Benutzeroberfläche hervor.',
          paragraph4:
            'Beispiel: Wenn Du ein Nachhilfeinstitut anfragst und Dich für deren Angebot interessiert, geben wir in manchen Fällen Deine personenbezogene Daten an eben dieses weiter, sodass es für Dich das passende Angebot erstellen und mit Dir in Verbindung treten kann. ',
          paragraph5:
            'Diese Weitergabe erfolgt auf Basis gesetzlicher Vorschriften, die es uns gestatten, personenbezogene Daten zu verarbeiten, soweit es für die Nutzung eines Dienstes bzw. die Erfüllung eines Vertrages erforderlich ist (Art. 6 Abs. 1 b) DSGVO), bzw. weil TutorSpace ein überwiegendes berechtigtes Interesse daran hat, die Nutzung der Plattform so leicht und effizient wie möglich zu machen (Art. 6 Abs. 1 f) DSGVO). In einigen Fällen holen wir auch Deine Einwilligung in die Datenverarbeitung ein (Art. 6 Abs. 1 a) DSGVO).',
          paragraph6:
            '<b>5.2. Weitergabe und gemeinsame Verantwortlichkeit innerhalb der TutorSpace GmbH</b>',
          paragraph7:
            'Personenbezogene Daten (bspw. Accountname, E-Mail, …) können innerhalb von unserem Unternehmen für interne Zwecke wie Kundensupport und Verwaltung verarbeitet werden, sofern dies erforderlich und rechtlich zulässig ist. Unser berechtigtes Interesse ist es, die Kundenbetreuung so effizient und nutzerfreundlich wie möglich zu gestalten.',
          paragraph8: '<b>5.3. Weitergabe an weisungsgebundene Dienstleister</b>',
          paragraph9:
            'In manchen Fällen setzen wir für bestimmte Verarbeitungsaktivitäten weisungsgebundene Dienstleister gemäß Art. 28 DSGVO ein. ',
          paragraph10: '<b>5.4. Weitergabe zur Rechtsverfolgung</b>',
          paragraph11:
            'Falls notwendig im Sinne von rechtswidriger oder missbräuchlicher Nutzung (auch Verstöße gegen die Nutzungsbedingungen) erfolgt die Weitergabe von personenbezogenen Daten an entsprechende staatliche Behörden und geschädigte Dritte.',
          paragraph12:
            'Unser berechtigtes Interesse im Sinne von Art. 6 Abs. 1 f) DSGVO an der Datenverarbeitung liegt dabei darin, das ordnungsgemäße Funktionieren unserer Website und des Dienstes sicherzustellen sowie gegebenenfalls Rechtsansprüche geltend zu machen, auszuüben oder zu verteidigen.',
          paragraph13:
            'Wir sind zudem gesetzlich im Sinne des Art. 6 Abs. 1 c) DSGVO verpflichtet, auf Anfrage bestimmten öffentlichen Stellen Auskunft zu erteilen. Dies sind Strafverfolgungsbehörden, Behörden, die bußgeldbewährte Ordnungswidrigkeiten verfolgen und die Finanzbehörden.',
        },
      },
      dataStorage: {
        headline: '6. Wie lange speichern wir Deine Daten?',
        paragraphs: {
          paragraph1:
            'Grundsätzlich speichern wir Deine personenbezogene Daten nur so lange, wie dies zur Erreichung der beschriebenen Zwecke und nach gesetzlichen Vorschriften notwendig ist. Danach löschen wir die Daten bzw. sie werden wie gesetzlich festgelegt anonymisiert.',
          paragraph2:
            'Bitte beachte, dass wir Dein Nutzerkonto erst löschen, wenn Du diese Funktion selbst ausübst oder uns anweist, Deinen Account zu löschen. Werden wir zur Löschung angewiesen, erfolgt die Löschung aller personenbezogenen Daten innerhalb von einer technischen Prozessdauer von 14 Tagen.',
          paragraph3:
            'Wenn Daten aus überwiegend berechtigten Interessen (wie gesetzliche Aufbewahrungsfristen von Zahlungsdaten) nicht gelöscht werden können, wird lediglich die Verarbeitung der betroffenen Daten eingeschränkt.',
          paragraph4:
            'Wenn Deine Daten bei der Nutzung unserer Angebote an Drittanbieter weitergeleitet wurden (beispielsweise nach einer Anfrage Deinerseits gegenüber eines Nachhilfeinstitutes), sind diese für die Löschung und Verarbeitung zuständig. Die Kontaktdaten werden dem Nutzer bei der Ausübung der betroffenen Funktionen mitgeteilt, sodass die Rechte gegenüber den Drittanbietern geltend gemacht werden können.',
        },
      },
      yourRightsNew: {
        headline: '7. Welche Rechte hast Du?',
        paragraphs: {
          paragraph1: '<b>7.1. Auskunftsrecht</b>',
          paragraph2:
            'Nach Art. 15 DSGVO hast Du zu jedem gegebenen Zeitpunkt das Recht, Auskunft über Deine personenbezogenen Daten zu erhalten. Auch bist Du berechtigt Auskunft über die Verarbeitung, deren Zwecke, Empfänger, denen Deine Daten offengelegt wurden, die Speicherdauer und weitere zu verlangen. ',
          paragraph3:
            'Damit Deine Rechte geltend gemacht werden können, musst Du Dich an die Unternehmen wenden, deren Angebote Du genutzt hast.',
          paragraph4:
            'Wenn die jeweiligen Voraussetzungen erfüllt sind, stehen Dir auch folgende Rechte zu:',
          paragraph5: '<b>7.2. Widerrufsrecht</b>',
          paragraph7:
            'Dir steht gem. Art. 7 Abs. 3 DSGVO das Recht zu, Deine einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen.',
          paragraph8: '<b>7.3. Recht zur Berichtigung und Vervollständigung von Daten</b>',
          paragraph9:
            'Du hast das Recht, von uns die unverzügliche Berichtigung der Dich betreffenden personenbezogenen Daten zu verlangen, sofern diese unrichtig sein sollten.',
          paragraph10: '<b>7.4. Recht auf Löschung</b>',
          paragraph11:
            'Du hast das Recht, unter den in Art. 17 DSGVO beschriebenen Voraussetzungen, von uns die Löschung der Dich betreffenden personenbezogenen Daten zu verlangen, etwa wenn Deine Daten für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr erforderlich sind.',
          paragraph12: '<b>7.5. Recht auf Einschränkung der Verarbeitung</b>',
          paragraph13:
            'Du hast das Recht, von uns die Einschränkung der Verarbeitung nach Maßgabe des Art. 18 DSGVO zu verlangen. ',
          paragraph14: '<b>7.6. Beschwerderecht</b>',
          paragraph15:
            'Du hast das Recht nach Art. 77 DSGVO Dich bei den zuständigen Behörden zu beschweren. Weitere Informationen hierzu findest Du unter <a href="https://www.datenschutz.rlp.de/de/startseite/">https://www.datenschutz.rlp.de/de/startseite/</a>.',
          paragraph16: '<b>7.7. Recht auf Datenübertragbarkeit</b>',
          paragraph17:
            'Nach Art. 20 DSGVO steht Dir das Recht zu, die Dich betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, sofern und soweit Du uns die Daten zur Verfügung gestellt hast.',
          paragraph18: '<b>7.8. Widerspruchsrecht</b>',
          paragraph19: 'Information über Dein Widerspruchsrecht nach Art. 21 DSGVO',
          paragraph20:
            'Es besteht das Recht, aus Gründen, die sich aus der besonderen Situation ergeben, jederzeit gegen die Verarbeitung betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Absatz 1 Buchstabe f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling von Artikel 4 Nr. 4 DSGVO.',
          paragraph21:
            'Legst Du Widerspruch ein, werden wir Deine personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Deine Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.',
          paragraph22:
            'Sofern sich Dein Widerspruch gegen eine Verarbeitung von Daten zum Zwecke der Direktwerbung richtet, so werden wir die Verarbeitung umgehend einstellen. In diesem Fall ist die Angabe einer besonderen Situation nicht erforderlich. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.',
          paragraph23:
            'Möchtest Du von Deinem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an die aufgeführten Kontaktdaten.',
          paragraph24:
            'Wenn Du individuelle Produktempfehlungen per Push-Service in unseren Apps abbestellen möchten, kannst Du diese Funktion in den Einstellungen der jeweiligen App jederzeit deaktivieren. In den meisten Apps findest Du diese Funktion im Bereich "Einstellungen", unter "Mitteilungen" oder "Push-Nachrichten".',
          paragraph25:
            'Bitte beachte, dass Du auch dann, wenn Du Dich gegen den Erhalt von Marketingmitteilungen entscheidest, weiterhin gewisse Mitteilungen von uns erhalten kannst. Hiervon erfasst sind etwa technische Aktualisierungen unserer Produkte, Auftragsbestätigungen, Benachrichtigungen über Deine Kontoaktivitäten und andere wichtige Mitteilungen.',
        },
      },
      changes: {
        headline: '8. Änderung dieser Datenschutzinformation',
        paragraphs: {
          paragraph1:
            'Wir werden diese Datenschutzinformation von Zeit zu Zeit dementsprechend anpassen. Die jeweils aktuelle Version ist auf unserer Website unter der Rubrik "Datenschutz" abrufbar.',
        },
      },
      general: {
        heading: '1. Allgemeines',
        paragraph:
          'Die am 25. Mai 2018 in Kraft tretende EU-Datenschutz-Grundverordnung (DSGVO) ist eine Verordnung der Europäischen Union, mit der die Regeln für die Verarbeitung von personenbezogenen Daten durch private Unternehmen und öffentlichen Stellen in der EU vereinheitlicht werden. Dadurch soll der Schutz von personenbezogenen Daten innerhalb der EU insgesamt sichergestellt werden. Wir von TutorSpace nehmen den Schutz Deiner persönlichen Daten sehr ernst. Deine Privatsphäre ist für uns ein wichtiges Anliegen. Wie verarbeiten Deine personenbezogenen Daten im Einklang mit den jeweils anzuwendenden gesetzlichen Datenschutzanforderungen zu den nachfolgend aufgeführten Zwecken. Personenbezogene Daten im Sinne dieser Datenschutzinformation sind sämtliche Informationen, die einen Bezug zu Deiner Person aufweisen. Hierbei handelt es sich insbesondere um die von uns gesetzlich oder kraft vertraglicher Vereinbarung zu erhebenden Vertragsdaten. Dies betrifft vornehmlich Angaben zur Person des Kunden (z.B. Benutzername, E-Mail, Familienname und Vorname sowie Adresse). Bei Tutoren speichern wir darüber hinaus Informationen, die im Profil hinterlegt sind.',
      },
      responsibleBody: {
        heading: '2. Verantwortliche Stelle',
        paragraph:
          'Verantwortlich für die Verarbeitung deiner personenbezogenen Daten ist die TutorSpace GmbH, Pettenkoferstraße 9 67063 Ludwigshafen.',
      },
      purposes: {
        heading: '3. Zwecke der Verarbeitung und Empfänger',
        subsections: {
          contractProcessing: {
            heading: '3.1. Vertragsabwicklung',
            paragraph:
              'Wir oder von uns beauftragte Dienstleister verarbeiten Deine personenbezogenen Daten zur Erfüllung des Vertrages mit Dir. Zur Erfüllung des Vertrages, Kontaktaufnahme zur Nachhilfe, sowie der Versendung von Nachrichten etc. übermitteln wir Deine personenbezogenen Daten auch an Dritte (z.B. die von uns beauftragten IT-Dienstleister). Rechtsgrundlage für die Verarbeitung und Bereitstellung Deiner personenbezogenen Daten ist somit die Verarbeitung zur Vertragserfüllung und -durchführung. Ohne diese können wir den Service von TutorSpace nicht anbieten und abwickeln.',
          },
          advertising: {
            heading: '3.2. Werbung und maßgeschneiderte Angebote',
            paragraph:
              'Wir nutzen Deine personenbezogenen Daten auch, um Dir Produktinformationen über von TutorSpace angebotene Produkte im Bereich von Schulungs- und Nachhilfeangeboten zukommen zu lassen. Die vorgenannte Verarbeitung erfolgt, wenn hierfür ein berechtigtes Interesse besteht, sofern nicht Dein schutzwürdiges Interesse überwiegt (Interessenabwägung). Rechtsgrundlage ist ein berechtigtes Interesse. Das berechtigte Interesse unseres Unternehmens besteht darin, Dir maßgeschneiderte Produkte anzubieten sowie Services und Produkte zu verbessern. Ansonsten werden wir Dich werblich nur ansprechen, wenn Du hierzu Deine gesonderte Einwilligung erteilt hast oder eine gesetzliche Rechtfertigungsgrundlage besteht.',
          },
          creditCheck: {
            heading: '3.3. Bonitätsprüfung',
            paragraph:
              'Wir behalten uns vor, soweit im Einzelfall vertraglich vereinbart, eine Bonitätsauskunft über Dich einzuholen. Dies kann bei Vertragsschluss, vor einer Vertragsverlängerung, bei einer Vertragsänderung,, bei Bekanntwerden ggf. bonitätsbeeinträchtigender Umstände oder stichwortartig erfolgen. Zu diesem Zweck übermitteln wir ggf. Deinen Namen und Deine Anschrift an Creditreform GmbH, Radlkoferstraße 2, 81373 München. Liegt eine negative Auskunft zu Merkmalen Deiner Bonität vor, können wir es ablehnen, mit Dir ein Vertragsverhältnis einzugehen. Rechtsgrundlage für die Verarbeitung ist eine Interessenabwägung. Unser berechtigtes Interesse liegt in der Bewertung Deiner Bonität und Reduzierung des Risikos auf Zahlungsausfällen. Wir behalten uns vor, anstelle der genannten Wirtschaftsauskunft auch eine andere Wirtschaftsauskunft einzusetzen. In diesem Fall werden wir darauf achten, dass diese mindestens die gleiche Gewähr für die Einhaltung des Datenschutzes bietet, wie die zuvor genannte.',
          },
          other: {
            heading: '3.4. Sonstige Empfänger und Zwecke',
            paragraph:
              'Wir lassen einzelne der vorgenannten Prozesse und Servicedienstleistungen unter Umständen durch sorgfältig ausgewählte und beauftragte Dienstleister, insbesondere IT-Dienstleister, ausführen, die Deinen Sitz in einigen Fällen außerhalb der EU/EWR (Drittland) haben können. In diesen Fällen findet eine Drittlandsübermittlung von Daten statt. Mit den Dienstleistern werden den gesetzlichen Anforderungen entsprechende Datenschutzvereinbarungen zur Herstellung eines angemessenen Datenschutzniveaus vertraglich festgelegt. Dazu zählen EU-Standardverträge. Sofern Dienstleister nicht im Rahmen einer Auftragsvereinbarung für uns tätig werden, ist die Rechtsgrundlage für die Weitergabe der Daten, dass die Leistungen Dritter zum Zwecke der optimierten und effizienten Erfüllung des Vertrages mit Dir bzw. der Erfüllung unserer Vertragspflichten erforderlich sind. Vor jedweder Übermittlung Deiner Kundendaten an Dritte erfolgt unsererseits eine Interessenabwägung. Hierbei wird unser berechtigtes Interesse an einer Auswertung der Akzeptanz unserer Produkte oder der Feststellung der Zufriedenheit unserer Kunden abgewogen mit Deinen Interessen an einem verantwortungsvollen Umgang mit Deinen Daten.',
          },
        },
      },
      duration: {
        heading: '4. Dauer der Speicherung und Löschung Deiner Daten',
        paragraph:
          'Wir löschen Deine personenbezogenen Daten, wenn das Vertragsverhältnis mit Dir beendet ist (z.B. durch Löschung Deines Profils in der TutorSpace App), sämtliche gegenseitige Ansprüche erfüllt sind und keine gesetzlichen Aufbewahrungspflichten oder gesetzliche Rechtfertigungsgrundlagen für die Speicherung mehr bestehen. Deine E-Mail nutzen wir ggf. für einen Zeitraum von maximal 24 Monaten nach Beendigung des Vertragsverhältnisses. Rechtsgrundlage für die Verarbeitung ist eine Interessenabwägung. Unser berechtigtes Interesse besteht darin, Dich im Rahmen von werblichen Anschreiben von unseren Produkten und unserem Service zu überzeugen.',
      },
      legalBasis: {
        heading: '5. Rechtsgrundlage der Verarbeitung',
        paragraphs: {
          paragraph1:
            'Soweit wir für Verarbeitungvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Artikel 6 Absatz 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.',
          paragraph2:
            'Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich sind, dient Artikel 6 Absatz 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.',
          paragraph3:
            'Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, denen unser Unternehmen unterliegt, dient Artikel 6 Absatz 1 lit. c DSGVO als Rechtsgrundlage.',
          paragraph4:
            'Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Artikel 6 Absatz 1 lit. d DSGVO als Rechtsgrundlage.',
          paragraph5:
            'Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Artikel 6 Absatz 1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung. Das berechtigte Interesse unseres Unternehmens liegt in der Durchführung unserer Geschäftstätigkeit.',
        },
      },
      yourRights: {
        heading: '6. Deine Rechte',
        subsections: {
          informationCorrection: {
            heading: '6.1. Auskunft, Berichtigung, Löschung etc.',
            paragraph:
              'Gerne geben wir Dir Auskunft darüber, ob und welche personenbezogenen Daten von Dir bei uns gespeichert sind und an wen wir diese ggf. weitergegeben haben. Nach Maßgabe der gesetzlichen Bestimmung kannst Du folgende weitere Rechte geltend machen: Berichtigung, Löschung, Einschränkung der Verarbeitung (Sperrung für bestimmte Zwecke) sowie Datenschutzübertragung.',
          },
          directEmailObjection: {
            heading: '6.2. Widerspruchsrecht gegen Direktwerbung etc.',
            paragraph:
              'Sofern wir eine Verarbeitung von Daten auf Grundlage sog. Interessenabwägung vornehmen (siehe Ziffer 3.2 bis 3.3), hast Du jederzeit das Recht, aus Gründen, die sich aus Deiner besonderen Situation ergeben, gegen diese Verarbeitung Widerspruch einzulegen. Insbesondere hast Du das Recht, Widerspruch gegen die Verarbeitung zu Werbezwecken einzulegen.',
          },
          withdrawal: {
            heading: '6.3. Widerrufsrecht',
            paragraph:
              'Sofern Du uns eine gesonderte Einwilligung für die Verarbeitung Deiner personenbezogenen Daten erteilt hast, kannst Du diese jederzeit uns gegenüber widerrufen. Die Rechtmäßigkeit der Verarbeitung Deiner Daten bis zum Widerruf bleibt von einem Widerruf unberührt.',
          },
          complaints: {
            heading: '6.4. Fragen oder Beschwerden',
            paragraph:
              'Du hast das Recht, dich bei Fragen oder Beschwerden an die zuständige Aufsichtsratsbehörde zu wenden. Diese ist der Landesbeauftragte für den Datenschutz und die Informationsfreiheit, Königsstraße 10 a, 70171 Stuttgart.',
          },
          contact: {
            heading: '6.5. Kontaktdaten',
            paragraph1:
              'Wenn Du Fragen oder Anmerkungen zum Datenschutz hast, nehme bitte folgendermaßen Kontakt mit uns auf:',
            paragraph2:
              'die TutorSpace GmbH\n, Pettenkoferstraße 9\n67063 Ludwigshafen \nsupport@tutorspace.de',
          },
        },
      },
    },
  },
  sidebar: {
    custom: {
      promotion: '🥇 Werde jetzt Premium-Tutor',
      donationInfo:
        'Mit deiner Nachhilfe hast du <a href="/spendeninitiative">{{donationCount}}\xA0Schulstunden</a> für Kinder in Not gespendet! <a href="/spendeninitiative">Erfahre mehr</a>',
      testCall: {
        doYouWantToTest: 'Du möchtest etwas im Klassenzimmer testen?',
        start: 'Klassenzimmer starten',
        loading: 'lädt...',
      },
      help: {
        headline: 'Du benötigst Unterstützung oder etwas funktioniert nicht?',
        textForSeeker: 'Für Schüler',
        textForTutor: 'Für Tutoren',
      },
    },
    tutoringInstitute: {
      myOffers: 'Meine Nachhilfeschulen',
      myProfile: 'Mein Profil',
      settings: 'Kontoeinstellungen',
      messages: 'Meine Nachrichten',
    },
    regularUser: {
      myMessages: 'Meine Nachrichten',
      myOffer: 'Mein Nachhilfeangebot',
      myProfile: 'Mein Profil',
    },
    elitetutor: {
      title: 'Online-Nachhilfe (Tutor)',
      myAvailability: 'Meine Verfügbarkeit',
      earnings: 'Verdienst',
      earnMoney: 'Geld verdienen',
      unitOverview: 'Einheitenübersicht',
      mySubjects: 'Meine Fächer',
    },
    eliteseeker: {
      title: 'Online-Nachhilfe (Schüler)',
      unitOverview: 'Einheitenübersicht',
      billing: 'Abrechnung',
      myPricing: 'Paket buchen',
    },
  },
  notFoundPage: {
    backToHomepage: 'Zurück zur Homepage',
  },
  creditPurchase: {
    purchaseCredit: 'Mit 1-Klick Guthaben für einen Unterricht kaufen',
    purchaseSuccessfulTitle: 'Sie haben erfolgreich eine Einzelstunde hinzugebucht.',
    purchaseSuccessfulDesc:
      'Somit kann die Lehrkraft die Stunde direkt einbuchen. Sagen Sie der Lehrkraft bitte direkt auf Whatsapp Bescheid.',
    additionalRemark:
      'Hinweis: Sollte die SEPA-Lastschrift-Zahlungsmethode gewählt werden, kann es 5-6 Werktage dauern bis wir die Zahlung erhalten und das Guthaben bereitstellen können.',
    errorTokenInvalid:
      'Der Link zum Kaufen von Guthaben ist abgelaufen, ungültig oder wurde bereits genutzt. Bitte fordern Sie einen neuen Link an.',
    errorForbidden: 'Dieser Nutzer hat keine Berechtigung, Guthaben zu kaufen.',
    errorGeneric:
      'Guthaben konnte nicht aufgeladen werden. Bitte versuchen Sie es erneut oder fordern Sie einen neuen Link an.',
  },
  date: {
    month_names: [
      null,
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  },
  stickySideBar: {
    promotion_1: {
      title: 'Premium-Tutoren entdecken',
      headline: 'Warum sich 96\xa0% aller unserer Schüler verbessern.',
      buttonText: 'Mehr erfahren über den Ablauf',
      textList: {
        0: {
          text:
            'Alle Lehrer gehen durch ein pädagogisch geprüftes Aufnahmeverfahren. Dabei werden fachliche und soziale Fähigkeiten auf die Probe gestellt.',
        },
        1: {
          text:
            'Unterricht auf höchstem Niveau, wann & wo man will. Digitale Tafel, Aufnahmefunktion, Screensharing uvm. inklusive.',
        },
        2: {
          text:
            'Der Lehrer passt nicht zu deinem Kind? Mit wenigen Klicks ganz einfach & kostenlos den Lehrer wechseln.',
        },
        3: {
          text: 'Alle gängigen Zahlmethoden verfügbar. Wir kümmern uns um alles.',
        },
      },
    },
    promotion_2: {
      headline: 'Gratis Sofort-Vermittlung',
      paragraphs: {
        paragraph1:
          'Unsere Bildungsberater melden sich gerne bei Ihnen und helfen, den richtigen Nachhilfelehrer für Ihr Kind zu finden.',
      },
      buttonText: 'Jetzt bequem Rückruf anfordern',
    },
  },
  conference: {
    joinButton: 'Beitreten',
    socialCall: {
      title: 'Unsere nächsten Gruppengespräche',
      infotext:
        'Hier kannst du zu gegebener Uhrzeit einem der nächsten 6 Termine zum Kennenlernen beitreten. In dem letzten Schritt deiner Bewerbung geht es um dein Auftreten und deine Sozialkompetenzen. Wir freuen uns dich näher kennen zu lernen!',
      at: 'um',
      noSocialCalls: 'Leider sind derzeit keine Socialcalls vorhanden.',
    },
    introductionCall: {
      title: 'Dein Einführungsgespräch',
      infotext:
        'Wir erklären dir die Nutzeroberfläche und beantworten dir alle deine Fragen!\n Tritt einfach zu einem der unten aufgeführten Terminen bei. Wir freuen uns auf dich!',
      noCalls: 'Leider sind derzeit keine Einführungsgespräche vorhanden.',
      at: 'um',
    },
  },
  bookingForm: {
    default: {
      headline: 'Buche deine Unterrichtstunde',
      subtext: 'Lass {{firstName}} wissen, wann du deine Stunde haben willst.',
    },
    substitute: {
      headline: 'Vereinbare gleich einen Ersatztermin mit deinem Tutor.',
    },
    myOffer: {
      headline: 'Buche eine Unterrichtsstunde für einen Schüler',
      subtext: 'Plane mit deinem Schüler die nächste Unterrichtsstunde.',
      subjects: {
        label: 'In welchem Fach benötigst der Schüler Hilfe?',
      },
      level: {
        label: 'In welcher Klasse oder auf welchem Niveau braucht der Schüler Hilfe?',
      },
      lessonDate: {
        label: 'Wann soll die Einheit statt finden?',
      },
      seekerNote: {
        label: 'Hinterlasse eine Notiz für die nächste Stunde',
        placeholder: 'z.B. Hausaufgaben überprüfen, Buchseite 4 etc',
      },
      seeker: {
        label: 'Für welchen Schüler willst du eine Stunde buchen?',
        placeholder: 'Wähle einen Schüler aus',
      },
    },
    trial: {
      headline: 'Buche deine kostenlose Probestunde',
      subtext: 'Lass {{firstName}} wissen, wann du deine erste Stunde haben willst.',
      submit: 'Jetzt kostenlose Stunde buchen',
      submitNoAuth: 'Kostenlos Account erstellen und Probestunde sichern',
      submitAfterAuth: 'Buchung zur kostenlosen Probestunde abschließen',
    },
    subjects: {
      label: 'In welchem Fach benötigst du Hilfe?',
      placeholder: 'Fach hinzufügen (z.B. Mathe)',
    },
    level: {
      label: 'In welcher Klasse bist du oder auf welchem Niveau brauchst du Hilfe?',
    },
    lessonDate: {
      label: 'Wann willst du deine Einheit haben?',
      placeholder: 'Bitte Uhrzeit auswählen.',
      timeSlotLabel: '{{weekday}} der {{date}} - {{startTime}} Uhr bis {{endTime}} Uhr',
    },
    seekerNote: {
      label: 'Stelle dich {{firstName}} vor und beschreibe worauf du Wert legst',
      placeholder:
        'Lass {{firstName}} wissen, wie er dir am besten helfen kann, was du lernen ' +
        'willst, wo du eventuell Probleme hast und worauf du am meisten Wert legst.\n' +
        '\n' +
        'Achte darauf an dieser Stelle noch keine Kontaktdaten wie Telefonnummer ' +
        'oder E-Mail anzugeben!',
    },
    button: {
      submit: 'Unterrichtsstunde buchen.',
    },
    create: {
      responseOK:
        'Buchung erfolgreich. Bitte bestätige dem Schüler den Termin in der Whatsapp-Gruppe noch einmal manuell.',
      responseBadRequestError: 'Bitte wähle ein Fach, eine Klasse und ein Zeitfenster aus.',
      customErrorCode: {
        BOOKING_CREDIT_INSUFFICIENT_SEEKER:
          'Du hast keine Unterrichtseinheiten. Bitte lade dir in deinem Profilmenü unter Paket buchen neue Einheiten auf.',
      },
    },
    promptCreditPurchase: {
      title: 'Leider hat dein Schüler kein Guthaben mehr.',
      subtext1:
        'Aber keine Sorge. Mit dem Klick auf den Button erhält dein Schüler eine E-Mail, um die Stunde sofort freizuschalten. Danach kannst du die Stunde auch wie gewohnt einbuchen.',
      subtext2:
        'Das reguläre Aufladungsdatum des Schülers ist am {{date}}. Durch SEPA kann es auch 4-6 Werktage länger dauern.',
      sendEmail: 'E-Mail an Schüler senden',
      successTitle: 'Super, dein Schüler hat eine E-Mail erhalten.',
      successSubtext: 'Schreibe ihm bitte direkt auf Whatsapp, damit er darauf aufmerksam wird.',
      errorNotFound: 'Der ausgewählte Schüler existiert nicht oder nicht mehr.',
      errorNotSubscribed: 'Der ausgewählte Schüler hat kein aktives Abonnement.',
      errorMoreThanOnceSubscribed:
        'Der ausgewählte Schüler hat mehr als ein aktives Abonnement. Er muss erst ein Abonnement kündigen unter "Abrechnung > Abonnement bearbeiten".',
    },
  },
  pricing: {
    headline: 'Faire und transparente Preise',
    subheadline:
      'Wir nutzen feste Preise für alle Premium-Lehrkräfte, damit du immer genau weißt, wo du stehst.',
    infoBox: {
      bulletPoints: [
        'Individueller Lernplan für die Bedürfnisse deines Kindes und lerntypenorientierte Lernförderung.',
        'Der Plan kann monatlich angepasst werden.',
        'Eine Stunde übrig am Monatsende? Nicht genutzte Stunden sind übertragbar und verfallen erst nach dem dritten Monat.',
        'Die Zeit wird knapp? Zusätzliche Stunden können jederzeit des Monats hinzugebucht werden.',
      ],
    },
    couponPriceOnNextPage:
      'Die Preise inkl. Coupon-Rabatt werden auf der nachfolgenden Seite angezeigt',
    errorTokenInvalid:
      'Der Link zum Abo ist abgelaufen oder ungültig. Bitte fordern Sie einen neuen Link an.',
    pricingModel: {
      default: {
        lesson: {
          zero: 'keine Unterrichtseinheiten',
          one: '1 Unterrichtseinheit',
          other: '%{count} Unterrichtseinheiten',
        },
        perWeek: 'pro Woche',
        startsAt: 'Ab',
        perUnit: ' /Einheit',
        selectPlan: 'Plan auswählen',
        selectedPlan: 'Ausgewählt',
        customSelector: 'Einheiten pro Woche',
        mostSold: 'Meistgekauft',
      },
      item1: {
        title: 'Small',
        description: 'Am besten für Schüler, die ab und zu einen kleinen Tipp benötigen.',
      },
      item2: {
        title: 'Medium',
        description: 'Ideal für Schüler, die sich effektiv und schnell verbessern wollen.',
      },
      item3: {
        title: 'Large',
        description: 'Sehr gut für Schüler, die intensiv Lernlücken aufarbeiten möchten.',
      },
      item4: {
        title: 'Family',
        description: 'Perfekt für Geschwister, die gemeinsam mit ihren Noten glänzen wollen.',
      },
    },
    selection: {
      lessonUnitCount: {
        headline: 'Monatliches Guthaben für Online-Nachhilfe aufladen',
        desciption:
          'Das Guthaben wird in deinem TutorSpace-Account gespeichert & kann bis in den nächsten Monat frei zur Buchung von Tutoren aus allen Fächern verwendet werden.',
        lessonUnit: '{{lessonUnitCount}} Nachhilfestunden',
        hint: 'Hinweis: Entspricht ca. {{range}} Einheiten pro Woche.',
      },
      contractPeriod: {
        headline: 'Laufzeit',
        month: 'Monat',
        months: 'Monate',
        description: [
          '… wenn du dir unsicher bist.',
          '… wenn du motiviert bist.',
          '… wenn du Geld sparen willst.',
        ],
      },
      summary: {
        lessonUnitsPerMonth: 'Anzahl Einheiten pro Monat',
        regularPrice: 'Regulärer Preis pro Einheit',
        regularPricePerMonth: 'Regulärer Preis pro Monat',
        yourPrice: 'Dein Preis pro Einheit',
        discount: 'Rabatt',
        couponDiscount: 'Coupon-Rabatt',
        total: 'Summe pro Monat',
      },
      formError: 'Bitte wähle deine gewünschte Stundenanzahl und eine Vertragslaufzeit aus.',
      button: {
        confirm: 'Weiter zu den Zahlungsmethoden',
        change: 'Paket ändern',
      },
      delayNoticeSEPA:
        'Hinweis: Sollte die SEPA-Lastschrift-Zahlungsmethode gewählt werden, kann es 5-6 Werktage dauern bis wir die Zahlung erhalten und das Guthaben bereitstellen können.',
      promotionBox: {
        headline: 'Warum sich 96 % aller unserer Schüler verbessern.',
        text1: '🔎 Nur ca. 15 % aller Bewerbungen werden bei uns angenommen.',
        text2:
          '🤝 Unsere Lehrkräfte sind nicht nur Experten in ihrem Fach, sondern dienen auch als Vorbild und Mentor.',
      },
      quotation: {
        quote:
          'Wir können TutorSpace gar nicht genug danken. Meine 12 Jahre alte Tochter liebt den Unterricht so sehr, dass sie jetzt auch noch Französisch Einheiten nimmt. Mein Sohn ist jetzt auch bei der selben Tutorin, nachdem er gesehen hat wie begeistert Maria sich auf den Unterricht vorbereitet.',
        user: 'Petra aus München, Mutter von zwei',
      },
    },
    success: 'Du hast erfolgreich ein Abonnement gebucht.',
    revokedSubscriptionChange:
      'Die vorgemerkte Paketänderung wurde gelöscht. Dein Paket bleibt unverändert.',
    noPricingAvailable: {
      headline: 'Zur Zeit können keine Abonnements abgeschlossen werden.',
    },
    subscriptionChangePreview: {
      title: 'Änderungsvorschau',
      header: 'Du hast eine vorgemerkte Paketänderung.',
      text:
        'Ab dem {{date}} hast du {{lessonUnits}} Einheiten pro Monat zur Verfügung und zahlst dafür {{price}}.',
      textBillingCycles: 'Das Paket ist auf {{totalBillingCycles}}\xA0Monate gewählt.',
      textNoBillingCycles: 'Das Paket ist {{planName}}.',
      confirmPreviewText:
        'Du änderst gerade dein aktuelles Unterrichtspaket zu {{lessonUnits}}\xA0Einheiten pro Monat ({{totalBillingCycles}}\xA0Monate) zu einem Preis von {{price}}. Die Änderung wird zum {{date}} aktiv. Bitte bestätige die Anpassung.',
      timeoutWarningText:
        'Verbleibende Zeit: {{remainingMinutes}} Minuten {{remainingSeconds}} Sekunden',
      timeoutErrorText:
        'Nachdem sich dein Abo erneuert ist leider gerade keine Änderung möglich. Bitte versuche es später erneut.',
    },
  },
  upComingLessons: {
    headline: 'Geplante und vergangene Nachhilfestunden',
    upcoming: 'Geplant',
    past: 'Vorbei',
    lessonCard: {
      level: 'Niveau: ',
      time: 'Uhrzeit',
      date: 'Datum',
      type: 'Art der Einheit',
      promotion:
        'Du hast jetzt die einmalige Chance, einen neuen Schüler für dich zu gewinnen. Der erste Eindruck zählt!',
      startLesson: 'Einheit starten',
      cancelLesson: 'Einheit absagen',
      contactDetails: 'Kontaktdaten',
      trialLesson: 'Probestunde',
      regularLesson: 'Regulär',
      bookNewLesson: 'Neue Einheit buchen',
      recording: {
        title: 'Unterrichtsaufnahmen',
        recording: 'Aufnahme {{id}}',
        notAvailable: 'Es sind keine Aufnahmen vorhanden. Bitte versuche es später erneut.',
        notAvailableReason:
          'Entweder befindet sich die Aufnahme noch in der Nachbearbeitung oder der Tutor hat keine Aufnahme gestartet.',
        recordPartInfo:
          'Wenn du und der Tutor zwischendurch den Raum verlassen habt, dann könnten mehrere Aufnahmen hier gelistet sein.',
      },
      cancelLessonWarning: {
        warning: 'Bist du dir sicher, dass du die Stunde absagen willst?',
        reminderCreditExpiry:
          'Solltest du die Stunde bis zum {{expireDate}} Uhr absagen, bekommst du eine Erstattung der Unterrichtseinheit.',
        reminderCreditExpiryTutor:
          'Dein Schüler bekommt eine Erstattung der Unterrichtseinheit, außer das zur Buchung verwendete Guthaben wurde vor zu langer Zeit gekauft.',
        reminderMinBefore:
          'Die Unterrichtseinheit muss mind. {{minDuration}} vor Beginn abgesagt werden.',
      },
      cancelTooLate: 'Absage nicht möglich: Du musst mind. {{minDuration}} vor Beginn absagen.',
      cancelSuccessful: 'Die Stunde wurde erfolgreich abgesagt.',
    },
    infoBox: {
      tutor: {
        heading: 'Hier ein paar wichtige Hinweise zu deiner Nachhilfestunde:',
        paragraphs: {
          paragraph1:
            'Vereinbare nach dem Unterricht gleich den nächsten Termin. So kommt die nächste Stunde am ehesten zu Stande und du gewinnst einen Stammschüler. Buchen kannst du die vereinbarte Stunde in den vergangenen Stunden (erst ab der ersten regulären Stunde).',
          paragraph2:
            'Der Link funktioniert jeweils ab 15 Minuten vor der Nachhilfestunde bis 15 Minuten nach der Nachhilfestunde.',
          paragraph3:
            'Verschiebungen von Stunden funktionieren durch das Absagen und neu Buchen einer Stunde durch die Lehrkraft im System. Eine Stunde kann NUR bis zu bis zu 3 Stunden vor dem Termin abgesagt werden. Erkläre das gerne in der Stunde, sodass später keine Missverständnisse entstehen.',
          paragraph4: 'Bedenke bei Doppelstunden, dass zwei Buchungen eingetragen werden müssen.',
        },
      },
      seeker: {
        heading: 'Hier ein paar wichtige Hinweise zu deiner Nachhilfestunde:',
        paragraphs: {
          paragraph1:
            'Überprüfe am besten 5 Minuten vor der Stunde, ob Kamera und Mikrofon funktionieren.',
          paragraph2:
            'Überlege dir schon vorher, wann die nächste Einheit stattfinden soll. Dann könnt ihr gleich am Ende die Einheit vereinbaren und der Tutor kann sie für dich eintragen.',
          paragraph3:
            'Bereite Unterlagen/ Fragen in deinem Fach schon vor der Unterrichtsstunde vor, damit ihr die Einheit effektiv nutzen könnt.',
          paragraph4:
            'Beitritt nur über den Button "Einheit starten", sonst können technische Probleme auftreten.',
          paragraph5:
            'Verschiebungen von Stunden funktionieren durch das Absagen und neu Buchen einer Stunde durch die Lehrkraft im System. Eine Stunde kann NUR bis zu bis zu 3 Stunden vor dem Termin abgesagt werden.',
        },
      },
    },
    birthdayModal: {
      title: 'Wann hast du Geburtstag?',
      description:
        'Gib Deinen Geburtstag an, um von uns an Deinem Geburtstag überrascht zu werden und dein Altern zu verifizieren.',
      hint: 'Hinweis:',
      birthday: {
        label: 'Geburtstag eingeben',
        inputHint: 'Geburtstag des Lernenden',
      },
    },
  },
  billing: {
    subscriptionDetails: {
      currentSubscription: 'Aktuelles Abonnement',
      lessonUnitsLeft: 'Verfügbare Einheiten',
      editSubscrption: 'Abonnement bearbeiten',
      revokeChangeSubscrption: 'Paketänderung widerrufen',
      nextBilling: 'Die nächste Zahlung von {{price}} wird am {{date}} eingezogen.',
      autoRenew: 'Der Vertrag verlängert sich automatisch.',
      canceledBilling: 'Das Abonnement wird zum {{date}} gekündigt.',
    },
    bookingHistory: {
      title: 'Guthabenverlauf',
      yourTutor: 'Dein Tutor',
      yourSeeker: 'Schüler',
      time: 'Uhrzeit',
      date: 'Datum',
      lessonType: 'Art der Einheit',
      subject: 'Fach',
      lessonUnit: 'Unterrichtseinheit',
      receivedCredits:
        'Du hast neue Stunden auf dein Konto gebucht bekommen. Viel Erfolg bei deinen nächsten Unterrichtseinheiten!',
      receivedCreditsFromCanceledBooking:
        'Du hast eine Einheit abgesagt und gutgeschrieben bekommen. Viel Erfolg bei deinen nächsten Unterrichtseinheiten!',
      tutorNoShowRefund: 'Dein Tutor ist nicht zum Unterricht erschienen.',
      bothNoShowRefund: 'Ihr beide seid nicht zum Unterricht erschienen.',
      expiredCredits: 'Leider sind ein paar deiner Stunden abgelaufen.',
      correction: 'Dein Guthaben wurde manuell von TutorSpace angepasst.',
      correctionNoteByAdmin: 'Notiz des Admins: "{{note}}"',
      freeBookingGranted: 'Deine Probestunde wurde manuell von TutorSpace wiederhergestellt.',
      freeBookingRevoked: 'Deine Probestunde wurde manuell von TutorSpace zurückgenommen.',
      delayNoticeSEPA:
        'Hinweis: Solltest du SEPA-Lastschrift als Zahlungsmethode gewählt haben, kann es 5-6 Werktage dauern bis wir deine Zahlung erhalten und dein Guthaben bereitstellen. Bei Fragen zu deinem Guthaben wende dich an: <a href="mailto:support@tutorspace.de">support@tutorspace.de</a>',
    },
    infoBox: {
      heading: 'Hier ein paar wichtige Hinweise zu deinem Guthaben:',
      paragraphs: {
        paragraph1:
          'Auf dieser Seite findest du eine detaillierte Auflistung aller gehaltenen und gutgeschriebenen Einheiten.',
        paragraph2:
          'Erscheinst du nicht zur Nachhilfestunde und hast diese nicht rechtzeitig abgesagt, wird 1 Stunde von deinem Guthaben abgezogen.',
        paragraph3:
          'Erscheint der Lehrer einmal wegen einem Notfall nicht, wird dir die Stunde wieder gutgeschrieben.',
        paragraph4:
          'Zusätzliche Stunden kannst du jederzeit flexibel hinzubuchen, indem du unseren Support kontaktierst.',
      },
    },
  },
  availbilityPage: {
    infobox1:
      'Trage deine Zeiten ein, in denen sich neue Schüler für einen ersten Termin eintragen können. Nach der ersten Stunde mit jedem Schüler empfehlen wir die Folgetermine immer am Ende der Stunde individuell zu planen.',
    infobox2:
      'Verfügbarkeit nicht für bestehende Schüler eintragen, hier übernimmst immer du die Buchung. Pro Woche dürfen 6 Stunden (Verfügbarkeiten in den blau hinterlegten Hauptzeiten + gebuchte Einheiten) nicht unterschritten werden.',
  },
  earnings: {
    headline: 'Verdienst',
    infoBox: {
      currentEarnings: 'Dein aktueller Verdienst: {{earning}}',
      infoTextPart1:
        'Die Auszahlung des Honorars erfolgt 1x innerhalb von 30 Tagen. Dies erfolgt entweder automatisch jeweils montags, sobald das Honorar über 150,00\xa0€ liegt, oder manuell durch die Tutor*innen ausgelöst, ',
      manualPayoutLink: 'sobald du hier klickst',
      infoTextPart2: '.',
      addPayoutInfoButton: 'Auszahlungsinformationen hinzufügen',
      updatePayoutInfoButton: 'Auszahlungsinformationen ändern',
      warningupdatePayoutInfo:
        'Um eine Auszahlung zu erhalten, bitte hinterlege dein Auszahlungskonto.',
      tips: {
        heading: 'Hier einige wichtige Dinge zur Auszahlung:',
        paragraphs: {
          paragraph1:
            'Unterrichtsstunden werden nur vergütet, wenn der Schüler, als auch der Lehrer über den bereitgestellten Link für die Einheit beigetreten sind.',
          paragraph2:
            'Erscheinst du nicht zur Nachhilfestunde wird der Stundenlohn von deinem Guthaben abgezogen.',
          paragraph3:
            'Sollte der/die Schüler/in nicht zur Einheit erscheinen wird dir die Hälfte deines Verdienstes für diese Nachhilfestunde gutgeschrieben.',
        },
      },
    },
    manualPayout: {
      title: '',
      areYouSure: 'Manuelle Auszahlung anfordern?',
      areYouSureInfo: 'Es dauert bis zu 5 Werktagen, bis die Auszahlung ausgeführt wird.',
      updateSuccess: 'Die Auszahlung wurde erfolgreich angefordert.',
    },
    updatePayoutInfo: {
      title: '',
      updateSuccess: 'Deine Auszahlungsinformationen wurden erfolgreich hinterlegt.',
      bankInfoTitle: 'Kontoinformationen',
      addressTitle: 'Rechnungsadresse',
      donate: {
        title: '💚 Unterstütze gemeinsam mit uns Familien',
        description:
          'Leite einen Teil deines Stundensatzes weiter und #b#verändere das Leben von Kindern, deren Eltern sich die Nachhilfe sonst nicht leisten können.#b# 100% deines gewählten %-Satzes wird an Familien weitergegeben und wir bezuschussen es mit einer Garantie, dass es so bleibt, auch wenn die Lehrkraft wechselt.',
        changeLater: 'Du kannst deine Auswahl jederzeit in Zukunft anpassen.',
        inputLabel: 'Spendensatz',
        inputTitle: 'Gib deinen eigenen Spendensatz ein',
        options: {
          ten: {
            label: '🌱 10 %',
          },
          twentyFive: {
            label: '🍀 25%',
            hint: 'Meist gewählt',
          },
          fifty: {
            label: '🙌 50%',
          },
          hundred: {
            label: '🎖 100%',
            hint: 'Mit Zertifikat',
          },
          custom: {
            label: 'Eigener Satz',
            hint: 'Eigener Satz',
          },
          optOut: {
            label: '⛔ Nicht teilnehmen.',
          },
        },
      },
      accountHolder: {
        label: 'Kontoinhaber',
      },
      iban: {
        label: 'IBAN',
      },
      bic: {
        label: 'BIC',
      },
      smallBusinessRegulation: {
        paragraph:
          'Bitte beachte, dass wir keine steuerliche Hilfe bzw. Steuerberatung anbieten. Falls du steuerlich noch nicht registriert bist, nimm bitte die Registrierung beim Finanzamt vor. Bei steuerlichen Fragen, setze dich bitte mit deinem Steuerberater in Verbindung.',
        label:
          'Ankreuzen, falls du dich für die Kleinunternehmerregelung <a href="https://www.gesetze-im-internet.de/ustg_1980/__19.html" target="_blank">(§19\xa0UStG)</a> entschieden hast.',
        placeholder: '',
      },
      taxNumber: {
        label: 'Steuernummer',
        placeholder: 'z.B. 181/815/08155',
      },
      street: {
        label: 'Straße',
        placeholder: 'z.B. Bahnhofstraße',
      },
      houseNumber: {
        label: 'Hausnummer',
        placeholder: 'z.B. 7a',
      },
      postcode: {
        label: 'Postleitzahl (PLZ)',
        placeholder: 'z.B. 50667',
      },
      city: {
        label: 'Stadt',
        placeholder: 'z.B. Berlin',
      },
      country: {
        label: 'Land',
        placeholder: 'z.B. Deutschland',
        defaultValue: 'Deutschland',
      },
    },
    deletePayoutInfo: {
      success: 'Deine Auszahlungsinformationen wurden erfolgreich gelöscht.',
    },
    eventText: {
      bonusTrial:
        'Super! Du hast {{name}} von dir überzeugt. Nun habt ihr schon die 2. Stunde hinter euch. Somit erhältst du die dreifache Auszahlung der Probestunde.',
      penalty: 'Du bist zu dem Termin nicht erschienen.',
      bothNoShow: 'Ihr beide seid nicht zum Unterricht erschienen.',
      seekerNoShow: '{{name}} ist nicht zu dem Termin erschienen.',
      payout: 'Dir wurde {{amount}} ausgezahlt.',
      receipt: 'Auszahlungsbeleg',
      correction: 'Dein Verdienst wurde manuell von TutorSpace angepasst.',
    },
  },
  mySubjects: {
    infoBoxTop:
      "Ein Test dauert ca. 15–20 Minuten. Bei Bestehen kannst du in dem neuen Fach unterrichten. Nimm dir also einen ruhigen Moment und los geht's!",
    infoBoxTop2:
      'Nach dem Test kann es bis zu 10 Minuten dauern, bis sich diese Anzeige aktualisiert. Und natürlich zählt nur dein erster Versuch!',
    infoBoxTopDeclined:
      'Leider bist du in unserem Auswahlverfahren in der Unterrichtssimulation nicht weitergekommen. Daher kannst du nicht an Tests für weitere Fächer teilnehmen.',
    infoBoxSide: {
      heading: 'Hier ein paar wichtige Hinweise zu deinen Unterrichtsfächern:',
      paragraphs: {
        paragraph1:
          'Wie bekomme ich das Ergebnis?\nInnerhalb von 10 Minuten wird dir das Ergebnis per Mail zugesandt.',
        paragraph2:
          'Was für Aufgabentypen gibt es?\nDie Tests bestehen hauptsächlich aus Multiple-Choice-Fragen.',
        paragraph3:
          'Wann gilt ein Test als bestanden?\nEs müssen 70 % der Fragen richtig beantwortet werden.',
        paragraph4:
          'Muss ich mich vorbereiten?\nDie Tests sind so gestaltet, dass nicht explizit darauf gelernt werden muss. Allerdings schadet Vorbereitung auch nie.',
        paragraph5:
          'Ich bin neu. Wie geht es danach weiter?\nBei deinem ersten Fach wird dir nach Bestehen per Mail ein Link zu einem Termin für die kurze Unterrichtssimulation geschickt.',
      },
    },
    subjectHeadings: {
      success: 'Meine aktiven Unterrichtsfächer',
      pending: 'Schließe hier noch den Test ab',
      unapplied: 'Auf diese Fächer kannst du dich ebenfalls bewerben',
      fail: 'Punktzahl nicht erreicht',
    },
    buttons: {
      startTest: 'Weiter zum Test',
      contactUs: 'Kontaktiere uns',
      applyAndStartTest: 'Bewerben und weiter zum Test',
      retryAfter: {
        zero: 'Test wiederholen in weniger als einem Tag',
        one: 'Test wiederholen in einem Tag',
        other: 'Test wiederholen in %{count} Tagen',
      },
    },
    listEmpty: 'Leer',
    contactUs: {
      description1:
        'Schön, dass du dich dafür interessierst das Fach %{subject} zu unterrichten. Setze dich mit uns in Verbindung und wir erklären dir die nächsten Schritte:',
      description2: 'Halte hierfür deine letzte Zeugnisnote in %{subject} parat.',
      link: 'mailto:talents@tutorspace.de',
      linkText: 'talents@tutorspace.de',
    },
  },
  earnMoney: {
    headline1: 'Empfehle uns weiter',
    headline2: 'Bewertungen von deinen Schülern für Trustpilot sammeln',
    headline3: 'Hilf uns, noch mehr Familien auf uns aufmerksam zu machen',
    infoBox: {
      headline: 'Wusstest du schon?',
      paragraph: {
        paragraph1:
          'Neben der Nachhilfe gibt es weitere tolle Möglichkeiten, sich etwas dazuzuverdienen. Nimm an den Aktionen teil, die dir gefallen!',
      },
    },
    box1: {
      context: 'Andere Lehrkräfte',
      headline: '50\xa0€ als Dankeschön',
      emoji: '👩🏽‍🏫👨🏼‍🏫',
      text1:
        'Wer in deinem Bekanntenkreis oder im Studium kann gut unterrichten? Wer möchte sich etwas dazuverdienen?',
      text2:
        'Wenn die Person deinen Namen im Bewerbungsprozess erwähnt oder du uns ihren Namen schreibst an talents@tutorspace.de, bekommst du die Belohnung, sobald der erste Unterricht beendet ist.',
      button: 'Bewerbungslink kopieren',
    },
    box2: {
      context: 'Schüler',
      headline: '100\xa0€ als Dankeschön',
      emoji: '👩‍🎓👨🏼‍🎓',
      text1: 'Du hast Anfragen von Bekannten, aber keine Zeit oder gibst das bestimmte Fach nicht?',
      text2:
        'Über den Link wissen wir, dass der Schüler durch eine Empfehlung kommt und fragen explizit nach dir. Sobald der erste reguläre Unterricht stattgefunden hat, bekommst du deine Belohnung gutgeschrieben.',
      button: 'Link zur Probestunde kopieren',
    },
    box3: {
      context: 'Lokal Abreißzettel aufhängen',
      headline: '100\xa0€ pro Schüler',
      emoji: '🏘️🚀',
      text1:
        'Abreißzettel an Schulen, schwarzen Brettern oder in Läden. So wird häufig Nachhilfe gefunden!',
      text2:
        'So funktioniert’s:\n1) Du erhältst ein Paket mit Abreißzetteln und eine Anleitung\n2) Du verteilst Abreißzettel an passenden Stellen in einer anliegenden Postleitzahl\n3) Du erhältst 100\xa0€\xa0pro Schüler, der sich anmeldet.',
      button: 'Kontakt aufnehmen',
    },
    box4: {
      context: 'Stadtleiter werden',
      headline: '100\xa0€ pro Schüler für dich 100\xa0€ für deine Helfer',
      emoji: '🏛️🎖️',
      text1: 'Du hast pro Woche >2 Stunden Zeit und willst mehr Verantwortung übernehmen?',
      text2:
        'Leite eine Stadt in Deutschland und organisiere mit Freunden, Bekannten oder Partnern das Platzieren von Werbemitteln. Für jeden Schüler, der sich aus dieser Stadt anmeldet, bekommst du und deine Freunde Geld. Für die Stadt herrscht so lange Exklusivität, wie du Ergebnisse erzielst.',
      button: 'Kontakt aufnehmen',
    },
    reviewCard: {
      uploadModal: {
        headline: 'Lade das Bild hoch',
        submit: 'Hochladen',
      },
      button: {
        open: 'Screenshot hochladen',
        denied: 'Screenshot abgelehnt',
        validating: 'Screenshot wird überprüft',
        done: 'Belohnung ausgezahlt',
      },
    },
  },
  exitIntentModal: {
    headline: 'Bevor du gehst ...',
    subheadline: 'Wir bieten bei exzellenten Tutoren kostenlose Probestunden an.',
    content: {
      bulletpoints: [
        'Individueller Lernplan',
        'Lerntypenorientierter Unterricht',
        'Keine Anfahrt = Weniger Kosten',
        'Flexibler Fachwechsel',
        'Mit Spaß zu guten Noten',
      ],
    },
    button: 'Gratis Probestunde',
  },
  jobs: {
    offer: 'Offene Stellen',
    initiativeApplication: 'Keine Stelle gefunden, die zu dir passt?',
    initiativeApplicationButton: 'Initiativbewerbung senden',
  },
  patrickNadler: {
    headline1: 'Wer ist Patrick Nadler?',
    headline2: 'Mein Engagement für die Bildung',
    description:
      'Seit über 5 Jahren setzt sich Patrick Nadler für den Bildungsstandort Deutschland ein, leitet die staatlich anerkannte Bildungseinrichtung TutorSpace, die jede Woche viele hunderte Familien durch individuelle Förderung unterstützt, selbstbewusst und mit guten Noten in der Schule zu glänzen, sitzt dem Branchenverband für Nachhilfe in Deutschland vor und fördert mit seiner Frau (DeinSprachcoach Maria) die Integration in Deutschland (> 1,5 Millionen Mal pro Monat werden die gratis Deutschinhalte aufgerufen!).',
    press: 'Patrick Nadler in der Presse',
    more: 'Mehr dazu',
    metaTitel: 'Patrick Nadler ist der Gründer des Bildungsanbieters TutorSpace',
    metaDescription:
      'Patrick Nadler leitet die Online-Schule TutorSpace und sitzt dem Bundesverband für Nachhilfe- und Nachmittagsschulen vor.',
  },
  customHttpErrors: {
    conflictErrors: {
      MISSING_VACANCY:
        'Der Tutor hat seine Verfügbarkeiten geändert. Bitte wähle einen anderes Zeitfenster aus.',
      OTHER_BOOKING:
        'Da war wohl ein anderer Schüler schneller. Bitte wähle ein anderes Zeitfenster aus.',
      CONCURRENT_MODIFICATION: 'Gleichzeitige Datenänderung - Bitte versuche es in Kürze erneut.',
      FREE_BOOKING_UNAVAILABLE: 'Keine Probestunde mehr verfügbar. Bitte lade die Seite erneut.',
      FREE_BOOKING_REQUIRED: 'Bitte buche zuerst eine Probestunde.',
      BOOKING_CREDIT_INSUFFICIENT:
        'Du hast keine Unterrichtseinheiten. Bitte lade dir in deinem Profilmenü unter Paket buchen neue Einheiten auf.',
      SUBSCRIPTION_PLAN_MODIFIED: 'Das Abomodell hat sich geändert. Bitte lade die Seite erneut.',
      BOOKING_CANCELLATION_ALREADY_REQUESTED:
        'Das Abbrechen der Buchung wurde bereits angefragt. Wenn diese Meldung weiterhin erscheint, wende dich bitte an den Support: support@tutorspace.de',
      PAYOUT_ALREADY_REQUESTED: 'Du hast schone eine Auszahlung angefragt.',
      LAST_PAYOUT_TOO_RECENT:
        'Auszahlung nicht möglich. Deine letzte Auszahlung war vor weniger als einem Monat.',
      PAYOUT_INFO_MISSING:
        'Auszahlung nicht möglich. Bitte hinterlege deine Zahlungsinformationen.',
      PROFILE_VIDEO_PENDING_APPROVAL:
        'Du hast bereits ein Video hochgeladen. Bitte warte bis es geprüft wurde.',
      QUOTA_EXHAUSTED:
        'Du kannst nur 2x ein Video pro Monat hochladen. Bitte versuche es in einem Monat erneut.',
      ALREADY_SUBSCRIBED:
        'Du hast bereits ein aktives Abonnement. Unter "Abrechnung" erscheint dein aktives Abonnement.',
      NOT_SUBSCRIBED: 'Du hast kein aktives Abonnement, das wir ändern können.',
      MORE_THAN_ONCE_SUBSCRIBED:
        'Du hast mehr als ein aktives Abonnement. Bitte kündige zuerst ein Abonnement unter "Abrechnung > Abonnement bearbeiten".',
      CURRENT_SUBSCRIPTION_CHANGED:
        'Es gab eine zeitgleiche Änderung an deinem aktiven Abonnement. Bitte versuche es erneut.',
      SUBSCRIPTION_CHANGE_NO_ACTUAL_CHANGE: 'Deine Angaben entsprechen deinem aktiven Abonnement.',
      PAYOUT_BALANCE_TOO_LOW: 'Dein aktueller Verdienst ist zu niedrig.',
    },
    badRequestErrors: {
      VACANCY_SLOT_COUNT_WRONG:
        'Oops. Das hätte nicht passieren dürfen. (VACANCY_SLOT_COUNT_WRONG)',
      VACANCY_BEFORE_THRESHOLD:
        'Leider ist dieses Formular abgelaufen. Bitte lade die Seite erneut.',
      VACANCY_AFTER_THRESHOLD: 'Oops. Das hätte nicht passieren dürfen. (VACANCY_AFTER_THRESHOLD)',
      VACANCY_TOO_SHORT: 'Bitte immer zwei zusammenhängende Timeslots wählen!',
      VACANCY_NOT_SLOT_ALIGNED:
        'Oops. Das hätte nicht passieren dürfen. (VACANCY_NOT_SLOT_ALIGNED)',
      BOOKING_BEFORE_THRESHOLD:
        'Leider ist dieses Zeitfenster nicht mehr verfügbar. Bitte wähle ein anderes aus.',
      BOOKING_AFTER_THRESHOLD:
        'Leider ist dieses Zeitfenster nicht mehr verfügbar. Bitte wähle ein früheres aus.',
      BOOKING_TOO_SHORT: 'Oops. Das hätte nicht passieren dürfen. (BOOKING_TOO_SHORT)',
      BOOKING_NOT_SLOT_ALIGNED:
        'Oops. Das hätte nicht passieren dürfen. (BOOKING_NOT_SLOT_ALIGNED)',
      SUBSCRIPTION_PLAN_NOT_FOUND:
        'Dieses Abonnement gibt es nicht mehr. Bitte lade die Seite erneut.',
      PHONENUMBER_CHANGE_TIMEOUT: 'Du kannst die Telefonnummer nur zweimal pro Woche ändern.',
      INVALID_IMAGE_URL: 'Dein Bild ist ungültig. Bitte lade ein neues Foto hoch.',
    },
    forbiddenErrors: {
      WRONG_ACCOUNT_TYPE_TUTORING_INSTITUTE:
        'Du kannst mit einem Institutaccount diese Funktion nicht nutzen.',
      NOT_CURRENT_USER: 'Du darfst diese Funktion nur für dich selbst nutzen.',
      BOOKING_OWN_USER: 'Du kannst dich selbst nicht buchen.',
      CANCEL_BOOKING_START_TOO_EARLY:
        'Absage nicht möglich: Du versuchst zu knapp vor Beginn der Nachhilfe die Buchung abzusagen.',
      CANCEL_BOOKING_FREE:
        'Absage nicht möglich: Kostenlose Probestunden können nicht abgesagt werden.',
    },
    unsupportedMediaTypeErrors: {
      UNSUPPORTED_MEDIA_TYPE: 'Nicht unterstütztes Format.',
      MEDIA_INFO_INVALID: 'Nicht unterstützter Medieninhalt.',
      VIDEO_DURATION_LIMIT_EXCEEDED: 'Maximale Videolänge überschritten.',
    },
  },
};
